/* ==========================================================================
   Background images
   ========================================================================== */

	.twitter a,
	.flickr a,
	.facebook a,
	.menu-icon a,
	.search-icon a,
	.ticket-icon a,
	.submit a,
	.logo a,
	.close a,
	.button,
	#btnSearch,
	.newsWrapper .icon,
	.newsWrapper2 .icon,
	.icons,
	.shareButtons a,
	.buttons a,
	.filter__sentence__close,
	.share__twitter,
	.share__facebook,
	.button:after,
	#plannerHeader .opnieuw a span { background-image: url('../images/slice-mobile.png'); background-repeat: no-repeat; text-indent: -9999px; }

	.newsWrapper2 ul li,
	.knop,
	.btnRed .title span,
	.btnBlue .title span,
	#photo .prev a,
	.newsWrapper2 ul li,
	.txt #tijden li a.knopTickets,
	.search #bottomNav .arrowRight,
	.search #bottomNav ul li.right a,
	#list.block li a .button { background-image: url('../images/arrow-right-mobile.png'); background-repeat: no-repeat; }

	#filterOverlay .knop,
	.btnGrey .title span{ background-image: url('../images/arrow-right-b-mobile.png'); background-repeat: no-repeat; }

	.knopTerug,
	#photo .next a,
	.search #bottomNav .arrowLeft,
	.search #bottomNav ul li.left a { background-image: url('../images/arrow-left-mobile.png'); background-repeat: no-repeat; }

	#planner .select_stap_1,
	#planner .select_stap_2,
	.mainBlock .title.active span { background-image: url('../images/arrow-down-b-mobile.png'); background-repeat: no-repeat; }

	.btn--filter:before {
		background-image: url('../images/plus-negativ.png');
	}

	.btn--info:before {
		background-image: url('../images/down.png');
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px),
	   only screen and      (-o-min-device-pixel-ratio: 3/2) and (max-width: 767px),
	   only screen and    (min--moz-device-pixel-ratio: 1.5) and (max-width: 767px),
	   only screen and         (min-device-pixel-ratio: 1.5) and (max-width: 767px) {

	.twitter a,
	.flickr a,
	.facebook a,
	.menu-icon a,
	.search-icon a,
	.ticket-icon a,
	.submit a,
	.logo a,
	.close a,
	.button,
	.icons,
	#btnSearch,
	.newsWrapper .icon,
	.newsWrapper2 .icon,
	.shareButtons a,
	.buttons a,
	.filter__sentence__close,
	.share__twitter,
	.share__facebook,
	.button:after,
	#plannerHeader .opnieuw a span { background-image: url('../images/slice-mobile@2x.png'); background-size: 425px 280px; }

	.newsWrapper2 ul li,
	.knop,
	.btnRed .title span,
	.btnBlue .title span,
	#photo .prev a,
	.newsWrapper2 ul li,
	.txt #tijden li a.knopTickets,
	.search #bottomNav .arrowRight,
	.search #bottomNav ul li.right,
	#list.block li a .button { background-image: url('../images/arrow-right-mobile@2x.png'); background-size: 30px 14px; }

	#filterOverlay .knop,
	.btnGrey .title span{ background-image: url('../images/arrow-right-b-mobile@2x.png'); background-size: 30px 14px; }

	.knopTerug,
	#photo .next a,
	.search #bottomNav .arrowLeft,
	.search #bottomNav ul li.left { background-image: url('../images/arrow-left-mobile@2x.png'); background-size: 30px 14px; }

	#planner .select_stap_1,
	#planner .select_stap_2,
	.mainBlock .title.active span { background-image: url('../images/arrow-down-b-mobile@2x.png'); background-size: 30px 14px; }

	#planner .submit #plannerSubmit.loading { background-size: 16px; }

	.btn--filter:before {
		background-image: url('../images/plus-negativ@2x.png');
		background-size: 16px;
	}

	.btn--info:before {
		background-image: url('../images/min-down@2x.png');
		background-size: 16px;
	}

	.btn--info:before {
		background-image: url('../images/down@2x.png');
	}
}
