/**
 * DEAFULT COLORS:
 * Grijs: $whd-gray
 * Rood: $theme-color-02
 * Donker rood: $theme-color-02-dark;
 * Donkerder rood: $theme-color-02-darker
 */

 /* ==========================================================================
   0.0 Base
   ========================================================================== */

	::-webkit-input-placeholder {
		color: #ADADAD;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	:-moz-placeholder {
		color: #ADADAD;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	::-moz-placeholder {
		color: #ADADAD;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	:-ms-input-placeholder {
		color: #ADADAD;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	select,
	input {
		color: #ADADAD;
		font-size: $base-font-size;
		font-family: $good-bold;
	}


/* ==========================================================================
   Content sections home
   ========================================================================== */

	.content-section {
		@include box-sizing(border-box);
		padding: 30px 15px;
	}

	.content-section h1 {
		margin: 0;
		font-size: 34px;
		font-family: $good-bold;
	}

	.content-section__header { margin: 0 0 25px 0;}

	.content-section__header h1 {
		display: block;
		color: $whd-gray;
		font-size: 34px;
		font-family: $good-bold;
		margin: 0 0 10px 0;
	}

	.content-section__header a {
		position: relative;
		color: #303030;
		text-decoration: none;
		font-family: $good-bold;
	}

	.content-section__header a:after {
		position: absolute;
		width: 11px;
		height: 17px;
		content: "";
		margin-left: 10px;
		background-image: url('../images/slice.png');
		background-position: -675px -312px;
	}

	.content-half {
		float: left;
		font-size: 16px;
		line-height: 22px;

		p {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			margin-bottom: $med;

			li {
				margin-bottom: $x-small;
				float: none;
				padding-left: 20px;
				position: relative;

				&:before {
					content: "•";
					display: inline-block;
					position: absolute;
					left: 0;
					color: $theme-color-01;

				}

			}
		}

	}

	.content-half h1 {
		font-family: $good-bold;
		font-size: 37px;
		line-height: 42px;
		margin-bottom: 30px;
	}

	.content-half p {
		margin-bottom: $norm;
	}

	.content-half span {
		font-family: $good-bold;
		font-size: $base-font-size;
		color: #e8122e;
		margin-bottom: 30px;
		display: block;
	}




/* ==========================================================================
   Algemeen
   ========================================================================== */

	.alignright {
		float: right;
		margin-bottom: 5px;
		margin-left: 15px;
	}

	.alignleft {
		float: left;
		margin-right: 15px;
		margin-bottom: 5px;
	}

	.size-full {
		max-width: 100%;
	}

	.printText {
		display: none;
		visibility: hidden;
	}

	#printLogo {
		display: none;
	}

	#background {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -100;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	#photoSlide {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
	}

	#photoSlide .photo {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	#photoSlide .photoSlide {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

/* ==========================================================================
   4.0 Content pagina - Mobile
   ========================================================================== */

	#text {
		position: relative;
		background-color: $snow-white;
	}

	#textWrapper { padding: 0; }

	.mainBlock {
		float: left;
		clear: both;
		width: 100%;
	}

	.mainBlock .title {
		margin: 0;
		padding: 15px;
	}

	.mainBlock .title span{
		float: right;
		margin-top: -5px;
		width: 30px;
		height: 30px;
		-webkit-border-radius: 15px;
		border-radius: 15px;
		background-position: center center;
	}

	.mainBlock .title.active span { background-color: #E7E7E7; }

	.mainBlock .wrapper { padding: 15px; }

	.btnRed .title {
		background-color: $theme-color-02;
		color: $snow-white;
	}

	.btnRed .title.active {
		background-color: $snow-white;
		color: $theme-color-02;
	}

	.btnRed .title span { background-color: $theme-color-02-dark; }

	.btnBlue .title {
		background-color: $theme-color-01;
		color: $snow-white;
	}

	.btnBlue .title.active {
		background-color: $snow-white;
		color: $theme-color-01;
	}

	.btnBlue .title span { background-color: #0E5691; }

	.btnGrey .title {
		background-color: #E7E7E7;
		color: #2B322B;
	}

	.btnGrey .title.active {
		background-color: $snow-white;
		color: #2B322B;
	}

	.btnGrey .title span { background-color: #FAFAFA; }

	/* FAQ */
    #page_5842 ul,
	#page_52 ul {
		margin: 0;
		padding: 0;
		max-width: 630px;
		border-top: 2px solid $theme-color-02;
		list-style: none;
	}

    #page_5842 ul li,
	#page_52 ul li {
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: 1px solid $theme-color-02;
	}

    #page_5842 ul li h2,
	#page_52 ul li h2 {
		padding: 10px 0 5px 0;
		color: $theme-color-02;
		font-weight: bold;
		font-size: $base-font-size;
	}

    #page_5842 ul li h3,
	#page_52 ul li h3 {
		padding: 8px 0 8px 50px;
		background-image: url('../images/plus.gif');
		background-position: 12px 8px;
		background-repeat: no-repeat;
		font-weight: bold;
		font-size: 17px;
		font-family: $good;
	}

    #page_5842 ul li h3.active,
	#page_52 ul li h3.active {
		background-image: url('../images/min.gif');
		background-position: 12px 14px;
	}

    #page_5842 ul li h3:hover,
	#page_52 ul li h3:hover {
		cursor: pointer;
	}

    #page_5842 ul li p,
	#page_52 ul li p {
		padding-left: 50px;
	}

/* ==========================================================================
   6.0 Programma - Mobile
   ========================================================================== */

	#photo {
		position: relative;
		width: 100%;
		height: 280px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	#photo .next {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -30px;
		width: 60px;
		height: 60px;
	}

	#photo .prev {
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -30px;
		width: 60px;
		height: 60px;
	}

	#photo .prev a,
	#photo .next a {
		display: block;
		width: 60px;
		height: 60px;
		background-color: $whd-gray;
		background-position: center center;
		text-indent: -9999px;
	}

	#photo .pattern {
		width: 100%;
		height: 100%;
		background-image: url('../images/WHD_patroon');
	}

	#programmaContent { background-color: #E7E7E7; }

	.programmaWrapper {
		float: left;
		margin-right: -60px;
		width: 100%;
	}

	.programmaWrapper .programmaContainer {
		margin-right: 60px;
		padding: 15px;
		background-color: $snow-white;
	}

	.ticketWrapper {
		float: left;
		width: 100%;
	}

	.ticketWrapper .programmaContainer {
		padding: 15px;
		background-color: $snow-white;
	}

	#programmaContent h1 {
		margin: 0 0 15px 0;
		color: $whd-gray;
		line-height: 110%;
	}

	.tags {
		color: $theme-color-02;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	.txt .tags a { color: $theme-color-02; }

	.txt .tags a:hover { color: $theme-color-01; }

	.icons {
		display: inline-block;
		margin-right: 5px;
		width: 57px;
		height: 57px;
		float: left;
		margin-bottom: 10px !important;
	}

	.schip { background-position: -250px 0px; }

	.bus { background-position: -250px -60px; }

	.eigenvervoer { background-position: -250px -120px; }

	.english { background-position: -250px -180px; }

	.fiets { background-position: -310px 0; }

	.helikopter { background-position: -310px -60px; }

	.legitimatie { background-position: -310px -120px; }

	.nieuw { background-position: -310px -180px; }

	.rolstoel { background-position: -370px 0; }

	.step { background-position: -370px -60px; }

	.tevoet { background-position: -370px -120px; }

	.trein { background-position: -370px -180px; }

	.sidebar {
		float: right;
		width: 60px;
	}

	.shareButtons { }

	.shareButtons div { display: none; }

	.shareButtons a {
		display: block;
		width: 60px;
		height: 60px;
		background-color: $theme-color-02;
		cursor: pointer;
	}

	.shareButtons a:hover { background-color: $theme-color-02-darker; }

	.shareButtons a.share { background-position: 0px -170px; }

	.shareButtons a.twitter { background-position: -60px -170px; }

	.shareButtons a.facebook { background-position: -120px -170px; }

	.shareButtons a.pinterest { background-position: -180px -170px; }

	.shareButtons a.share { position: relative; }

	.shareButtons a.share.active { background-color: $theme-color-02-darker; }

	.shareButtons a.share.active:after {
		position: absolute;
		top: 100%;
		width: 0;
		height: 0;
		border: solid transparent;
		content: " ";
		pointer-events: none;
	}

	.shareButtons a.share.active:after {
		left: 50%;
		margin-left: -8px;
		border-width: 8px;
		border-color: transparent;
		border-top-color: $theme-color-02-darker;
	}

	#tijden {
		margin: 15px 0;
		padding: 0;
		border-top: 2px solid $theme-color-02;
		list-style: none;
		font-size: $base-font-size;
	}

	#tijden li { clear: both; }

	#tijden li a { color: $whd-gray; }

	#tijden li a strong {
		display: block;
		padding: 10px 0 10px 30px;
		border-bottom: 2px solid $theme-color-02;
		background: url('../images/plus.gif') no-repeat left center;
		font-family: $good-bold;
		cursor: pointer;
	}

	#tijden li.active a strong {
		background: url('../images/min.gif') no-repeat left center;
	}

	#tijden li ul {
		margin: 0 0 15px 0;
		padding: 0;
		list-style: none; /*display: none;*/
	}

	#tijden li ul li {
		margin: 0;
		padding: 13px 0 13px 30px;
		border-bottom: 1px solid $theme-color-02;
	}

	.txt #tijden li span { float: right; }

	.txt #tijden li span a {
		margin-top: -5px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		font-family: $good-bold;
	}

	.txt #tijden li span a.knopTickets {
		padding: 5px 40px 5px 10px;
		background-color: $theme-color-01;
		background-position: center right;
		background-repeat: no-repeat;
		color: $snow-white;
	}

	.txt #tijden li span a.knopTickets:hover {
		background-color: #005199;
		color: $snow-white;
	}

	.txt #tijden li span a.knopUitverkocht {
		padding: 5px 10px 5px 10px;
		background-color: #E7E7E7;
		color: $whd-gray;
	}

	.txt #tijden li span a.knopUitverkocht:hover {
		background-color: #B9B9B9;
		color: $whd-gray;
	}

/* ==========================================================================
   7.0 Planner - Mobile
   ========================================================================== */

	#plannerHeader {
		padding: 15px 15px 0 15px;
		background-color: $theme-color-01;
		color: $snow-white;
		font-size: $base-font-size;
	}

	#plannerHeader .container { }

	#plannerHeader .title {
		display: none;
		padding: 15px;
		background-color: $theme-color-02;
	}

	#plannerHeader .title h1 {
		margin: 0;
		padding: 0;
		color: $snow-white;
		font-size: 20px;
	}

	#plannerHeader .title p { font-family: $good-bold; }

	#plannerHeader .opnieuw {
		float: right;
		padding: 0;
		width: 100%;
		height: 70px;
		font-size: $base-font-size;
		font-family: $good-bold;
		line-height: 16px;
	}

	#plannerHeader .opnieuw a {
		display: block;
		padding: 18px 15px 0 15px;
		height: 52px;
		background-color: $theme-color-02;
		color: $snow-white;
		color: $snow-white;
		text-align: center;
	}

	#plannerHeader .opnieuw a:hover { background-color: $theme-color-02-darker; }

	#plannerHeader .opnieuw span {
		float: right;
		margin: -18px -15px;
		width: 60px;
		height: 60px;
		background-position: -180px -118px;
	}

	#plannerHeader .buttons {
		position: absolute;
		top: 90px;
		right: 0;
		width: 159px;
		height: 50px;
		background-color: $theme-color-01;
	}

	#plannerHeader .buttons a {
		display: block;
		float: left;
		margin: 0;
		padding: 0;
		width: 53px;
		height: 50px;
		background-color: $theme-color-01;
	}

	#plannerHeader .buttons a.active,

	#plannerHeader .buttons a:hover { background-color: #005199; }

	#plannerHeader .buttons a.active:after {
		position: absolute;
		top: 100%;
		width: 0;
		height: 0;
		border: solid transparent;
		content: " ";
		pointer-events: none;
	}

	#plannerHeader .buttons a.active:after {
		left: 27px;
		margin-left: -8px;
		border-width: 8px;
		border-color: transparent;
		border-top-color: #005199;
	}

	#plannerHeader .buttons div {
		display: none;
		clear: both;
		height: 50px;
	}

	#plannerHeader .buttons div a { background-color: $theme-color-01; }

	#plannerHeader .buttons div a:hover { background-color: #005199; }

	.buttons a.share { background-position: -2px -175px; }

	.buttons a.print { background-position: -60px -240px; }

	.buttons a.email { background-position: -0px -240px; }

	.buttons a.twitter { background-position: -62px -175px; }

	.buttons a.facebook { background-position: -122px -175px; }

	.buttons a.pinterest { background-position: -182px -175px; }

	.plannerList {
		margin: 0;
		padding: 0 !important;
		list-style: none;
		font-size: 1.2308em;
	}

	.plannerList li {
		margin: 10px 0;
		width: 100%;
		background-color: #E7E7E7;
	}

	.plannerList li a {
		display: block;
		color: $whd-gray;
		text-decoration: none;
	}

	.plannerList li a .image { display: none; }

	.plannerList li a .listWrapper {
		float: left;
		margin-right: -50px;
		width: 100%;
	}

	.plannerList li a .listWrapper div {
		margin-right: 50px;
		padding: 10px;
	}

	.plannerList li a .listWrapper div div {
		margin-right: 0;
		padding: 0;
	}

	.plannerList li a .listWrapper div div {
		margin-right: 0;
		padding: 0;
	}

	.plannerList li a .title {
		font-weight: bold;
	}

	.plannerList li a .button {
		font-family: $good-bold;
		display: block;
		float: right;
		width: 50px;
		height: 80px;
		background-color: $theme-color-01;
		background-position: -125px 10px;
	}

	.txt .plannerList li:hover a { color: $whd-gray; }

	.txt .plannerList li:hover .button { background-color: #005199; }

// /* ==========================================================================
//    8.0 Kaart - Mobile
//    ========================================================================== */

// 	#kaart {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		z-index: 0;
// 		width: 100%;
// 		height: 100%;
// 	}

// 	#kaartFilterButtonContainer {
// 		position: relative;
// 		width: 100%;
// 		pointer-event: none;
// 	}

// 	#kaartFilterButton { padding: 20px; }

// 	#kaartFilterButton a {
// 		float: left;
// 		clear: both;
// 		padding: 10px 60px 10px 20px;
// 		-webkit-border-radius: 3px;
// 		border-radius: 3px;
// 		background-color: $theme-color-01;
// 		color: $snow-white;
// 		text-decoration: none;
// 		font-size: 18px;
// 		font-family: $good-bold;
// 	}

// 	#kaartFilterButton a:hover { background-color: #005199; }

// 	#kaartFilterContainer {
// 		position: relative;
// 		width: 100%;
// 		pointer-event: none;
// 	}

// 	#kaartFilter {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		z-index: 100;
// 		display: none;
// 		width: 100%;
// 		height: auto;
// 		background-color: $theme-color-01;
// 		pointer-events: auto;
// 	}

// 	#kaartFilter .close {
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		z-index: 10;
// 		width: 60px;
// 		height: 60px;
// 	}

// 	#kaartFilter .close a {
// 		display: block;
// 		width: 60px;
// 		height: 60px;
// 		background-color: #005199;
// 		background-position: -180px -118px;
// 	}

// 	#kaartFilter .close a:hover { background-color: #005199; }

// 	#kaartFilter .wrapper { padding: 15px; }

// 	#kaartFilter .wrapper h1 {
// 		color: #233b48;
// 		font-size: 20px;
// 	}

// 	#kaartFilter .wrapper hr {
// 		margin: 6px 0 20px 0;
// 		height: 1px;
// 		border: none;
// 		background-color: #4d94d2;
// 	}

// 	#kaartFilter input { display: none; }

// 	#kaartFilter label {
// 		display: block;
// 		margin: 4px 0;
// 		padding: 15px 0px 15px 20px;
// 		-webkit-border-radius: 3px;
// 		border-radius: 3px;
// 		background-color: #3384CC;
// 		color: #0D5592;
// 		font-size: $base-font-size;
// 		font-family: $good-bold;
// 		cursor: pointer;
// 	}

// 	#kaartFilter label.active {
// 		background-color: $snow-white;
// 		color: $theme-color-01;
// 	}

// 	#kaartFilter label span {
// 		float: right;
// 		margin-top: -9px;
// 	}

// 	#kaartFilter .noBackground label {
// 		padding: 5px 0;
// 		background-color: transparent;
// 		color: #3284CD;
// 		font-size: 25px;
// 	}

// 	#kaartFilter .noBackground label.active {
// 		background-color: transparent;
// 		color: $snow-white;
// 	}

	.gm-style-iw {
		h2 {
			display: block;
			font-family: $good-bold;
		}
	}

	.infobox { }

	.infoBox .info {
		overflow: auto;
		padding: 10px;
		max-height: 230px;
		background-color: $snow-white;
		-webkit-box-shadow: 3px 3px 3px rgba(51,51,51,0.6);
		box-shadow: 3px 3px 3px rgba(51,51,51,0.6);
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	.infoBox .info a {
		color: $whd-gray;
		text-decoration: none;
	}

	.infoBox .info img { margin-bottom: 9px; }

	.infoBox .info .title { margin-bottom: 6px; }

	.infoBox .info .noImage {
		margin-bottom: 0;
		width: 170px;
	}

	.infoBox .info ul {
		margin: 30px 0 0 0;
		padding: 0;
		list-style: none;
	}

	/*.infoBox .info ul.titleAbove { margin: 20px 0 0 0; }*/

	.infoBox .info ul li {
		border-top: 1px solid #D5D5D5;
		background-color: #E7E7E7;
	}

	.infoBox .info ul li:first-child { border-top: none; }

	.infoBox .info ul li a {
		display: block;
		padding: 5px;
	}

	.infoBox .info .content {
		overflow: auto;
		margin: 20px 0 0 0;
		max-height: 160px;
	}

	.infoBox .info .content ul { margin: 0; }

	.arrow-down {
		margin: 0 auto 30px auto;
		padding: 0;
		width: 0;
		height: 0;
		border-top: 15px solid $snow-white;
		border-right: 15px solid transparent;
		border-left: 15px solid transparent;
		background-color: 0;
	}

	.black{ color: $whd-gray; }

	.container { position: relative; }

	.clearfix { clear: both; }

	// .desktop { display: none; }

	.left { position: relative; float: left; }

	.right {
		position: relative;
		float: right;
	}

	.submit a { background-position: -120px 0; }

	.txt {
		font-size: 15px;
		line-height: 22px;
	}

	.txt h1 {
	 	margin: 10px 0 20px 0;
	 	padding: 0;
	 	color: $theme-color-02;
		font-size: 32px;
	 	font-family: $good-bold;
	 }

	.txt h1.date {
		margin-bottom: 30px;
		padding-top: 5px;
		padding-bottom: 15px;
		border-bottom: 1px solid $whd-gray;
		color: $whd-gray;
		font-size: 22px;
	}

	.txt h1.title40 {
		display: inline-block;
		font-size: 42px;
		line-height: 47px;
	}

	.txt h2 {
		margin: 0;
		padding: 0;
		font-size: 1.2308em;
		font-family: $good-bold;
	}

	.txt h3 {
		margin: 0;
		padding: 0;
		font-size: 1.2308em;
		font-family: $good-bold;
	}

	.txt h3.red { color: $theme-color-02; }

	.txt a {
		color: $theme-color-01;
		text-decoration: none;
	}

	.txt a:hover {
		color: $theme-color-02;
	}

	.txt hr {
		clear: both;
		height: 1px;
		border: none;
		background-color: $theme-color-01;
	}

	.txt ul {
		margin: 0;
		padding: 0 0 0 20px;
	}

	.txt p + ul { margin-bottom: 0px; }

	.txt h2 + p { margin-top: 0px; }

	.knop {
		display: block;
		margin: 5px 0;
		padding: 10px 30px 10px 10px;
		width: 80%;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background-color: $theme-color-02;
		background-position:
		center right;
		color: $snow-white !important;
		text-decoration: none;
		font-size: 17px;
		font-family: $good-bold;

		&.inline {
			width: auto;
			display: inline-block;
		}

	}

	.knop:hover { background-color: $theme-color-02-darker; }

	.knopTerug {
		display: inline-block;
		margin: 5px 0;
		padding: 10px 10px 10px 30px;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background-color: $theme-color-01;
		background-position: center left;
		color: $snow-white !important;
		text-decoration: none;
		font-size: 17px;
		font-family: $good-bold;
	}

	.knopTerug:hover { background-color: #005199; }

	#allFaq {
		color: $whd-gray;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	.red { color: $theme-color-02; }
	.blue { color: $theme-color-01; }

	.txt h2.big {
		font-size: 42px;
		line-height: 110%;
	}

	.wrapper { position: relative; }

	.noresize { width: auto !important; }

	#bestellen { display: none; }


/* ==========================================================================
   10.0 Helper classes - Mobile
   ========================================================================== */

	.bg--white { background-color: $snow-white; }

	.bg--gray { background-color: $whd-gray-light; }

	.bg--red { background-color: $theme-color-02; }

	.bg--blue { background-color: $theme-color-01; }

	.clearfix { zoom: 1; }

	.clearfix:before {
		display: table;
		content: "";
	}

	.clearfix:after {
		display: table;
		clear: both;
		content: "";
	}

	/*
	 * Image replacement
	 */

	.ir {
	    overflow: hidden;
	    border: 0;
	    background-color: transparent;
	    /* IE 6/7 fallback */
	    *text-indent: -9999px;
	}

	.ir:before {
	    display: block;
	    width: 0;
	    height: 150%;
	    content: "";
	}

	/*
	 * Hide from both screenreaders and browsers: h5bp.com/u
	 */

	.hidden {
	    display: none !important;
	    visibility: hidden;
	}

	/*
	 * Hide only visually, but have it available for screenreaders: h5bp.com/v
	 */

	.visuallyhidden {
	    position: absolute;
	    overflow: hidden;
	    clip: rect(0 0 0 0);
	    margin: -1px;
	    padding: 0;
	    width: 1px;
	    height: 1px;
	    border: 0;
	}

	/*
	 * Extends the .visuallyhidden class to allow the element to be focusable
	 * when navigated to via the keyboard: h5bp.com/p
	 */

	.visuallyhidden.focusable:active,
	.visuallyhidden.focusable:focus {
	    position: static;
	    overflow: visible;
	    clip: auto;
	    margin: 0;
	    width: auto;
	    height: auto;
	}

	/*
	 * Hide visually and from screenreaders, but maintain layout
	 */

	.invisible {
	    visibility: hidden;
	}

	/*
	 * Clearfix: contain floats
	 *
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    `contenteditable` attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that receive the `clearfix` class.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */

	.clearfix:before,
	.clearfix:after {
	    display: table; /* 2 */
	    content: " "; /* 1 */
	}

	.clearfix:after {
	    clear: both;
	}

	/*
	 * For IE 6/7 only
	 * Include this rule to trigger hasLayout and contain floats.
	 */

	.clearfix {
	    *zoom: 1;
	}
	/*
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px), only screen and (min-resolution: 144dpi) and (max-width: 767px)  {

		.menu-icon a,
		.search-icon a,
		.submit a,
		.logo a,
		.close a,
		#btnSearch { background-image: url('../images/slice-mobilex2.png'); background-repeat: no-repeat; text-indent: -9999px; }
	}
	*/

/* ==========================================================================
   11.0 Isotope - Mobile
   ========================================================================== */

	.isotope-item {
	  z-index: 2;
	}

	.isotope-hidden.isotope-item {
	  z-index: 1;
	  pointer-events: none;
	}

	/**** Isotope CSS3 transitions ****/

	.isotope,
	.isotope .isotope-item {
	  -webkit-transition-duration: 0.8s;
	     -moz-transition-duration: 0.8s;
	      -ms-transition-duration: 0.8s;
	       -o-transition-duration: 0.8s;
	          transition-duration: 0.8s;
	}

	.isotope {
	  -webkit-transition-property: height, width;
	     -moz-transition-property: height, width;
	      -ms-transition-property: height, width;
	       -o-transition-property: height, width;
	          transition-property: height, width;
	}

	.isotope .isotope-item {
	  -webkit-transition-property: -webkit-transform, opacity;
	     -moz-transition-property:    -moz-transform, opacity;
	      -ms-transition-property:     -ms-transform, opacity;
	       -o-transition-property:      -o-transform, opacity;
	          transition-property:         transform, opacity;
	}

	/**** disabling Isotope CSS3 transitions ****/

	.isotope.no-transition,
	.isotope.no-transition .isotope-item,
	.isotope .isotope-item.no-transition {
	  -webkit-transition-duration: 0s;
	     -moz-transition-duration: 0s;
	      -ms-transition-duration: 0s;
	       -o-transition-duration: 0s;
	          transition-duration: 0s;
	}

	/* End: Recommended Isotope styles */



	/* disable CSS transitions for containers with infinite scrolling*/
	.isotope.infinite-scrolling {
	  -webkit-transition: none;
	     -moz-transition: none;
	      -ms-transition: none;
	       -o-transition: none;
	          transition: none;
	}


/* ==========================================================================
   Desktop min-width: 480px
   ========================================================================== */

@media only screen and (min-width: 480px) {


/* ==========================================================================
   1.0 Socials home - mobile
   ========================================================================== */


	.content-section__header h1 {
		display: inline-block;
		font-size: 42px;
		margin: 0 0 0 0;
	}

	.content-section__header a {
		float: right;
		margin: 22px 21px 0 0;
	}


}

/* ==========================================================================
   Desktop min-width: 768px
   ========================================================================== */

@media only screen and (min-width: 768px) {

	.container {
		width: 730px;
		margin: 0 auto;
	}

	.container--border {
		margin-top: -60px!important;
	}

	.content-section {
		padding: 60px 15px;
	}

	.content-half { width: 50%; }

	.content-half span { margin-bottom: 60px; }



	.visitors-info h1 {
		margin: 0 0 30px 0;
	}

	.visitors-info p {
		float: left;
		margin: 0 0 0 0;
	}

	.visitors-info .btn {
		float: right;
		width: 243px;
		margin-left: 20px;
		margin-right: 0;
		margin-bottom: 0;
	}

   /* ==========================================================================
   14.0 Newsfeed - Desktop (min 768px)
   ========================================================================== */

	#newsFeed { height: 75px; }

	.newsWrapper {
		width: 520px;
		height: 75px;
		float: left;
		overflow: hidden;
		background-color: #0085B5;
	}

	.newsWrapper .icon {
		width: 75px;
		height: 75px;
		float: left;
		background-color: #006A91;
		position: relative;
		background-position: -52px -162px;
	}

	.newsWrapper .icon:after {
		left: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position:
		absolute;
		pointer-events: none;
	}

	.newsWrapper .icon:after {
		border-color:
		transparent;
		border-left-color: #006A91;
		border-width: 8px;
		top: 50%;
		margin-top: -8px;
	}

	.newsWrapper .newsText {
		width: 445px;
		height: 75px;
		float: left;
		overflow: hidden;
	}

	.newsWrapper .newsText ul { width: 445px !important; }

	.newsWrapper .newsText ul li {
		padding: 8px 25px 0 25px;
		height: 67px;
		width: 395px !important;
		font-family: $good;
		font-size: 16px;
		color: $snow-white;
		line-height: 22px;
	}

	.newsWrapper .newsText a { color: $snow-white; }

	.newsWrapper2 ul { width: 520px !important; }

	.newsWrapper2 ul li {
		background-position:
		right center;
		background-color: #0085B5;
		width: 100% !important;
		font-family: $good;
		height: 75px;
		font-size: 16px;
		color: $snow-white;
		line-height: 22px;
	}

	.newsWrapper2 ul li a { color: $snow-white; }

	.newsWrapper2 ul li img { float: left; }

	.newsWrapper2 ul li.block_facebook {background-color: #54739F; }

	.newsWrapper2 ul li.block_facebook .icon {
		background-position: -112px -162px;
		background-color: #335B99;
	}

	.newsWrapper2 ul li .icon {
		width: 75px;
		height: 75px;
		float: left;
		background-color: #335B99;
		position: relative;
		background-position: -52px -162px;
	}

	.newsWrapper2 ul li .icon:after {
		left: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	.newsWrapper2 ul li .icon:after {
		border-color:
		transparent;
		border-left-color: #335B99;
		border-width: 8px;
		top: 50%;
		margin-top: -8px;
	}

	.newsWrapper2 .newsText {
		width: 395px;
		height: 67px;
		float: left;
		overflow:
		hidden;
		padding: 8px 25px 0 25px;
	}

	.newsWrapper2 .newsTextImage {
		width: 200px;
		height: 63px;
		float: left;
		overflow: hidden;
		padding: 8px 30px 0px 15px;
	}

	.newsWrapper2 .newsImage {
		width: 200px;
		height: 75px;
		float: left;
		overflow: hidden;
		text-align: center;
	}

	.newsWrapper .table {
		display: table;
		height: 100%;
	}

	.newsWrapper .table-cell {
		display: table-cell;
		vertical-align: middle;
	}

 //   /* ==========================================================================
 //   14.0 Newsletter - Desktop (min 768px)
 //   ========================================================================== */

	// #newsletterButton {
	// 	width: 210px;
	// 	height: 75px;
	// 	float: right;
	// 	overflow: hidden;
	// }

	// #newsletterButton a {
	// 	background-color: #E7E7E7;
	// 	font-family: $good-bold;
	// 	font-size: 18px;
	// 	line-height: 16px;
	// 	display: block;
	// 	padding: 25px 30px 18px 30px;
	// 	color: $whd-gray;
	// 	text-decoration: none;
	// }

	// #newsletterButton a:hover {  background-color: #B9B9B9; }

	// #newsletter {
	// 	height: 100px;
	// 	background-color: #E7E7E7;
	// 	display: none;
	// }

	// #newsletter .container { padding: 20px 0 0 0; }

	// #newsletter form {
	// 	margin: 0;
	// 	padding: 0;
	// }

	// #newsletter form input[type=email],
	// #newsletter form input[type=text] {
	// 	width: 478px;
	// 	padding: 15px 20px;
	// 	margin-right: 20px;
	// 	float: left;
	// 	border: 1px solid #E7E7E7;
	// 	-webkit-border-radius: 3px;
	// 	border-radius: 3px;
	// }

	// #newsletter form input[type=submit] {
	// 	font-family: $good-bold;
	// 	padding: 15px 0 14px 20px;
	// 	width: 190px;
	// 	float: left;
	// 	display: inline-block;
	// 	border: none;
	// 	color: $snow-white;
	// 	background-color: $theme-color-02;
	// 	text-align: left;
	// 	-webkit-border-radius: 3px;
	// 	border-radius: 3px;
	// }

	// #newsletter form input[type=submit]:hover { background-color: $theme-color-02-darker; }

	// #newsletter input::-webkit-input-placeholder { color: #A6A6A6; }

	// #newsletter input:-moz-placeholder { color: #A6A6A6; }

	// #newsletter input::-moz-placeholder { color: #A6A6A6; }

	// #newsletter input:-ms-input-placeholder { color: #A6A6A6; }

	// #newsletter label.error { display: none !important; }

	// #newsletter form input[type=email].error,
	// #newsletter form input[type=text].error { border: 1px solid #F00; }

	// #newsletterResult {
	// 	display: none;
	// 	color: $whd-gray;
	// 	font-family: $good;
	// 	font-size: 15px;
	// 	line-height: 20px;
	// }

	// #newsletterResult .result {
	// 	width: 540px;
	// 	float: left;
	// }

	// #newsletterResult .result div { width: 100%; }

	// #newsletterResult .again {
	// 	width: 190px;
	// 	float: left;
	// }

   /* ==========================================================================
   15.0 Content pagina - Desktop (min 768px)
   ========================================================================== */

	#content {
		position: relative;
		// top: 260px;
		// margin-top: -108px;
		margin-top: 150px;
		width: 100%;
		-webkit-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
	}

	#content .container .wrapper { padding: 0; }

	.programma .wrapper { padding: 15px 0 !important; }

   /* ==========================================================================
   16.0 Filter - Desktop (min 768px)
   ========================================================================== */

	.programma a#filter {
		float: left;
		margin-left: 30px;
	}

	#meerinfo { margin-right: 0px; }

	#infoOverlay { }

	#infoOverlay .close {
		width: 78px;
		height: 78px;
	}

	#infoOverlay .close a {
		width: 78px;
		height: 78px;
		background-position: -560px -280px;
	}

	#infoOverlay .wrapper {
		padding: 25px 0 !important;
		width: 640px;
	}

	#filterOverlay { }

	#filterOverlay .close {
		width: 78px;
		height: 78px;
	}

	#filterOverlay .close a {
		width: 78px;
		height: 78px;
		background-position: -560px -280px;
	}

	#filterOverlay hr { display: none; }

	#filterOverlay .knop {
		float: right;
		clear: both;
	}

	#filterOverlay .wrapper { padding: 25px 0 !important; }

	#switch {
		height: 40px;
		float: right;
		margin-top: 9px;
	}

	#switch a {
		width: 40px;
		height: 40px;
		margin-left: 10px;
		float: left;
		display: block;
		background-color: #E7E7E7;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		text-indent: -9999px;
	}

	#switch a:hover { background-color: #B9B9B9; }

	#switch a.list { background-position: -640px -80px; }

	#switch a.block { background-position: -640px -40px; }

	#switch a.active { background-color: $theme-color-01; }

	#switch a.list.active { background-position: -680px -80px; }

	#switch a.block.active { background-position: -680px -40px; }

	#text {
		position: relative;
		margin-top: 150px;
		width: 100%;
		-webkit-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
		-moz-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
	}

	.mainBlock { padding: 30px 0; }

	#text .title { background-color: transparent !important; }

	.title span { display: none; }

	.bckWhite .title { color: $theme-color-02; }

	.bckBlue {
		background-color: $theme-color-01;
		color: $snow-white;

		textarea,
		select,
		input {
			color: $whd-gray !important;
		}

	}

	.bckBlue .title { color: $snow-white; }

	.bckBlue .knop {
		background-image: url('../images/arrow-right-mobile-blue.png');
		background-color: $snow-white;
		color: $theme-color-01 !important;
	}

	.bckGrey { background-color: #EDEDED; }

	.bckGrey .title { color: $theme-color-02; }

	.bckRed {
		background-color: $theme-color-02;
		color: $snow-white;

		textarea,
		select,
		input {
			color: $whd-gray !important;
		}
	}

	.bckRed .title { color: $snow-white; }

	.bckRed a { color: $snow-white; }

	.bckRed a:hover { color: $snow-white; }

	#submenu {
		position: absolute;
		top: -60px;
		width: 100%;
		font-family: $good-bold;
		font-size: 17px;
		height: 60px;
		z-index: 100;
	}

	#submenu .container div.submenuContainer {
		display: table;
		width: 720px;
		padding: 10px 5px;
		background-color: $theme-color-02;
	}

	#submenu ul {
		margin: 10px 0;
		padding: 0;
		height: 40px;
		list-style: none;
		display: table-row;
		table-layout: fixed;
		clear: both;
		padding: 10px 0;
		width: 720px;
	}

	#submenu ul li {
		padding: 0 5px;
		margin: 0 5px;
		display: table-cell;
		width: auto;
	}

	#submenu ul li a {
		display: block;
		background-color: $theme-color-02-dark;
		color: $snow-white;
		text-decoration: none;
		padding: 10px 0;
		text-align: center;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		line-height: 20px;
	}

	#submenu ul li a:hover {
		background-color: $snow-white;
		color: $whd-gray;
	}

	#submenu ul li a:active {
		background-color: $snow-white;
		color: $theme-color-01;
	}

	#submenu.sticky {
		position: fixed;
		top: 0;
	}

	.contentBlock {
		width: 46%;
		margin-right: 4%;
		float: left;
	}

	.contentBlock3 {
		width: 30%;
		margin-right: 3%;
		float: left;
	}

	.contentBlock3 img { width: 100%; }

	.group_1 {
		width: 30%;
		float: left;
	}

	.group_2 {
		width: 65%;
		float: right;
	}

	.logo {
		margin-top:15px;
		z-index: 10;
		position: relative;
	}

	.logo a {
		width: 100px;
		height: 105px;
		display: block;
		text-indent: -9999px;
		background-repeat: no-repeat;
		background-position: 0 0;
		background-size: contain;
	}

	.var1 a {
		background-image: url(../images/logo--whd-geel-rood.svg);
	} /* geel - rood */

	.var2 a {
		background-image: url(../images/logo--whd-blauw-wit.svg);
	} /* blauw - wit */

	.var3 a {
		background-image: url(../images/logo--whd-wit-blauw.svg);
	} /* wit - blauw */

	.var4 a {
		background-image: url(../images/logo--whd-wit-rood.svg);
	} /* wit - rood */

	.var5 a {
		background-image: url(../images/logo--whd-rood-zwart.svg);
	} /* rood - zwart */

	.var6 a {
		background-image: url(../images/logo--whd-geel-zwart.svg);
	} /* geel - zwart */

	// body.home .logo a {
	// 	width: 143px;
	// 	height: 256px;
	// }

	// body.home .var1 a {
	// 	background-image:url(../images/logo-blauwrood.png);
	// 	background-position: left top;
	// 	background-size: 132px;
	// }

	// body.home .var2 a {
	// 	background-image:url(../images/logo-blauwwit.png);
	// 	background-position: left top;
	// 	background-size: 132px;
	// }

	// body.home .var3 a {
	// 	background-image:url(../images/logo-geelrood.png);
	// 	background-position: left top;
	// 	background-size: 132px;
	// }

	// body.home .var4 a {
	// 	background-image:url(../images/logo-roodzwart.png);
	// 	background-position: left top;
	// 	background-size: 132px;
	// }

	// body.home .var5 a {
	// 	background-image:url(../images/logo-witblauw.png);
	// 	background-position: left top;
	// 	background-size: 132px;
	// }

	// body.home .var6 a {
	// 	background-image:url(../images/logo-witrood.png);
	// 	background-position: left top;
	// 	background-size: 132px;
	// }


	.search #titel{

	}



	.search #titel h1 {

	}

	.search #subNav{

	}

	.search ul#results{

	}

	.search #bottomNav{

	}

   /* ==========================================================================
   18.0 Programma - Desktop (min 768px)
   ========================================================================== */

	#programmaBackground {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: fixed;
		overflow: hidden;
	}

	#photo {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: -1;
	}

	#map {
		left: 0;
		top: 100%;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 0;
	}

	#programmaContent {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 600px;
		background-color: transparent;
		z-index: 0;
		pointer-events: none;
	}

	.programmaWrapper {
		width: 470px;
		right: 78px;
		margin-right: 0;
		position: absolute;
		pointer-events: auto;
		background-color: $snow-white;
	}

	.programmaWrapper .programmaContainer,
	.ticketWrapper .programmaContainer {
		width: 100%;
		margin-right: 0;
		padding: 0;
		position: relative;
	}

	.programmaTitle {
		height: 78px;
		display: table;
		width: 100%;
	}

	.programmaTitle h1 {
		display: table-cell;
		vertical-align: middle;
		padding: 0 15px;
		margin: 0 !important;
	}

	.programmaTitle div.close {
		float: right;
		width: 78px;
	}

	.programmaTitle div.close a.close {
		width: 78px;
		height: 78px;
		background-color: #E7E7E7;
		display: block;
		background-position: -320px -280px;
	}

	.programmaTitle div.close a.close:hover { background-color: #B9B9B9; }

	.programmaContent {
		position: absolute;
		top: 75px;
		height: 522px;
		overflow: auto;
	}

	.programmaPadding { padding: 15px; }

	.ticketWrapper {
		width: 730px;
		margin-right: 0;
		position: absolute;
		pointer-events: auto;
		background-color: $snow-white;
	}

	.sidebar {
		width: 78px;
		float: right;
		pointer-events: auto;
	}

	.sidebar a.close {
		width: 78px;
		height: 78px;
		background-color: #E7E7E7;
		display: block;
		background-position: -320px -280px;
	}

	.sidebar a.close:hover { background-color: #B9B9B9; }

	.sidebar a.open {
		width: 78px;
		height: 78px;
		background-color: $theme-color-02;
		display: block;
		background-position: -320px -200px;
	}

	.sidebar a.open:hover { background-color: $theme-color-02-darker; }

	.shareButtons { }

	.shareButtons a {
		width: 78px;
		height: 78px;
	}

	.shareButtons a.share { background-position: 9px -161px; }

	.shareButtons a.twitter { background-position: -51px -161px; }

	.shareButtons a.facebook { background-position: -111px -161px; }

	.shareButtons a.pinterest { background-position: -171px -161px; }

	.toggleView {
		position: absolute;
		left: 0; bottom: 0;
		font-family: $good-bold;
		font-size: 20px;
		pointer-events: auto;
	}

	.toggleView a {
		background-color: $snow-white;
		color: $whd-gray;
		padding: 20px 20px 15px 20px;
		float: left;
		text-decoration: none;
	}

	.toggleView a span {
		background-position: -434px -236px;
		width: 13px;
		height: 9px;
		margin-left: 20px;
		margin-top: 7px;
		display: block;
		float: right;
	}

	.toggleView a.map { display: none; }

   /* ==========================================================================
   19.0 Planner - Desktop (min 768px)
   ========================================================================== */

	.single-whd_planner #content { margin-top: -90px; }

	#plannerHeader {
		padding: 0;
		background-color: transparent;
		color: $snow-white;
		top: -90px;
		height: 90px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}

	#plannerHeader .container { padding: 0px !important; }

	#plannerHeader .title {
		padding: 15px 15px 0 15px;
		width: 541px;
		float: left;
		height: 75px;
		overflow: hidden;
		display: block;
	}

	#plannerHeader .title h1 {
		color: $snow-white;
		margin: 0;
		padding: 0;
		font-size: 27px;
	}

	#plannerHeader .opnieuw {
		padding: 0;
		width: 159px;
		float: right;
		height: 90px;
	}

	#plannerHeader .opnieuw a {
		padding: 18px 15px 0 15px;
		color: $snow-white; display: block;
		text-align: center;
		height: 72px;
		background-color: $theme-color-02-darker;
	}

	#plannerHeader .opnieuw a:hover { background-color: #A40D21; }

	#plannerHeader .opnieuw a span {
		display: block;
		width: 13px;
		height: 12px;
		float: none;
		background-position: -434px -314px;
		margin: 0 auto 10px auto;
	}

	.planner .container { padding: 15px 0; }

	.single-whd_planner #content.planner .container .wrapper { padding-left: 15px; }

	.plannerList { margin-bottom: 55px; }

	.plannerList li {
		background-color: transparent;
		width: 475px;
	}

	.plannerList li a { position: relative; }

	.plannerList li a .image {
		display: block;
		float: left;
		width: 195px;
	}

	.plannerList li a .image img {
		width: 175px;
		height: 130px;
	}

	.plannerList li a .listWrapper {
		float: left;
		width: 280px;
		padding-top: 5px;
	}

	.plannerList li a .listWrapper div { padding: 0; }

	.plannerList li a .listWrapper div div {  }

	.plannerList li a .button {
		position: absolute;
		bottom: 5px;
		left: 195px;
		text-indent: 0;
		background-image: url('../images/arrow-right-mobile.png');
		background-repeat: no-repeat;
		background-position: right center;
		color: $snow-white;
		width: 250px;
		height: auto;
		float: left;
		padding: 10px 15px;
	}

 //   /* ==========================================================================
 //   20.0 Kaart - Desktop (min 768px)
 //   ========================================================================== */

	// #kaartFilterButtonContainer {
	// 	pointer-events: none;
	// 	position: fixed;
	// 	bottom: 0;
	// 	left: 0;
	// }

	// #kaartFilterButton {
	// 	width: 120px;
	// 	pointer-events: auto;
	// 	background-color: #E7E7E7;
	// }

	// #kaartFilterContainer {
	// 	pointer-events: none;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	height: 0;
	// 	overflow: hidden;
	// }

	// #kaartFilterContainer .container {  }

	// #kaartFilter {
	// 	display: block;
	// 	height: 490px;
	// 	width: 270px;
	// 	pointer-events: auto;
	// }

	// #kaartFilter .close {
	// 	width: 78px;
	// 	height: 78px;
	// }

	// #kaartFilter .close a {
	// 	width: 78px;
	// 	height: 78px;
	// 	background-position: -560px -280px;
	// }

	// #kaartFilter .wrapper h1 { margin: 13px 0 50px; }

   /* ==========================================================================
   21.0 Background images - Desktop (min 768px)
   ========================================================================== */

	.submit a { background-position: -315px -110px; }

	.twitter a,
	.facebook a,
	.flickr a,
	.menu-icon a,
	.search-icon a,
	.ticket-icon a,
	.submit a,
	// .logo a,
	.close a,
	a.close,
	a.open,
	.button,
	.toggleView a span,
	#switch a,
	#plannerHeader .opnieuw a span,
	#btnSearch {
		background-image: url('../images/slice.png');
		background-repeat: no-repeat;
		text-indent: -9999px;
	}

	.txt h1.date { width: 555px; }
	.txt p.small { width: 515px; }

}

/* ==========================================================================
   Desktop min-width: 960px
   ========================================================================== */

@media only screen and (min-width: 960px) {

	.container { width: 960px; }

	.newsWrapper { width: 650px; }

	.newsWrapper .newsText { width: 575px; }

	.newsWrapper .newsText ul { width: 575px !important; }

	.newsWrapper .newsText ul li {
		padding: 0 25px;
		width: 525px !important;
		height: 75px;
	}

	.newsWrapper2 ul { width: 650px !important; }

	.newsWrapper2 .newsText { width: 525px; }

	.newsWrapper2 .newsTextImage { width: 250px;  }

	.newsWrapper2 .newsImage { width: 280px; }

	.visitors-info .btn {
		width: 286px;
	}

	// .filter__sidebar { width: 25%; }

	// .filter__sentence {
	// 	padding: 70px 0 10px 0;
	// 	font-size: 24px;
	// }

	// .filter__sentence__step {
	// 	margin: -16px 0 16px 0;
	// 	font-size: 24px;
	// }

	// .filter__sentence__step select { margin-right: 16px; padding: 10px 16px;}
	// .filter__sentence .text { margin-right: 16px; }

	// #list.block { margin: 0 -10px; }
	// 	#list.block li { width: 200px; height: 210px; margin: 10px; }
	// 	#list.block li div.image,
	// 	#list.block li img.lazy { width: 200px; height: 160px; }
	// 	#list.block li.medium { width: 420px; height: 210px; }
	// 	#list.block li.large { width: 420px; height: 440px; }
	// 	#list.block li .image,
	// 	#list.block li img.lazy { width: 200px; height: 160px; }
	// 	#list.block li.medium .image,
	// 	#list.block li.medium img.lazy { width: 420px; height: 160px; }
	// 	#list.block li.large .image,
	// 	#list.block li.large img.lazy { width: 420px; height: 390px; }


	#submenu {
		top: -80px;
		font-size: $base-font-size;
	}

	#submenu .container div.submenuContainer {
		padding: 20px 15px;
		width: 960px;
		box-sizing: border-box;
	}

	#submenu ul {
		// width: 830px;
		height: 40px;
	}

	.mainBlock .title { padding: 15px 20px; }

	.mainBlock .wrapper { padding: 15px 20px; }

	/*
		#submenu .container div.submenuContainer { display: table; width: 720px; padding: 10px 5px; background-color: $theme-color-01; }
		#submenu ul { margin: 10px 0; padding: 0; height: 40px; list-style: none; display: table-row;table-layout: fixed; clear: both; padding: 10px 0; width: 720px; }
		#submenu ul li { padding: 0 5px; margin: 0 5px; display: table-cell; width: auto; }
		#submenu ul li a { display: block; background-color: #3484CD; color: $snow-white; text-decoration: none; padding: 10px 0; text-align: center; -webkit-border-radius: 3px; border-radius: 3px; }
		#submenu ul li a:hover { background-color: $snow-white; color: #3484CD; }
	*/

	#plannerHeader .title { width: 671px;  }

	#plannerHeader .title h1 {
		margin-top: 10px;
		font-size: 32px;
	}

	#plannerHeader .title p { margin-top: 8px; }

	.programmaWrapper { width: 502px; }

	.ticketWrapper { width: 860px; }

	.txt h1.date { width: 685px; }

	.txt p.small { width: 645px; }

   /* ==========================================================================
   22.0 Newsletter - Desktop (min 960px)
   ========================================================================== */

	// #newsletter form input[type=email],
	// #newsletter form input[type=text] { width: 608px; }

	// #newsletterResult .result { width: 670px; }

}

/* ==========================================================================
   Desktop min-width: 1350px
   ========================================================================== */

@media only screen and (min-width: 1350px) {

	// .post-type-archive-whd_programma #content .container { width: 1080px; }

	// .post-type-archive-whd_programma #content .container .wrapper {
	// 	margin: 0 auto;
	// 	width: 860px;
	// }

	// .post-type-archive-whd_programma #content #list.list li {
	// 	margin-left: 110px;
	// 	width: 860px;
	// }

	// #filterOverlay .close,

	// #infoOverlay .close { right: 110px; }

	// .post-type-archive-whd_programma #content #infoOverlay .wrapper {
	// 	margin-left: 110px;
	// 	width: 640px;
	// }

}
