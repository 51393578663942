/* ==========================================================================
   Socials homepage
   ========================================================================== */

	.socials {
		@include box-sizing(border-box);
		float: left;
		width: 100%;
		font-family: $good-bold;

		@include respond-to(medium-and-up) {
			width: 50%;
		}

		img {
			vertical-align: middle;

		}

		a {
			margin: 0;
			color: $snow-white;
			text-decoration: underline;
			line-height: 1.25;
		}
	}

	/* Socials Twitter & Facebook
   ========================================================================== */

	.social--twitter,
	.social--facebook {
		position: relative;
		float: left;
		margin-bottom: 20px;
		width: 100%;

		.social__image {
			@include background-size(cover);
			display: none;
			background-position: center center;
			width: 50%;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
		}

		@include respond-to(compact-and-up) {
			width: 100%;
			min-height: 215px;

			.social__image {
				width: 50%;
				display: block;
				float: right;
			}
		}
	}

	.social--facebook {
		background-color: #335b99;

		.social__image {
			background-image: img-url('placeholder-fb.jpg');
		}
	}

	.social--twitter {
		background-color: #0085b5;

		.social__image {
			background-image: img-url('placeholder-tw.jpg');
		}
	}

	.social__content {
		@include box-sizing(border-box);
		float: left;
		padding: 20px 20px 40px 20px;
		width: 100%;
		color: white;

		@include respond-to(compact-and-up) {
			width: 50%;
		}
	}

	.social__content:before {
		display: block;
		margin-bottom: 15px;
		width: 20px;
		height: 25px;
		background-image: url('../images/slice.png');
		content: "";
	}

	.social--facebook .social__content:before { background-position: -593px -228px; }

	.social--twitter .social__content:before { background-position: -670px -228px; }

	/* Socials instagram
   	========================================================================== */

	.social--instagram {
		float: right;
		display: inline-block;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 8px;
			width: 92px;
			height: 26px;
			background-image: url('../images/instagram.png');
		}

		.instagram__img {
			padding: 12px;
			background-color: #bdb6b0;
			display: inline-block;

			img {
				width: 100%;
			}
		}

		.instagram__avatar {
			border-radius: 3px;
			margin: 0 10px 10px 0;
			border: 1px solid #929aa2;
		}

		.instagram__name {
			color: #3f729b;
		}

		ul {
			margin-top: 10px;

			li {
				float: left;
				color: #c1c1c1;
				margin-right: 10px;

				&:before {
					content: "";
					display: inline-block;
					width: 15px;
					height: 13px;
					margin-right: 10px;
					background-image: url('../images/insta-hart.png');
					background-repeat: no-repeat;
				}
			}
		}

		@include respond-to(medium-and-up) {
			display: block;
			margin-left: $med;
		}
	}

	.social--instagram header img {
		width: 30px;
	}
