// --------------------------------------------------------------------------
// Path functions (http://j.mp/Tx8lGt)
// --------------------------------------------------------------------------

// @function icon-url($filename) {
// 	@return url(../images/icons/#{$filename});
// }

@function img-url($filename) {
	@return url(../images/#{$filename});
}

// @function entypo-url($filename) {
// 	@return url(../../webfonts/entypo/#{$filename});
// }

// @function fontawesome-url($filename) {
// 	@return url(../../webfonts/fontawesome/#{$filename});
// }
