/*
Version: 3.4.0 Timestamp: Tue May 14 08:27:33 PDT 2013
*/
.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    /* inline-block for ie7 */
    zoom: 1;
    *display: inline;
    vertical-align: middle;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input{
  -webkit-box-sizing: border-box; /* webkit */
   -khtml-box-sizing: border-box; /* konqueror */
     -moz-box-sizing: border-box; /* firefox */
      -ms-box-sizing: border-box; /* ie */
          box-sizing: border-box; /* css3 */
}

.select2-container .select2-choice {
    display: block;
    padding: 16px 0 16px 8px;
    overflow: hidden;
    position: relative;
	font-size: 24px;
	line-height: 100%;

    border: 1px solid #FFF;
    white-space: nowrap;
    line-height: 26px;
    color: #2C312A;
    text-decoration: none;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;

    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    background-color: #fff;
}

.mobile .select2-container .select2-choice {
	font-size: 25px;
}

body.desktop .select2-container .select2-choice {
	margin-top: -8px;
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #FFF;
    border-radius:0 0 3px 3px;
}

.select2-container.select2-allowclear .select2-choice span {
    margin-right: 42px;
}

.select2-container .select2-choice span {
    @extend .ellipsis;
    margin-right: 26px;
    display: block;

    @include respond-to(medium-and-up) {
        width: 300px;
    }

    @include respond-to(large-and-up) {
        width: 340px;
    }
}

.select2-container a.select2-choice {
	background: url('../images/arrow-down-b-mobile@2x.png') no-repeat right center #FFF;
    background-size: 30px 14px;
}

.select2-container .select2-choice abbr {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 24px;
    top: 8px;

    font-size: 1px;
    text-decoration: none;

    border: 0;
    cursor: pointer;
    outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    left: 0;
    top: 0;
    z-index: 9998;
	width:100% !important;
	height: 100% !important;
	position: fixed;
}

.select2-drop {
	font-family: 'ff-good-web-pro', Arial, sans-serif;
	font-size: 35px;
    width: 100%;
    margin-top:-1px;
    position: absolute;
    z-index: 9999;
    top: 100%;

    background: #fff;
    color: #2C312A;
    border: 1px solid #FFF;
    border-top: 0;

    -webkit-border-radius: 0 0 3px 3px;
       -moz-border-radius: 0 0 3px 3px;
            border-radius: 0 0 3px 3px;
}

.mobile .select2-drop {
	font-size: 20px !important;
}

.select2-drop-auto-width {
    border-top: 1px solid #FFF;
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid #FFF;
    border-bottom: 0;

    -webkit-border-radius: 3px 3px 0 0;
       -moz-border-radius: 3px 3px 0 0;
            border-radius: 3px 3px 0 0;
}

.select2-container .select2-choice div {
    display: inline-block;
    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    -webkit-border-radius: 0 4px 4px 0;
       -moz-border-radius: 0 4px 4px 0;
            border-radius: 0 4px 4px 0;

    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;

    background: #FFF;
}

.select2-container .select2-choice div b {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../images/slice-mobile.png');
	background-repeat: no-repeat;
	background-position: -185px 10px;
}

.select2-search {
    display: none;
    width: 100%;
    min-height: 26px;
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;

    position: relative;
    z-index: 10000;

    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    height: auto !important;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;

    outline: 0;
    font-family: sans-serif;
    font-size: 1em;

    border: 1px solid #FFF;
    -webkit-border-radius: 0;
       -moz-border-radius: 0;
            border-radius: 0;

    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px;
}

.select2-search input.select2-active {
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid #FFF;
    outline: none;
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-bottomleft: 0;
            border-bottom-left-radius: 0;

    -webkit-border-bottom-right-radius: 0;
        -moz-border-radius-bottomright: 0;
            border-bottom-right-radius: 0;

    background-color: #FFF;
	color: #A6A6A6;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border: 1px solid #FFF;
    border-top-color: transparent;
}

.select2-dropdown-open .select2-choice div {
    background: transparent;
    border-left: none;
    filter: none;
}
.select2-dropdown-open .select2-choice div b {
    background-position: -185px -42px;
}

/* results */
.select2-results {
    max-height: 400px;
    // padding: 0 0 0 4px;
    margin: 4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
    font-weight: bold;
}

.select2-results .select2-result-label {
    padding: 10px 15px;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px solid #f2f2f2;
    min-height: 1em;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

}

.select2-results .select2-highlighted {
    background: #E7E7E7;
    color: #2C312A;
}

.select2-results li em {
    background: #feffde;
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: white;
    color: #000;
}


.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item;
}

.select2-results .select2-disabled.select2-highlighted {
    color: #666;
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}
.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: #f4f4f4 url('select2-spinner.gif') no-repeat 100%;
}

.select2-more-results {
    background: #f4f4f4;
    display: list-item;
}

.select2-container.select2-container-disabled .select2-choice {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #FFF;
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice div {
    background-color: #f4f4f4;
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}

.select2-container-multi .select2-choices {
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0;
    position: relative;
    border: 1px solid #FFF;
    cursor: text;
    overflow: hidden;
    background-color: #fff;
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
    min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #FFF;
    outline: none;
}
.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 5px;
    margin: 1px 0;

    font-family: sans-serif;
    font-size: 100%;
    color: #666;
    outline: 0;
    border: 0;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
    background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
}

.select2-default {
    color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 18px;
    margin: 3px 0 3px 5px;
    position: relative;

    line-height: 13px;
    color: #333;
    cursor: default;
    border: 1px solid #FFF;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;

    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    background-color: #FFF;
}
.select2-container-multi .select2-choices .select2-search-choice span {
    cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4;
}

.select2-search-choice-close {
    display: block;
    width: 12px;
    height: 13px;
    position: absolute;
    right: 3px;
    top: 4px;

    font-size: 1px;
    outline: none;
}

.select2-container-multi .select2-search-choice-close {
    left: 3px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
    background-position: right -11px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices{
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #FFF;
    cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 5px;
    border: 1px solid #FFF;
    background-image: none;
    background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {    display: none;
    background:none;
}
/* end multiselect */


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    border: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    outline: 0;
    left: 0px;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}
/* Retina-ize icons */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)  {
  .select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice div b {
      /*background-image: url('../images/select2x2.png') !important;*/
      background-repeat: no-repeat !important;
      background-size: 60px 40px !important;
  }
  .select2-search input {
      background-position: 100% -21px !important;
  }
}
