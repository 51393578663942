/* ==========================================================================
   Featured item
   ========================================================================== */

.featured__title {
	color: $snow-white;
	padding: 25px 20px;

	@include respond-to(medium-and-up) {
		padding: 0;
		text-align: right;
	}

	h1 {
		margin: 0 0 15px 0;
		font-size: 26px;
		font-family: $good-bold;
		line-height: 1;
		text-shadow: 0px 0px 4px rgba(#000, 0.5);

		@include respond-to(medium-and-up) {
			font-size: 34px;
		}
	}

	.btn--small {
		margin-left: 0;
		margin-bottom: 10px;
		text-shadow: none;
		background-color: $theme-color-01;
		min-width: 220px;

		@include respond-to(medium-and-up) {
			margin-left: 10px;
			margin-bottom: 0;
			min-width: auto;
		}

		&:hover {
			background-color: $theme-color-01-dark;
		}
	}
}

.featured__items {
	h2 {
		margin: 80px 0 20px 0;
		font-size: 18px;
		font-family: $good-bold;
		line-height: 1;
		text-shadow: 0px 0px 4px rgba(#000, 0.5);
		color: $white;

		// @include respond-to(medium-and-up) {
		// 	font-size: 34px;
		// }
	}
}

.featured__item {
	@include box-sizing(border-box);
	display: block;
	position: relative;
	width: 100%;
	cursor: pointer;
	padding: 25px 20px;
	color: $snow-white;
	transition: all 0.3s;

	@include respond-to(medium-and-up) {
		float: left;
		margin-bottom: 0;
		margin-top: 80px;
		height: 150px;

		&:hover {
			transition: all 0.3s;
			margin-top: 60px;
			height: 170px;
		}
	}

	&:nth-child(1) {
		display: none;
		cursor: default;

		&:hover {
			transition: none;
			margin-top: 80px;
			height: 150px;
		}

		h2 {
			margin: 0;
			font-size: 26px;
			font-family: $good-bold;
			line-height: 1;
			text-shadow: 0px 0px 4px rgba(#000, 0.5);
			color: $white;

			// @include respond-to(medium-and-up) {
			// 	font-size: 28px;
			// }
		}

		@include respond-to(medium-and-up) {
			display: block;
			background-color: $theme-color-01;
		}
	}

	&:nth-child(2) {

		@include respond-to(medium-and-up) {
			background-color: lighten($theme-color-02, 5);
			&:hover {
				background-color: darken(lighten($theme-color-02, 5),10);
			}
		}
	}

	&:nth-child(3) {
		display: none;

		@include respond-to(medium-and-up) {
			display: block;
			background-color: lighten($theme-color-02, 10);
			&:hover {
				background-color: darken(lighten($theme-color-02, 10),10);
			}
		}
	}

	&:nth-child(4) {
		display: none;

		@include respond-to(large-and-up) {
			display: block;
			background-color: lighten($theme-color-02, 15);

			&:hover {
				background-color: darken(lighten($theme-color-02, 15),10);
			}
		}
	}

	// &.first {
	// 	margin-top: 0px;
	// 	background-color: $theme-color-02;
	//
	// 	@include respond-to(medium-and-up) {
	// 		// height: 230px;
	//
	// 		&:hover {
	// 			background-color: darken($theme-color-02, 10);
	// 			// margin-top: -20px;
	//
	// 			@include respond-to(medium-and-up) {
	// 				// margin-top: -20px;
	// 				// height: 250px;
	// 			}
	//
	// 		}
	// 	}

		// &:after {
		// 	position: absolute;
		// 	right: 15px;
		// 	bottom: 15px;
		// 	display: block;
		// 	width: 12px;
		// 	height: 12px;
		// 	border-right: 4px solid white;
		// 	border-bottom: 4px solid white;
		// 	content: "";
		// }
	// }

	// h2 {
	// 	margin: 0;
	// 	font-size: 26px;
	// 	font-family: $good-bold;
	// 	line-height: 1;
	// 	overflow: hidden;
	//
	// 	@include respond-to(medium-and-up) {
	// 		height: 130px;
	// 	}
	// }

	h2 {
		margin: 0;
		font-size: 18px;
		font-family: $good-bold;
		line-height: 1;

		@include respond-to(medium-and-up) {
			height: 55px;
			overflow: hidden;
		}
	}

	span {
		display: block;
		color: rgba(#fff,0.75);
		font-size: $base-font-size;
		font-family: $good;

		@include respond-to(medium-and-up) {
			position: absolute;
			bottom: 20px;
		}
	}

	.featured__tag {
		margin-top: 10px;
	}

	@include respond-to(medium-and-up) {
		width: 33.333%;

		&:last-child {
			margin-right: 0;
		}

		&.last {
			margin-right: 0;
		}
	}

	@include respond-to(large-and-up) {
		width: 25%;
	}
}
