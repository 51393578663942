/* iCheck plugin Line skin, blue
----------------------------------- */
.icheckbox_line-blue,
.iradio_line-blue {
    position: relative;
    display: block;
    margin: 5px 10px 5px 0;
    padding: 12px;
    font-size: 16px;
    line-height: 16px;
    color: #0D5592;
    background: #3384CC;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
	font-family: 'ff-good-web-pro';
	float: left;
}
    .icheckbox_line-blue .icheck_line-icon,
    .iradio_line-blue .icheck_line-icon {
        position: absolute;
        top: 50%;
        left: 13px;
        width: 13px;
        height: 11px;
        margin: -5px 0 0 0;
        padding: 0;
        overflow: hidden;
        background: url(line.png) no-repeat;
        border: none;
    }
    .icheckbox_line-blue.hover,
    .icheckbox_line-blue.checked.hover,
    .iradio_line-blue.hover {
        background: #FFF;
		color: #0056BF;
    }
    .icheckbox_line-blue.checked,
    .iradio_line-blue.checked {
        background: #FFF;
		color: #0056BF;
    }
        .icheckbox_line-blue.checked .icheck_line-icon,
        .iradio_line-blue.checked .icheck_line-icon {
            background-position: -15px 0;
        }
    .icheckbox_line-blue.disabled,
    .iradio_line-blue.disabled {
        background: #ADD7F0;
        cursor: default;
    }
        .icheckbox_line-blue.disabled .icheck_line-icon,
        .iradio_line-blue.disabled .icheck_line-icon {
            background-position: -30px 0;
        }
    .icheckbox_line-blue.checked.disabled,
    .iradio_line-blue.checked.disabled {
        background: #ADD7F0;
    }
        .icheckbox_line-blue.checked.disabled .icheck_line-icon,
        .iradio_line-blue.checked.disabled .icheck_line-icon {
            background-position: -45px 0;
        }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 3/2),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_line-blue .icheck_line-icon,
    .iradio_line-blue .icheck_line-icon {
        background-image: url(line@2x.png);
        -webkit-background-size: 60px 13px;
        background-size: 60px 13px;
    }
}


/**
 * CAT
 */
/* iCheck plugin Line skin, cat
----------------------------------- */
.icheckbox_line-cat,
.iradio_line-cat {
    position: relative;
    display: block;
    margin: 5px 10px 5px 0;
    padding: 12px 12px 12px 40px;
    font-size: 16px;
    line-height: 16px;
    color: #0D5592;
    border: none;
-webkit-border-top-right-radius: 3px;
-webkit-border-bottom-right-radius: 3px;
-moz-border-radius-topright: 3px;
-moz-border-radius-bottomright: 3px;
border-top-right-radius: 3px;
border-bottom-right-radius: 3px;
    cursor: pointer;
	font-family: 'ff-good-web-pro';
	float: left;
	background: url(check-inactive.gif) no-repeat left top #3384CC;
}
    .icheckbox_line-cat .icheck_line-icon,
    .iradio_line-cat .icheck_line-icon {
        position: absolute;
        top: 50%;
        left: 13px;
        width: 13px;
        height: 11px;
        margin: -5px 0 0 0;
        padding: 0;
        overflow: hidden;
        background: url(line.png) no-repeat;
        border: none;
    }
    .icheckbox_line-cat.hover,
    .icheckbox_line-cat.checked.hover,
    .iradio_line-cat.hover {
		color: #0056BF;
		background: url(check-active.gif) no-repeat left top #FFF;
    }
    .icheckbox_line-cat.checked,
    .iradio_line-cat.checked {
		color: #0056BF;
		background: url(check-active.gif) no-repeat left top #FFF;
    }
        .icheckbox_line-cat.checked .icheck_line-icon,
        .iradio_line-cat.checked .icheck_line-icon {
            background-position: -15px 0;
        }
    .icheckbox_line-cat.disabled,
    .iradio_line-cat.disabled {
        background: #ADD7F0;
        cursor: default;
    }
        .icheckbox_line-cat.disabled .icheck_line-icon,
        .iradio_line-cat.disabled .icheck_line-icon {
            background-position: -30px 0;
        }
    .icheckbox_line-cat.checked.disabled,
    .iradio_line-cat.checked.disabled {
        background: #ADD7F0;
    }
        .icheckbox_line-cat.checked.disabled .icheck_line-icon,
        .iradio_line-cat.checked.disabled .icheck_line-icon {
            background-position: -45px 0;
        }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 3/2),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_line-cat .icheck_line-icon,
    .iradio_line-cat .icheck_line-icon {
        background-image: url(line@2x.png);
        -webkit-background-size: 60px 13px;
        background-size: 60px 13px;
    }
}
