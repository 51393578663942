/**
 * Gridset
 *
 * This are grid related 'extend only' selectors
 * For more information about these selectors see:
 * - http://j.mp/UvgHgT and
 * - http://amperedesign.com/?p=171
 */

/* Clearfix (http://nicolasgallagher.com/micro-clearfix-hack/) */

%clearfix {
	zoom: 1;
	&:before, &:after {
		display: table;
		content: "";
	}
	&:after {
		clear: both;
	}
}

/**
* Layout
*
* Grid structure and layout per breakpoint (project specific)
*/

// Mobile grid breakpoints
@include respond-to(compact-and-below) {
	%mobile-one-whole {
		width: 100%;
	}
	%mobile-one-half {
		width: 50%;
	}
	%mobile-one-quarter {
		width: 25%;
	}
}

// Tablet grid breakpoints
@include respond-to(compact-and-up) {
	%tablet-one-whole {
		width: 100%;
	}
	%tablet-one-half {
		width: 50%;
	}
	%tablet-one-third {
		width: 33.33%;
	}
	%tablet-one-quarter {
		width: 25%;
	}
	%tablet-three-quarters {
		width: 75%;
	}
}

// Desktop grid breakpoints
@include respond-to(medium-and-up) {
	%desktop-one-fifth {
		width: 20%;
	}

	%desktop-one-third {
		width: 33.33%;
	}

	%desktop-one-quarter {
		width: 25%;
	}

	%desktop-one-half {
		width: 50%;
	}

	%desktop-two-thirds {
		width: 66.66%;
	}

	%desktop-one-whole {
		width: 100%;
	}

	%desktop-four-fifths {
		width: 80%;
	}
}

// Desktop-mid grid breakpoints
@include respond-to(normal-and-up) {
	%normal-one-half {
		width: 50%;
	}
}

// desktop grid breakpoints
@include respond-to(large-and-up) {
	%wide-one-whole {
		width: 100%;
	}
	%wide-one-third {
		width: 33.33%;
	}
	%wide-three-quarters {
		width: 75%;
	}
}

/**
 *Sizes in human readable format
 */

/* Whole */

%one-whole { width: 100%; }

/* Halves */

%one-half { width: 50%; }

/* Thirds */

%one-third { width: 33.333%; }
%two-thirds { width: 66.666%; }

/* Quarters */

%one-quarter { width: 25%; }
%two-quarters { width: 50%; }
%three-quarters { width: 75%; }

/* Fifths */

%one-fifth { width: 20%; }
%two-fifths { width: 40%; }
%three-fifths { width: 60%; }
%four-fifths { width: 80%; }

/* Sixths */

%one-sixth { width: 16.666%; }
%two-sixths { width: 33.333%; }
%three-sixths { width: 50%; }
%four-sixths { width: 66.666%; }
%five-sixths { width: 83.333%; }

/* Eighths */

%one-eighth { width: 12.5%; }
%two-eighths { width: 25%; }
%three-eighths { width: 37.5%; }
%four-eighths { width: 50%; }
%five-eighths { width: 62.5%; }
%six-eighths { width: 75%; }
%seven-eighths { width: 87.5%; }

/* Tenths */

%one-tenth { width: 10%; }
%two-tenths { width: 20%; }
%three-tenths { width: 30%; }
%four-tenths { width: 40%; }
%five-tenths { width: 50%; }
%six-tenths { width: 60%; }
%seven-tenths { width: 70%; }
%eight-tenths { width: 80%; }
%nine-tenths { width: 90%; }

/* Twelfths */

%one-twelfth       { width: 8.333%; }
%two-twelfths      { width: 16.666%; }
%three-twelfths    { width: 25%; }
%four-twelfths     { width: 33.333%; }
%five-twelfths     { width: 41.666% }
%six-twelfths      { width: 50%; }
%seven-twelfths    { width: 58.333%; }
%eight-twelfths    { width: 66.666%; }
%nine-twelfths     { width: 75%; }
%ten-twelfths      { width: 83.333%; }
%eleven-twelfths   { width: 91.666%; }

/**
 * Sizes based on modular scales
 */

/* Golden 2 column set */

%golden-col-1-2 { width: grid-span(1, 1, ratio($golden, 2, 'large')); }
%golden-col-2-2 { width: grid-span(1, 2, ratio($golden, 2, 'large')); }

%golden-row-1-2 { height: grid-span(1, 1, ratio($golden, 2, 'large')); }
%golden-row-2-2 { height: grid-span(1, 2, ratio($golden, 2, 'large')); }

/* Golden 3 column set */

%golden-col-1-3 { width: grid-span(1, 1, ratio($golden, 3, 'large')); }
%golden-col-2-3 { width: grid-span(1, 2, ratio($golden, 3, 'large')); }
%golden-col-3-3 { width: grid-span(1, 3, ratio($golden, 3, 'large')); }

%golden-row-1-3 { height: grid-span(1, 1, ratio($golden, 3, 'large')); }
%golden-row-2-3 { height: grid-span(1, 2, ratio($golden, 3, 'large')); }
%golden-row-3-3 { height: grid-span(1, 3, ratio($golden, 3, 'large')); }

/* Golden 4 column set */

%golden-col-1-4 { width: grid-span(1, 1, ratio($golden, 4, 'large')); }
%golden-col-2-4 { width: grid-span(1, 4, ratio($golden, 4, 'large')); }
%golden-col-3-4 { width: grid-span(1, 3, ratio($golden, 4, 'large')); }
%golden-col-4-4 { width: grid-span(1, 2, ratio($golden, 4, 'large')); }

/* Compound 4 column set */

%compound-col-1-4 { width: grid-span(1, 1, compound(2,3)); }
%compound-col-2-4 { width: grid-span(1, 2, compound(2,3)); }
%compound-col-3-4 { width: grid-span(1, 3, compound(2,3)); }
%compound-col-4-4 { width: grid-span(1, 4, compound(2,3)); }

/* Asymmetric 4 column set */

%asym-col-1-4 { width: grid-span(1, 1, 1 2 1 2); }
%asym-col-2-4 { width: grid-span(1, 2, 1 2 1 2); }
%asym-col-3-4 { width: grid-span(1, 3, 1 2 1 2); }
%asym-col-4-4 { width: grid-span(1, 4, 1 2 1 2); }
