// --------------------------------------------------------------------------
// Sidebar
// --------------------------------------------------------------------------

.event__picture {
	position: relative;

	img {
		position: relative;
	}
}

#list.block li .list__content span.ticket__lable,
.ticket__lable {
	@include box-sizing(border-box);
	background-color: rgba(48,48,48,.6);
	color: $snow-white;
	position: absolute;
	padding: $small;
	bottom: 0px;
	right: 50px;
	height: 50px;
    margin: 0;

	strong {
		font-family: $good-bold;
	}

	&:after {
		content: "";
		position: absolute;
		width: 50px;
		height: 50px;
		bottom: 0;
		right: -50px;
		display: inline-block;
		background-color: $theme-color-02;
		background-position: -100px -367px;
		background-image: url('../images/slice.png');


	}

//	&:hover {
//		background-color: rgb(48,48,48);
//
//		&:after {
//			background-color: $snow-white;
//			background-position: -180px -367px;
//		}
//	}
}

.content-sidebar {
	float: left;
	margin-top: 40px;

	.table {
		width: 100%;
		float: left;


		tr {
			margin-bottom: 15px;
			float: left;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.lable {
			width: 40%;
			font-family: $good-bold;
			float: left;

			@include respond-to(compact-and-up) {
				width: 25%;
			}

			@include respond-to(medium-and-up) {
				width: 40%;
			}
		}

		.lable__info {
			width: 58%;
			float: left;

			@include respond-to(compact-and-up) {
				width: 73%;
			}

			@include respond-to(medium-and-up) {
				width: 58%;
			}
		}
	}

	@include respond-to(medium-and-up) {
		margin-top: 0;
		width: 33.33%;
		float: right;
	}

	.btn--ticket {

		@include respond-to(compact-and-up) {
			width: 50%;
		}

		@include respond-to(medium-and-up) {
			width: 100%;
		}
	}
}

// Sharing within the sidebar

.share {
	margin: 14px 0 0 0;
	font-size: 14px;
	margin-bottom: 40px;

	@include respond-to(medium-and-up) {
		margin-top: -15px;
		float: right;
	}

	p {
		color: #8a8c89;
	}
}

.share__socials {
	display: inline-block;
	float: right;
	margin-top: 0;
	margin-left: 0;
}

.share__twitter,
.share__facebook {
	@include transition(all,0.2s, ease-in-out);
	text-indent: -9999px;
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 3px;
	float: left;
}

.share__twitter {
	background-color: #00aeff;
	margin: 0 10px;
	background-position: -70px -181px;

	&:hover {
		background-color: #0098fd;
	}
}

.share__facebook {
	background-color: #3b5998;
	background-position: -131px -181px;

	&:hover {
		background-color: #3b408b;
	}
}

// Related event within the sidebar

.content-sidebar__event {
	@include box-sizing(border-box);
	border: 1px solid $whd-gray-light;
	padding: 40px;
	line-height: 1.5;
	float: left;
	width: 100%;

	@include respond-to(compact-and-up) {
		width: 48%;
		margin-right: 2%;
	}

	@include respond-to(medium-and-up) {
		width: 100%;
		margin-left: 0;
	}

	img {
		width: 100%;
	}

	span {
		color: #8a8c89;
		margin-top: 20px;
		margin-bottom: 20px;
		display: block;
	}

	.title {
		font-family: $good-bold;
		font-size: 20px;
		margin-bottom: 50px;
		line-height: 1.2;
	}

	.tags {
		margin-top: 35px;
		display: block;
	}

	.event__picture .ticket__lable {
		bottom: 0;
	}
}

// Banner event within the sidebar

.content-sidebar__banner {
	width: 100%;
	float: left;
	margin-top: 40px;

	@include respond-to(compact-and-up) {
		width: 48%;
		margin-top: 0;
		margin-left: 2%;
	}

	@include respond-to(medium-and-up) {
		width: 100%;
		margin-top: 40px;
		margin-left: 0;
	}

	img {
		width: 100%;
		vertical-align: middle;
	}

	table {
		float: left;
		margin-top: 30px;

		a {
			text-decoration: none;
		}

		tr {
			margin-bottom: 5px;
			display: block;
			line-height: 24px;
		}

		@include respond-to(medium-and-up) {
			margin-top: 50px;
		}
	}

	@include respond-to(medium-and-up) {
		margin-bottom: 0;
	}
}

.single-whd_arrangement,
.single-whd_programma {

	.content-sidebar__banner {
		@include respond-to(compact-and-up) {
			margin-top: 40px;
			margin-left: 0;
		}
	}
}
