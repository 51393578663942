/* ==========================================================================
   Schedule (programma detail)
   ========================================================================== */

.schedule {
	float: left;
	list-style-type: none;
	padding: 0;
	margin: 10px 0 0 0;
	width: 100%;
	font-family: $good-bold;

	li {
		margin-bottom: 30px;
		position: relative;

		@include respond-to(medium-and-up) {
			margin-bottom: 20px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: none !important;
		}

		.schedule__date {
			display: inline-block;
			float: left;
			width: 50%;

			@include respond-to(medium-and-up) {
				padding-top: 20px;
				width: 25%;
			}
		}

		.schedule__time {
			display: inline-block;
			float: left;
			width: 50%;

			@include respond-to(compact-and-up) {
				display: block;
				width: 100%;
				margin-top: 10px;
			}

			@include respond-to(medium-and-up) {
				padding-top: 10px;
				width: 25%;
				text-align: left;
				padding-left: $small;
			}
		}

		.btn {
			float: right;
			margin-top: 20px;
			margin-bottom: 0;

			@include respond-to(compact-and-up) {
				width: 50%;
				margin-top: -46px;
			}

			@include respond-to(medium-and-up) {
				margin-top: -5px;
				width: 33.33%;
			}
		}
	}
}
