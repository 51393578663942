/* ==========================================================================
   Newsletter
   ========================================================================== */

.newsletter {
	position: relative;
	padding: 50px 15px 40px 15px;
	background-color: $whd-gray-dark;
	color: white;

	&#hoofdsponsoren {
		text-align: center;
		padding: 0 15px 40px 15px;

		img {
			margin-left: 30px;
			margin-right: 30px;
			max-height: 60px;
		}
	}

	h2 {
		display: block;
		margin: 0;
		font-family: $good-bold;
	}

	p {
		display: block;
		margin: 6px 0 20px 0;

		@include respond-to(large-and-up) {
			margin: 6px 0 0 0;
		}
	}
}

.newsletter__form {
	position: relative;

	@include respond-to(large-and-up) {
		position: absolute;
		top: -5px;
		right: 0;
		width: 62%;
	}
}

.newsletter__email {

	float: left;
	width: 100%;

	input {
		@include box-sizing(border-box);
		display: inline-block;
		margin: 0 20px 10px 0;
		padding: 20px 20px 20px 60px;
		width: 98%;
		border: none;
		border-radius: 3px;
		background-image: url('../images/mail.png');
		background-position: 20px 22px;
		background-repeat: no-repeat;
		outline: none;
	}

	@include respond-to(medium-and-up) {
		max-width: 530px;
	}

	@include respond-to(large-and-up) {
		margin: 0;
		width: 420px;
	}

}

label.error {
	display: block;
	color: $theme-color-02;
	float: left;
	margin-bottom: $med;

	@include respond-to(large-and-up) {
		margin: 0 20px 0 0;
		width: 350px;
		position: absolute;
		top: -25px;
	}
}

.newsletter__submit {
	position: relative;
	display: inline-block;
	padding: 17px 40px 19px 20px;
	border:none; border-radius: 3px;
	background-color: $theme-color-02-darker;
	color: white;
	text-decoration: none;
	font-size: 20px;
	font-family: $good-bold;
	float: left;
	outline: none;

	&:after {
		position: absolute;
		top: 20px;
		right: 20px;
		display: inline-block;
		width: 11px;
		height: 20px;
		background-image: url('../images/slice.png');
		background-position: -356px -158px;
		content: "";

		@include respond-to(large-and-up) {
			top: 22px;
		}
	}

	&.loading:after {
		background-image: url('../images/ajax-loader.gif');
		background-repeat: no-repeat;
		background-position: center center;
	}

	&:hover {
		background-color: $theme-color-02-dark;
	}

	@include respond-to(medium-and-up) {
		float: right;
	}
}

.newsletter__result {
	display: none;
	position: relative;

	@include respond-to(large-and-up) {
		position: absolute;
		top: 21px;
		right: 0;
		width: 62%;
		margin-right: 0;
	}

	.result {
		color: white; // fallback
		color: rgba( white, 0.6);
		font-size: 15px;
		line-height: 20px;

		@include respond-to(medium-and-up) {
			margin-top: 52px;
		}

	}

	.again {

	}

	@include respond-to(medium-and-up) {
		max-width: 530px;

		.again {
			float: right;
		}
	}

	@include respond-to(large-and-up) {
		margin: -50px 20px 0 0;
		width: 60%;
		float: right;
	}
}
