/* ==========================================================================
   Panoramabar
   ========================================================================== */

	.actions_wrapper {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.help,
	.news,
	.social {
		@include box-sizing(border-box);
		position: relative;
		float: left;
		padding: 15px;

		&:after {
			position: absolute;
			right: 15px;
			bottom: 15px;
			display: block;
			width: 12px;
			height: 12px;
			border-right: 4px solid white;
			border-bottom: 4px solid white;
			content: "";
		}

		@include respond-to(compact-and-up) {
			height: 116px;
		}
	}

	.help {
		width: 100%;
		float: left;

		p {
			margin: 0;
			padding: 0 15px 15px 0;
			color: white;
			font-size: 22px;
			font-family: $good-bold;
			line-height: 1.2;
		}

		@include respond-to(compact-and-up) {
			padding: 15px 200px 15px 15px;
		}

		@include respond-to(medium-and-up) {
			padding: 15px;
			width: 50%;
		}

		@include respond-to(large-and-up) {
			padding: 15px 150px 15px 15px;
		}
	}

	.news,
	.social {
		width: 50%;

		p {
			display: none;
			font-size: 16px;
			line-height: 1.285714286;

			@include respond-to(compact-and-up) {
				display: block;
			}
		}

		h2 {
			margin: 0;
			padding: 0 15px 15px 0;
			font-size: 16px;
			font-family: $good-bold;

			@include respond-to(compact-and-up) {
				padding: 0 15px 0 0;
				margin: 0 0 10px 0;
				font-size: 22px;
			}
		}

		@include respond-to(medium-and-up) {
			width: 25%;
		}
	}

	.news {
		color: #0065BF;

		&:after {
			border-right: 4px solid #0065BF;
			border-bottom: 4px solid #0065BF;
		}
	}

	.status--teasing,
	.status--afterwards {

		.actions_wrapper {
			width: 100%;
		}

		.news,
		.social {
			width: 50%;
		}

		.news {
			color: $snow-white;
			background-color: $theme-color-02;

			&:after {
				border-right: 4px solid $snow-white;
				border-bottom: 4px solid $snow-white;
			}
		}
	}

	.social p { color: #91b5d5; }
	.social h2 { color: $snow-white; }
