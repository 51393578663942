.backgroundsize .cbp-bislideshow li {
    background-size: cover;
    background-position: center center;
}

.cbp-bislideshow li {
    position: absolute;
    width: 101%;
    height: 101%;
    top: -0.5%;
    left: -0.5%;
    opacity: 0;
    transition: opacity 1s ease 0s;
}

.backgroundsize .cbp-bislideshow li img {
    display: none;
}

.cbp-bislideshow li img {
    display: block;
    width: 100%;
}