/* ==========================================================================
   Programma single
   ========================================================================== */

.single-whd_arrangement #photo,
.single-whd_programma #photo {
	@include respond-to(medium-and-up) {
		height: 610px !important;
	}
}

.single-whd_arrangement .program__header,
.single-whd_programma .program__header {

	#kaartFilterButtonContainer {
		position: relative;
	}

	#map {
		height: 580px;
		position: absolute !important;
		top: -100%;
	}
}

.programma__wrapper {
	@extend .shadow !optional;
	@include respond-to(medium-and-up) {
		margin-top: 450px;
	}

	h1 {
		margin: 0 0 30px 0 !important;
	}

	.content-half p,
	.content-half ol,
	.content-half ul {
		font-size: 16px;
	}

	.content-half em {
        font-weight: 700;
        color: $theme-color-02;
	}

	.content-half strong {
        font-weight: 700;
	}

	.content-half a {
        color: $theme-color-02;
	}

}

.visitors-info p {
	font-family: $good-bold;
	margin: 0 0 30px 0;
	font-size: 20px;
	line-height: 24px;
}

.visitors-info .btn {
	margin-bottom: 10px;
}

/* ==========================================================================
   Checkout popup
   ========================================================================== */

#checkout {
	// position: fixed;
	// bottom: -1000px;
	// left: 35%;
	// z-index: 100000;
	// margin: 0 0 0 -320px;
	// max-width: 950px !important;
	// width: 950px !important;
	// width: 320px;
	height: 85%;

	// border: 0;
	// background-color: #0065BF;
	// box-shadow: 0px 3px 10px 1px #666;
}

#checkout iframe {
	width: 100%;
	height: 100%;
	background-color: $snow-white;
	background-image: url('../images/ajax-loader-ticket.gif');
	background-position: center center !important;
	background-repeat: no-repeat;

	@include respond-to(medium-and-up) {
		height: 90%;
		min-height: 650px;
	}
}

.checkout-close {
	position: absolute;
	top: 20px;
	right: 6%;
	z-index: 1001;
	width: 110px;
	cursor: pointer;
	padding: 10px;

	&:after {
		top: 10px;
	}
}

.checkout-header {
	position: absolute;
	top: 0;
	left: 300px;
	z-index: 1000;
}

/* ==========================================================================
   Programma archive
   ========================================================================== */

.post-type-archive-whd_arrangement h1,
.post-type-archive-whd_programma h1 {

	@include respond-to(medium-and-up) {
		padding: 50px 0 15px 0;
	}

}

/**
 * Basic MixItUp Styles
 */

#list--arrangement.list li.mix,
#list--arrangement.block li.mix,
#list.list li.mix,
#list.block li.mix {
    display: none;
}

#list--arrangement.block.loading,
#list.block.loading {

	li {
		transition: all 0.3s;
		opacity: 0.2;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 160px;
		z-index: 100;
		background-image: url(../images/loader.gif);
		background-repeat: no-repeat;
		background-position: center;
		padding-left: 1.309em;
	}
}

/**
 * Info overlay window
 */

#infoOverlay {
	position: absolute;
	background-color: #0065BF;
	width: 100%;
	z-index: 200;
	display: none;
	color: $snow-white;
	font-size: 15px;

	.container { background-color: transparent; }

	.close {
		position: absolute;
		right: 0;
		top: 0;
		width: 60px;
		height: 60px;
		z-index: 10;

		a {
			width: 60px;
			height: 60px;
			display: block;
			background-color: #0D5592;
			background-position: -180px -118px;
		}
	}

	h3 {
		font-family: $good-bold;
		font-size: 20px;
		margin: $small 0 0 0;
		display: block;
	}

	ul {
		margin: $small 0 0 0;
		list-style-type: disc;
		padding-left: 20px;

		li {
			list-style-image: disc;
			list-style: outside;
			display: list-item;
			padding-left: 5px;
		}
	}

	.inner {
		padding: 15px;
	}
}

#infoOverlay
#infoOverlay ul {  }

/**
* Programma buttons
*/

.program__buttons {

	form.program__search {
		display: inline-block;
		position: relative;
		width: 100%;

		@include respond-to(medium-and-up) {
			width: 30%;
			float: right;
		}

		input[type=search] {
			@include box-sizing(border-box);
			padding: 15px 46px 15px 15px;
			background-color: $whd-gray-light;
			color: #8a8c89;
			font-size: $base-font-size;
			font-family: $good-bold;
			width: 100%;
			display: block;
			position: relative;
			text-decoration: none;
			margin-bottom: $med;
			border: none;
		}

		input[type=submit] {
			@include box-sizing(border-box);
			width: 50px;
			height: 50px;
			top: 0;
			right: 0;
			background-color: darken($whd-gray-light,20);
			font-size: $base-font-size;
			font-family: $good-bold;
			display: block;
			position: absolute;
			text-decoration: none;
			border: none;
			border-radius: 0;
			margin-right: 0;
			background-image: url(../images/slice.png);
			background-position: -14px -135px;
		}
	}

	.btn {
		@include box-sizing(border-box);
		padding: 15px 46px 15px 15px;
		border-radius: 3px;
		background-color: $whd-gray-light;
		color: #8a8c89;
		font-size: $base-font-size;
		font-family: $good-bold;
		width: 100%;
		display: block;
		position: relative;
		text-decoration: none;
		margin-bottom: $med;

		&:before {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			position: absolute;
			right: 15px;
			top: 50%;
			margin-top: -10px;
		}

		&:hover {
			background-color: #0065bf;
			color: $snow-white;

			&:before {

			}
		}

		@include respond-to(medium-and-up) {
			width: 30%;
			float: left;
		}
	}

	@include respond-to(medium-and-up) {

		.btn {
			margin-bottom: $large;
		}

		.btn + .btn {
			margin-left: $med;
		}
	}
}

.btn--info {

	&:before {
		background-repeat: no-repeat;
		background-position: 5px 6px;
	}

	&:hover {

		&:before {
			background-position: 5px -90px;
		}
	}
}

.btn--filter {

	&:before {
		background-repeat: no-repeat;
		background-position: 2px 3px;
	}

	&:hover {
		&:before {
			background-position: 2px -38px;
		}
	}
}

.sidebar-open .btn--filter {
		&:before {
		background-repeat: no-repeat;
		background-position: 2px 9px;
		background-image: url('../images/min-negativ.png');
	}

	&:hover {
		&:before {
			background-position: 2px -20px;
		}
	}
}

.btn--reset {
	padding: 15px 46px 15px 15px;
	border-radius: 3px;
	background-color: $whd-gray-light;
	color: $snow-white;
	font-size: $base-font-size;
	font-family: $good-bold;
	border: none;
	outline: none;
	background: $theme-color-01;
	position: relative;

	&:after {
		position: absolute;
		top: 17px;
		right: 15px;
		display: inline-block;
		width: 11px;
		height: 20px;
		background-image: url("../images/slice.png");
		background-position: -356px -158px;
		content: "";
	}

	&:hover {
		background: $theme-color-01-dark;
	}
}

/**
* Programma archive page
*/

#content {
	position: relative;
	background-color: $snow-white;


	.container .wrapper {
		padding: 15px;
	}
}

.container--border {
	border-top: 1px solid $whd-gray-light;
	padding-top: 60px;
}

.program h1 {
	font-family: $good-bold;
	font-size: 30px;
	margin: 18px 0 20px 0 !important;
	display: block;
	width: 100%;
}


/**
* Programma list
*/

#list--arrangement,
#list {
	position: relative;
	overflow: hidden;
}

#list--arrangement,
#list {
	min-height: 1200px;
}

#list--arrangement li.fail-message,
#list li.fail-message {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100% !important;
	margin-top: $med;
	display: none;

	h3 {
		color: $theme-color-01;
		font-size: 1.3em;
		font-family: $good-bold;
	}

	.span {
		display: inline-block;
		vertical-align: middle;
		font-size: 20px;
		font-weight: 700;
	}
}

#list--arrangement.fail li.fail-message,
#list.fail li.fail-message {
	display: block;
}

// mobile list view
#list--arrangement.list,
#list.list {
	margin: 0;

	li {
		@include box-sizing(border-box);
		background-color: #E7E7E7;
		margin: 10px 0;
		padding: $small;
		width: 100%;
		position: relative;

		&:hover {

			.title,
			p {
				color: $theme-color-02;
			}

			.button {
				background-color: #005199;
			}
		}
	}

	.list__content {
		width: 80%;
	}

	a {
		color: $whd-gray;
		text-decoration: none;
		display: block;
	}

	.button {
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 100%;
		display: block;
		background-color: #0065BF;
		background-image: none;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -5px;
			margin-top: -7px;
			width: 12px;
			height: 16px;
			background-position: -145px -22px;
		}
	}

	.title {
		font-family: $good-bold;
		color: $whd-gray;
		margin-bottom: $small;
	}

	.image,
	span,
	.tags {
		display: none;
	}

	p {
		@extend .ellipsis;
	}

	.list__line {
		display: none;
	}
}

// desktop block view
#list--arrangement.block,
#list.block {
	padding: 0;
	list-style: none;
	margin-left: -$med;

	// display: -webkit-flex;
 //    display: -ms-flexbox;
 //    display: flex;


	display: -ms-flexbox;
	display: -webkit-flex;

	@supports (flex-wrap: wrap) { /* hide from the incomplete Flexbox implementation in Firefox */
		display: flex;
	}

    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

	li {
		@include box-sizing(border-box);
		line-height: 1.5;
		float: left;
		width: 25%;
		padding-left: $med;
		margin-bottom: $med;
        position: relative;
        overflow: hidden;

		.image {
			position: relative;
		}

		.list__content span {
			color: #8a8c89;
			margin: 20px 0;
			display: block;
			font-size: .9375em; //15/16
			min-height: 22px;
		}

		.title a {
			font-family: $good-bold;
			font-size: 20px;
			margin-bottom: 25px;
			line-height: 1.2;
			color: $whd-gray;
			min-height: 75px;
			max-height: 75px;
			overflow: hidden;
			display: block;
		}

		p {
			color: $whd-gray;
			font-size: 1em;
			min-height: 74px;
		}

		.tags {
			margin-top: 35px;
			display: block;
			font-size: .9375em; //15/16
			min-height: 22px;
		}

		.list__content {
			padding-bottom: $med;
			// border: 1px solid red;
		}

		.list__line {
			@include box-sizing(border-box);
			background: $whd-gray-light;
			width: 100%;
			bottom: 0;
			right: -$med;
			border: none;
			height: 1px;
			position: absolute;
		}
	}
}

.sidebar-open #list--arrangement.block,
.sidebar-open #list.block {
	@include respond-to(medium-and-up) {
		width: 75%;
		float: right;
		min-height: 170px;

		li {
			width: 33.33%;
		}
	}
}

/**
 * Sentence filter
 */

 .step__wrapper {
 	width: 100%;
	float: left;

	@include respond-to(medium-and-up) {
		width: 49%;
		margin-right: 2%;

		&:last-child {
			margin-right: 0;
		}
 	}

 	@include respond-to(large-and-up) {
 		margin-right: 0;
 	}
 }

.filter__sentence {
	display: none;
	border-top: 1px solid $whd-gray-light;
	border-bottom: 1px solid $whd-gray-light;
	padding: 45px 0 25px 0;
	font-size: $base-font-size;
	position: relative;
	margin-bottom: $large;

		&.active {
		display: block;
	}

	@include respond-to(compact-and-up) {
		padding: 45px 0 15px 0;
		margin-bottom: $large;
	}

	@include respond-to(medium-and-up) {
		padding: 40px 0 20px 0;
		font-size: 22px;
	}

	@include respond-to(wide-and-up) {
		padding: 40px 0 20px 0;
		font-size: 22px;
	}

	.text {
		display: block;
		float: left;
		margin-bottom: 20px;
		width: 100%;

		@include respond-to(medium-and-up) {
			margin-bottom: 30px;
		}

		@include respond-to(large-and-up) {
			display: inline-block;
			margin-bottom: 0;
			width: auto;
		}
	}

	.text--first,
	.text--second {

		@include respond-to(large-and-up) {
			margin-right: 20px;
		}
	}
}

.filter__sentence__step {
	float: left;
	margin: -10px 0 20px 0;
	font-size: $base-font-size;
	position: relative;
	z-index: 10;
	width: 100%;

	@include respond-to(medium-and-up) {
		width: 100%;
		float: left;
	}

	@include respond-to(large-and-up) {
		margin: 0 0 16px 0;
		font-size: 22px;
		width: auto;
	}

	.select2-container {
		// margin-left: $med;
		// margin-right: $med;

		a.select2-choice {
			background-color: red;
			border: 1px solid #E8E8E8;
			background-color: #F8F8F8;
			font-size: 22px;
			padding: 8px 0 8px 8px;

			span {

			    @include respond-to(medium-and-up) {
			        width: 200px;
			    }

			    @include respond-to(large-and-up) {
			        width: 240px;
			    }
			}
		}
	}

	#s2id_planning_2 a.select2-choice {
		span {
			@include respond-to(large-and-up) {
				width: 160px;
			}

			@include respond-to(wide-and-up) {
				width: 200px;
			}
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		padding: 10px;
		font-family: $good-bold;
		border: 1px solid $whd-gray-light;
		border-radius: 3px;
		margin-right: 13px;
		padding: 10px;
		cursor: pointer;

		@include respond-to(medium-and-up) {
			margin-right: 8px;
		}

		&:focus {
			outline: none;
		}
	}
}

.filter__sentence__close {
	width: 12px;
	height: 12px;
	display: block;
	margin-bottom: 20px;
	position: absolute;
	right: 0;
	top: 20px;
	background-position: -196px -248px;
}

.post-type-archive-whd_arrangement .select2-results,
.post-type-archive-whd_programma .select2-results {
	border: 1px solid #f2f2f2;
	margin: 0 0;
}

/**
 * Sidebar filter
 */

.program__filter {
	display: none;
	width: 100%;
	font-family: $good-bold;
	padding: $med 0 0 0;

	@include respond-to(medium-and-up) {
		width: 33.33%;
	}

	form {
		margin-bottom: 30px;
		font-size: $base-font-size;
		color: #8a8c89;

		ul {
			margin: 0;
			padding: 0;
		}

		.intro {
			color: #8a8c89;
			font-family: $good;
			margin-bottom: $med;
		}
	}

	label {
		display: block;
		cursor: pointer;
		position: relative;
		padding: 0 0 30px 0;

		&:hover {
			color: $whd-gray;
		}

		@include respond-to(medium-and-up) {
			padding: 0 0 30px 40px;
		}
	}

	.text {
		color: #2c312a;
		font-size: 20px;
		margin-bottom: 30px;
		padding-left: 40px;

		&:hover {
			color: $theme-color-02;
		}
	}

	.checkbox {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 30px;
		height: 30px;
		background-color: $whd-gray-light;
		border-radius: 3px;
		position: relative;
		cursor: pointer;
		position: absolute;
		top: -5px;
		right: 0;
		border: none;

		@include respond-to(medium-and-up) {
			left: 0;
		}

		&:focus {
			outline: none;
		}
	}
}

.program__filter .checkbox:checked {
	&:after {
		content: '';
		background-image: url('../images/checkmark@2x.png');
		background-size: 19px 16px;
		width: 19px;
		height: 16px;
		position: absolute;
		top: 7px;
		left: 5px;
	}
}

.program__filter .active {
	color: $whd-gray;
}

.sidebar-open .program__filter {
	display: block;
	width: 100%;

	@include respond-to(medium-and-up) {
		width: 25%;
		float: left;
	}
}

/**
 * Share program
 */

.share--program {
	@extend .share;
	position: absolute;
	right: 15px;
	top: 40px;

	p {
		display: none;

		@include respond-to(compact-and-up) {
			padding-top: 15px;
			display: inline-block;
		}
	}

	@include respond-to(compact-and-up) {
		top: 24px;
	}

	@include respond-to(medium-and-up) {
		right: 0;
		top: 80px;
	}
}

.single-whd_arrangement .share--program,
.single-whd_programma .share--program {

	p {
		display: inline-block;
		padding-top: 15px;
	}

	position: relative;
	float: right;
	height: 40px;
	top: 0;
	right:0;
	margin: 0 0 20px 0;
}

.filter__tag {
	color: $theme-color-01;
	margin: $med 0;
	display: inline-block;
}

.remove__tag {
	@include hide-text;
	background-image: url('../images/remove.png');
	background-size: 10px 10px;
	display: inline-block;
	height: 10px;
	width: 10px;
	margin-left: 3px;
}
