/*!-----------------------------------------------------------------------------------

	Copyright 2013 (C) 12HOOG Webspecialisten
	Made by 12HOOG Webspecialisten, ALL RIGHTS RESERVED
	Design & realisation by 12HOOG Webspecialisten | Jasper Rooduijn & Bart van der Waerden
	Handcoded with help of the 12HOOG Theme Framework and WordPress
	E-mail  : info@12hoog.nl
	Website : http://www.12hoog.nl

	File: 12HOOG Theme Framework main SCSS file

-----------------------------------------------------------------------------------*/

// --------------------------------------------------------------------------
// Breakpoints to output (for respond-to mixin)
// --------------------------------------------------------------------------

// Only un-comment to output other breakpoints other then [all]

// $breakpoints: [all];

// --------------------------------------------------------------------------
// System
// --------------------------------------------------------------------------

@import "partials/system/paths.scss";
@import "partials/system/mixins.scss";
@import "partials/system/units.scss";
@import "partials/system/respond-to.scss";
@import "partials/system/vertical-rhythm.scss";
@import "partials/system/modular-scale.scss";
@import "partials/system/color-schemer.scss";
@import "partials/system/variables.scss";
@import "partials/system/states.scss";
@import "partials/system/gridset.scss";

// --------------------------------------------------------------------------
// Base
// --------------------------------------------------------------------------

@import "partials/base/reset.scss";
@import "partials/base/base.scss";

// --------------------------------------------------------------------------
// Base objects
// --------------------------------------------------------------------------

@import "partials/objects/webfonts.scss";
@import "partials/objects/checkbox.scss";
@import "partials/objects/panoramabar.scss";
@import "partials/objects/search.scss";
@import "partials/objects/planner.scss";
@import "partials/objects/backgrounds.scss";
@import "partials/objects/lightbox.scss";
@import "partials/objects/buttons.scss";
@import "partials/objects/toggle.scss";
@import "partials/objects/select.scss";
@import "partials/objects/kaart.scss";
@import "partials/objects/slideshow.scss";

// --------------------------------------------------------------------------
// Sections
// --------------------------------------------------------------------------

@import "partials/sections/featured.scss";
@import "partials/sections/header.scss";
@import "partials/sections/news.scss";
@import "partials/sections/programma.scss";
@import "partials/sections/socials.scss";
@import "partials/sections/mijn-whd.scss";
@import "partials/sections/newsletter.scss";
@import "partials/sections/sidebar.scss";
@import "partials/sections/schedule.scss";
@import "partials/sections/timeschedule.scss";
@import "partials/objects/old-mousecraft.scss";
