/**
 * Web fonts
 *
 * Font events for web fonts loaded with Typekit and/or Google's
 * WebFont Loader to control the Flash of Unstyled Text (FOUT)
 */


.wf-loading {
	visibility: hidden; /* IE7/8 */
	opacity: 0;
}

.wf-active {
	@include transition(opacity,.24s);
	visibility: visible; /* IE7/8 */
	opacity: 1;
}

.wf-inactive {
	visibility: visible; /* IE7/8 */
	opacity: 1;
}
