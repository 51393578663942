#search {
	position: relative;
	z-index: 10;
	display: none;
	width: 100%;
	background-color: $whd-gray;

	input#btnSearch[type="submit"] {
		margin-right: 0;
	}

	form {
		padding: 15px;
		height: 100px;

		@include respond-to(medium-and-up) {
			padding: 20px 0 30px 0;
			height: 120px;
		}
	}

	.searchWrapper {
		float: left;
		margin-right: -60px;
		width: 100%;

		@include respond-to(medium-and-up) {
			margin-right: -80px;
		}

		div {
			margin-right: 60px;
			padding: 10px 15px;
			-webkit-border-top-left-radius: 3px;
			-moz-border-radius-topleft: 3px;
			border-top-left-radius: 3px;
			-webkit-border-bottom-left-radius: 3px;
			-moz-border-radius-bottomleft: 3px;
			border-bottom-left-radius: 3px;
			background: $snow-white;

			@include respond-to(medium-and-up) {
				margin-right: 80px;
			}

			input {
				padding: 0;
				width: 100%;
				height: 40px;
				border: none;
				background-color: transparent;

				@include respond-to(medium-and-up) {
					height: 60px;
				}
			}
		}
	}

	#btnSearch {
		float: right;
		width: 60px;
		height: 60px;
		border: none;
		-webkit-border-top-left-radius: 0px;
		-moz-border-radius-topleft: 0px;
		border-top-left-radius: 0px;
		-webkit-border-top-right-radius: 3px;
		-moz-border-radius-topright: 3px;
		border-top-right-radius: 3px;
		-webkit-border-bottom-right-radius: 3px;
		-moz-border-radius-bottomright: 3px;
		border-bottom-right-radius: 3px;
		-webkit-border-bottom-left-radius: 0px;
		-moz-border-radius-bottomleft: 0px;
		border-bottom-left-radius: 0px;
		background-color: #E7E7E7;
		background-position: -60px -60px;

		@include respond-to(medium-and-up) {
			width: 80px;
			height: 80px;
			background-position: 0 -200px;
		}

		&:hover {
			background-color: #B9B9B9;
		}
	}
}

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	height: 60px;
	background-color: $theme-color-02;
}

.menu-icon {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 60px;
	height: 60px;
	text-align: right;

	a {
		display: block;
		float: right;
		width: 60px;
		height: 60px;
		background-position: 4px 0;

		&:hover {
			background-color: $theme-color-02-darker;
		}
	}

	.active {
		width: 320px;
		background-color: #D01029;
	}
}

.logo {
	width: 100%;
	height: 60px;
	text-align: center;

	a {
		display: inline-block;
		width: 38px;
		height: 60px;
		background-position: 0 -60px;
	}
}

.search-icon {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9;
	width: 60px;
	height: 60px;

	@include respond-to(medium-and-up) {
		width: 80px;
		height: 80px;
	}

	a {
		display: block;
		width: 60px;
		height: 60px;
		background-color: $theme-color-02-darker;
		background-position: -60px 0;

		@include respond-to(medium-and-up) {
			width: 80px;
			height: 80px;
			background-position: 0px -120px;
		}

		&:hover {
			background-color: #A40D21;
		}

		.active {
			background-position: -120px -60px;

			@include respond-to(medium-and-up) {
				background-position: -320px -200px;
			}
		}
	}
}

.status--teasing,
.status--afterwards,
.status--non-selling {

	.teaser a {
		@include opacity(60);

		&:hover {
			@include opacity(100);
		}
	}

	.ticket-icon {
		@include opacity(60);

		&:hover {
			@include opacity(100);
		}
	}

	.ticket__lable {

		&:after {
			@include opacity(60);
		}

		&:hover {
			&:after {
				@include opacity(100);
			}
		}
	}
}

#topNav ul.meta {
	li.active a {
		color: $snow-white;
	}
}

#menu {
	position: absolute;
	top: 60px;
	left: 0;
	z-index: 1000;
	display: none;
	width: 100%;
	font-size: 1.25em;
	font-family: $good-bold;

	.wrapper {
		position: relative;
		z-index: 10;
		display: none;
		width: 260px;

		.red {
			background-color: darken($theme-color-02,5%);
		}

		.white {
			background-color: #E7E7E7;
		}
	}

	ul {
		margin: 0;
		padding: 15px;
		list-style: none;

		li {
			margin-top: 10px;

			&:first-child {
				margin-top: 0;
			}

			a {
				display: block;
				padding: 8px 15px;
				border-radius: 7px;
				text-decoration: none;
			}
		}

		&.social {
			float:left;
			margin-bottom: 15px;
			margin-left: 15px;
			padding:0;

			@include respond-to(medium-and-up) {
				margin-left: 25px;
				margin-bottom: 25px;
			}

			li {
				float:left;
				margin:0 15px 0 0;
			}
		}
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(44,49,42,0.8);
	}
}

	#menu.mobile .red ul.social li a,
	#menu ul.social li.twitter a,
	#menu ul.social li.flickr a,
	#menu ul.social li.facebook a {
		padding:0;
		width:40px;
		height:40px;
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}

	#menu ul.social:after {
		display: none;
	}

	#menu ul.social li.twitter a {
		background-position:0px -120px;
	}

	#menu ul.social li.flickr a {
		background-position:-50px -120px;
	}

	#menu ul.social li.facebook a {
		background-position:-100px -120px;
	}

	#menu .red ul li a {
		color: $snow-white;
	}

	#menu.mobile .red ul li a,
	#menu .red ul li.active a,
	#menu .red ul li a:hover {
		background-color: lighten($theme-color-02,1%);
	}

	#menu .white ul li a {
		color: #B2B0B0;
	}

	#menu.mobile .white ul li a,
	#menu .white ul li.active a,
	#menu .white ul li a:hover {
		background-color: #D5D5D5;
	}

/* ==========================================================================
   12.0 (Top) Navigation - Desktop (min 768px)
   ========================================================================== */

@include respond-to(medium-and-up) {

	#topNav {
		width: 100%;
		height: 30px;
		background-color: $whd-gray;
		font-size: 12px;
		font-weight: bold;
		position: relative;
		z-index: 10;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			height: 30px;
			overflow: hidden;

			li {
				float: left;
				margin-right: 20px;
				height: 26px;
				display: table;
				margin-top: 4px;

				.active a,
				&:hover {
					color: $snow-white;
				}

				a {
					color: #969895;
					text-decoration: none;
					height: 26px;
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
	}

	#menuDesktopTop ul.socialNav {
		margin-top: 4px;
		li a {
			padding: 0;
			border-radius: 0;

			&:hover {
				background-color: inherit;
			}
		}
	}

	#menuDesktopTop ul li.twitter a,
	#topNav ul li.twitter a {
		display:block;
		width:20px;
		height:20px;
		background-position:-110px -150px;

		&:hover {
			background-position:-110px -230px;
		}
	}

	#menuDesktopTop ul li.flickr a,
	#topNav ul li.flickr a {
		display:block;
		width:20px;
		height:20px;
		background-position:-190px -150px;

		&:hover {
			background-position:-190px -230px;
		}
	}

	#menuDesktopTop ul li.facebook a,
	#topNav ul li.facebook a {
		display:block;
		width:20px;
		height:20px;
		background-position:-270px -150px;

		&:hover {
			background-position:-270px -230px;
		}
	}

	#menuDesktopTop ul li.twitter,
	#menuDesktopTop ul li.flickr,
	#topNav ul li.twitter,
	#topNav ul li.flickr {
		// margin-top: 5px;
		margin-right: 5px;
	}

	#menuDesktopTop ul li.facebook,
	#topNav ul li.facebook {
		// margin-top: 5px;
		margin-right: 5px;
	}

	#menuDesktopTop ul li.language-switch {
		margin-right: 15px;
		margin-left: 10px;

		a {
			width: 30px;
			height: 20px;
			display: block;
            background-color: #969895;
            color: #FFFFFF;
            text-align: center;
            line-height: 20px;
			font-family: $good;
			font-size: 16px;

			&:hover {
                background-color: lighten(#969895,15);
            }
		}
	}

	#topNav ul li.language-switch {
		margin-top: 5px;
		margin-right: 0px;
		margin-left: 10px;

		a {
			width: 30px;
			height: 20px;
			display: block;
            background-color: #969895;
            color: #FFFFFF;
            text-align: center;
            line-height: 20px;

			&:hover {
                background-color: lighten(#969895,15);
            }
		}
	}

	#menuDesktopTop {
		background-color: rgba($whd-gray,0.75);
		height: 50px;

		ul {
			li {
				margin-top: 5px;
				&.active a {
					background-color: darken($whd-gray, 5);
				}

				a {
					color: $gray;
					&:hover,
					.active a {
						background-color: darken($whd-gray, 5);
					}
				}
				&:first-child {
					a {
						background-color:transparent;
						color: #fff;
					}
				}
			}
		}
	}

	#menuDesktop {
		background-color: rgba($theme-color-01,0.75);
		height: 80px;

		ul {
			padding: 5px 0 0 0;
			// width: 470px;

			@include respond-to(large-and-up) {
				padding: 0;
				// width: 700px;
			}

			li {
				margin-top: 20px;
				//
				// @include respond-to(large-and-up) {
				// 	margin-top: 25px;
				// }

				&.active a {
					background-color: darken($theme-color-01, 5);
				}

				a {
					color: $snow-white;
					&:hover,
					.active a {
						background-color: darken($theme-color-01, 5);
					}
				}
			}
		}
	}

	#menuDesktopTop,
	#menuDesktop {
		width: 100%;
		font-size: $base-font-size;
		font-family: $good-bold;
		position: relative;
		z-index: 10;

		ul {
			margin: 0 0 0 5px;
			list-style: none;
			display: inline-block;

			li {
				float: left;
				margin-left: 10px;
				line-height: 1;
				height: 40px;

				// @include respond-to(large-and-up) {
				//
				// 	&:nth-child(5) {
				// 		display: none;
				// 	}
				// }

				// &:nth-child(6) {
				// 	display: none;
				//
				// 	@include respond-to(large-and-up) {
				// 		display: block;
				// 	}
				// }

				// &.active a {
				// 	background-color: #D01029;
				// }

				a {
					padding: 10px;
					display: block;
					text-decoration: none;
					border-radius: 3px;

					// @include respond-to(large-and-up) {
					// 	padding: 10px;
					// }

					// &:hover,
					// .active a {
					// 	background-color: #D01029;
					// }
				}
			}
		}
	}
}
