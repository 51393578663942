.toggle {
    cursor: pointer;
}

.is-collapsed {
    display: none;
}

.is-expanded {
    display: block;
}

tbody.is-expanded {
    display: table-row-group;
}
