/* ==========================================================================
   Planner - Mobile
   ========================================================================== */

	#planner {
		@include box-sizing(border-box);
		width: 100%;
		color: $snow-white;
		font-size: 24px;
		font-family: $good-bold;
		background-color: $theme-color-02;

		.step {
			font-size: $base-font-size;
			position: absolute;
			right: 80px;
			bottom: 22px;
			z-index: 10;
			font-size: 16px;

			@include respond-to(medium-and-up) {
				right: 110px;
				// bottom: 10px;
				bottom: 45px;
			}

			@include respond-to(large-and-up) {
				bottom: 45px;
			}
		}

		form {
			clear: both;
			overflow: hidden;
		}

		select {
			width: 100%;
			padding: 16px 20px;
			border: 0;
			background: transparent;
			color: $whd-gray;
			font-size: 24px;
			font-family: $good-bold;
			line-height: 26px;
			-webkit-appearance: none;

			@include respond-to(large-and-up) {
				font-size: 30px;
			}
		}

		.submit {
			background-color: $theme-color-02-dark;

			@include respond-to(medium-and-up) {
				float: right;
			}

			a {
				position: relative;
				float: right;
				clear: both;
				width: 60px;
				height: 60px;
				background-color: $theme-color-02-darker;

				&:before {
					@include rotate(45deg);
					position: absolute;
					content: "";
					display: block;
					width: 18px;
					height: 8px;
					background-color: $theme-color-02-dark;
					left: 0;
					top: 50%;
					margin-top: -7px;
					margin-left: -12px;

					@include respond-to(medium-and-up) {
						background-color: $theme-color-02;
					}
				}

				&:hover {
					background-color: #A40D21;

					@include respond-to(medium-and-up) {
						background-color: #A40D21;
					}
				}

				@include respond-to(medium-and-up) {
					background-color: $theme-color-02-darker;
					width: 90px;
					height: 112px;
					background-position: -312px -114px;
				}

				@include respond-to(large-and-up) {
					background-position: -312px -108px;
				}
			}
		}

		ul {
			// border: 1px solid black;
			margin: 0;
			padding: 0;
			width: 200%;
			list-style: none;

			li {
				float: left;
				width: 50%;
				position: relative;
				// border: 2px solid purple;

				.inner {
					// position: relative;
					// border: 1px solid yellow;
					// margin: 20px 20px 0 20px;
					padding: 20px;

					padding: 15px;

					@include respond-to(medium-and-up) {
						float: left;
						padding: 20px;
						margin: 0;

						div {
							float: left;
						}
					}

					// @include respond-to(large-and-up) {
					// 	padding: 32px;
					// }
				}

				.text {
					padding: 8px 0;

					@include respond-to(medium-and-up) {
						padding: 20px 0;
					}

				}
			}
		}

		.select_stap_1,
		.select_stap_2 {
			border-radius: 3px;
			background-color: $snow-white;
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

/* ==========================================================================
   Overige planner styles
   ========================================================================== */

	#planner ul li .inner .select_stap_1,
	#planner ul li .inner .select_stap_2 {

		@include respond-to(medium-and-up) {
			width: 350px;
			margin: 12px 20px 5px 20px;
		}

		@include respond-to(large-and-up) {
			width: 500px;
		}
	}

	#planner .submit #plannerSubmit.loading {
		background-image: url('../images/ajax-loader.gif');
		background-position: center center !important;
	}

	#planner .submit #plannerSubmit.loading:hover {
		background-color: $theme-color-02-darker;
	}
