.timeschedule {

    // table,
    // thead,
    // tbody,
    // tr,
    // th,
    // td {
    //     @include box-sizing(border-box);
    // }

    table {
        width: 100%;
        font-size: $base-font-size;
        background-color: $snow-white;
        margin-bottom: 20px;

        th.corner {
            vertical-align: bottom;
            span {
                display: block;
                position: relative;

                &:after {
                    position: absolute;
                    right: 15px;
                    bottom: 0px;
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-right: 4px solid white;
                    border-bottom: 4px solid white;
                    content: "";
                }
            }
        }

        tr:first-child td {
            padding-top: 20px;
            padding-bottom: 5px;
        }

        td.no-border,
        tr:last-child td {
            border-bottom: none;
        }

        td {
            text-align: left;
            border-bottom: 2px solid $theme-color-01;
            padding: 5px 0;

            &:first-child,
            &:last-child {
                width: 15px;
            }

            &:nth-child(2) {
                width: 140px;
            }

            &:nth-child(3) {
                width: 510px;
            }

            &:nth-child(4) {
                width: 140px;
            }

            &:nth-child(5) {
                width: 140px;
            }

            &.url {
                text-align: right;
                white-space: nowrap;
            }
        }

        th {
            background-color: $theme-color-01;
            color: #fff;
            font-size: 22px;
            font-family: $good-bold;
            font-weight: normal;
            text-align: left;
            padding: 15px 0;

            &:first-child,
            &:last-child {
                padding: 15px 0;
                width: 15px;
            }

            &:nth-child(2) {
                width: 650px;
            }

            &:nth-child(3) {
                width: 140px;
            }

            &:nth-child(4) {
                width: 140px;
            }

            &.location {
                font-family: $good;
            }
        }

        .time {
            font-family: $good-bold;
            color: $theme-color-01;
            white-space: nowrap;
        }
    }

    .mobile {

        tr:first-child td {
            padding-top: 20px;
            padding-bottom: 5px;
        }

        th {
            padding: 15px 0;
        }

        td {
            padding: 5px 0;
        }

        th:nth-child(2),
        td:nth-child(2) {
            width: 96%;
        }

        th:nth-child(1),
        th:nth-child(3),
        td:nth-child(1),
        td:nth-child(3) {
            width: 2%;
        }

        .location {
            display: block;
            font-family: $good;
            font-size: 18px;
            margin-top: 10px;
        }

        .time {
            display: block;
        }

        .title {
            display: block;
            margin: 5px 0;
        }

        .url {
            float: right;
        }
    }
}
