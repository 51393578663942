/* ==========================================================================
   Search
   ========================================================================== */

	.search{ 

		h1 {
			display: inline;
			color: $theme-color-02;	
		}

		#subTitel {
			float:left;
			padding: 86px 0 0;
			width: 170px;
			height: 49px;
			border-bottom: 1px solid #000;
		}

		#titel {
			margin: 0;
			padding: 20px 0 20px 0;
			width: 100%;		

			@include respond-to(medium-and-up) {
				width: 540px;
				height: 95px;
				float: left;
				border-bottom: 1px solid #000;
				clear: right;
				padding: 40px 0 0;
				margin: 0 0 0 20px;

				h1 {
					font-size: 3em;
					line-height: 120%;
				}
			}
		}

		#subNav {
			padding: 0 0 10px;
			width: 100%;
			border-bottom: 1px solid #000;

			@include respond-to(medium-and-up) {
				width: 170px;
				float: left;
				padding: 10px 0 0;
				border: none;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					margin: 0 0 10px;
					padding: 0;
					width: 100%;
					list-style: none;

					a {
						display: block;
						padding: 10px 10px;
						border-radius: 2px;
						background: $whd-gray-light;
						color: #8a8c89;
						text-align: center;
						text-decoration: none;
						font-size: 16px;
						font-family: $good-bold;

						@include respond-to(medium-and-up) {
							text-align: left;
						}
					}
				}
			}
		}

		ul#results {
			margin: 0;
			padding: 0;

			@include respond-to(medium-and-up) {
				width:540px;
				float:left;
				margin:0 0 0 20px;
			}

			li {
				padding: 20px 0 10px;
				border-bottom: 1px solid #000;
				list-style: none;

				h1 {
					color:$whd-gray;
					font-size:16px;
				}
			}
		}

		#bottomNav {
			width: 100%;

			@include respond-to(medium-and-up) {
				width:540px;
				float:left;
				margin:0 0 20px 190px;
			}

			ul {
				margin:0;
				padding:20px 0 0;

				li {
					margin:0;
					padding:0;
					list-style:none;	

					a {
						display:block;
						padding:10px 10px;
						border-radius:2px;
						background:$whd-gray-light;
						color:$snow-white;
						text-decoration:none;
						font-size:16px;
						font-family:$good-bold;
					}
				}
			}
		}
	}

	.search #subNav ul li a.active{
		background-color:#0065bf;
		color:$snow-white;
	}

	.search #bottomNav ul li.left a{
		margin:0;
		padding:15px 20px 15px 40px;
		background-color: #0065BF;
		background-position: left center;
	}

	.search #bottomNav ul li.right a{
		margin:0;
		padding: 15px 43px 15px 20px;
		background-color: $theme-color-02;
		background-position: right center;
	}

	.search #bottomNav .arrowLeft{
		position: absolute;
		width: 50px;
		height:100%;
		background-position: 5px 18px;
		background-repeat: no-repeat;
	}

	.search #bottomNav .arrowRight{
		position:absolute;
		right:0;
		z-index:50;
		width: 50px;
		height:100%;
		background-position: 15px 18px;
		background-repeat: no-repeat;
	}

	@include respond-to(medium-and-up) {
		
		.search #titel.noresults {
			margin-left: 200px;
			height:auto;
			border:none;
		}
	}