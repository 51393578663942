@import url('https://fonts.googleapis.com/css?family=Noto+Serif:700|Open+Sans');

// --------------------------------------------------------------------------
// Variables
// --------------------------------------------------------------------------

// Colors schemer (Just don't touch thiss one)
// --------------------------------------------------------------------------

$main-color:                    rgb(0,0,255);
$cs-primary:                    $main-color;
$cs-color-model:                rgb;                            // possible options are 'rgb' or 'ryb'
$cs-scheme:                     triad;                          // possible color schemes are 'mono', 'complement', 'triad', 'tetrad', 'analogic' and 'accented-analogic'

// Colors
// --------------------------------------------------------------------------

// Project colors
$whd-gray:					#2C312A;
$whd-gray-light:			#E8E8E8;
$whd-gray-dark:			    #303030;

$theme-color-01:			#D93600; /* orange */
$theme-color-01-dark:		darken($theme-color-01,10);

$theme-color-02:			#666600; /* olive green */
$theme-color-02-dark:		darken($theme-color-02,10);
$theme-color-02-darker:		darken($theme-color-02,20);

// Grays

$pitch-black:               rgb(0,0,0);
$black:                     rgb(20,20,20);
$gray-dark:                 tint($pitch-black,35%);

$gray:                      rgb(188, 188, 188);
$gray-light:                rgb(224,224,224);
$gray-lighter:              rgb(244,244,244);
$gray-lightest:             rgb(244,244,244);

$white:                     rgb(250,250,250);
$snow-white:                rgb(255,255,255);

// Accent colors

$red:                           rgb(255,0,0);
$green:                         rgb(0,255,0);
$blue:                          rgb(0,0,255);
$orange:                        rgb(255,128,0);
$yellow:                        rgb(255,255,0);
$purple:                        rgb(128,0,255);

// Complement colors

$complement:                    adjust-hue($main-color,180);    // Determine a complementary color
$split1:                        adjust-hue($main-color,158);    // Split complements
$split2:                        adjust-hue($main-color,-158);
$triad1:                        adjust-hue($main-color,135);    // Triads colors
$triad2:                        adjust-hue($main-color,-135);
$tetra1:                        adjust-hue($main-color,90);     // Tetra colors
$tetra2:                        adjust-hue($main-color,-90);
$analog1:                       adjust-hue($main-color,22);     // Analogs colors
$analog2:                       adjust-hue($main-color,-22);

// Button colors

$button-border:                 #d24219 !default;
$button-text-color:             rgb(60,60,60);

// Message colors
$succes-color:					rgb(79,138,16);
$error-color:                 	rgb(216,0,12);
$warning-color:                 rgb(212,176,7);
$info-color:                   	rgb(0,82,155);

// Highlight colors

$ins-color:                    	rgb(255,255,153);
$mark-color:                   	rgb(255,255,0);
$del-color:						rgb(255,0,0);


// Shadow colors

$shadow-color:                  rgb(220,220,220);
$shadow-color-alpha:            rgba(140,140,140,.3);

// Typography
// --------------------------------------------------------------------------

// Font sizes (see also breakpoints)
$font-size-small:               14px;                           // <= mobile
$font-size-compact:             15px;                           // <= tablet portrait
$font-size-medium:              16px;                           // <= dektop/tablet
$font-size-large:               18px;
$font-size-wide:                19px;
$font-size-huge:                $font-size-wide;

// Line heights
$line-height:                   1.6;                            // <= default
$line-height-small:             1.4;                            // <= mobile
$line-height-compact:           1.5;                            // <= tablet (portrait?)
$line-height-medium:            $line-height;                   // <= desktop
$line-height-large:            	$line-height;
$line-height-wide:              1.7;
$line-height-huge:              $line-height-wide;

// Baseline
$base-font-size:                18px;                           // default font size for all text in pixels; better not to change, is browser default (100% = 1em ~= 16px ~= 14pt)
$base-line-height:              ($base-font-size * $line-height); // distance between text baselines (vertical rhythm) in pixels

// Project fonts
$good:                     		'Open Sans', sans-serif;
$good-bold:                     'Noto Serif', serif;

// Font stacks
$sans-font:                     "Helvetica Neue", Helvetica, Arial, sans-serif;
$serif-font:                    "Lucida Bright", Georgia, serif;
$mono-font:                     Monaco, "Courier New", monospace, serif;
$headings:                      "Helvetica Neue", Helvetica, Arial, sans-serif;
$icons:                         "Entypo";                       // Entype, Font-Awesome

// Vertical rhythm
// --------------------------------------------------------------------------

$rhythm-unit:                   'rem';                          // length unit in which to output rhythm values, supported values are 'px', 'em' or 'rem'
$rem-with-px-fallback:          true;                           // whether to output fallback values in px when using rem as the rhythm-unit
$min-line-padding:              2px;                            // ensure there is at least this many pixels of vertical padding above and below the text

// Ratios
// --------------------------------------------------------------------------

$golden:                        1.618;
$double-octave:                 (4 / 1);
$major-twelfth:                 (3 / 1);
$major-eleventh:                (8 / 3);
$major-tenth:                   (5 / 2);
$octave:                        (2 / 1);
$major-seventh:                 (15 / 8);
$minor-seventh:                 (16 / 9);
$major-sixth:                   (5 / 3);
$minor-sixth:                   (8 / 5);
$fifth:                         (3 / 2);
$augfourth:                     (1 / 1.4142135623730950488);    // (1 / square root 2)
$fourth:                        (4 / 3);
$major-third:                   (5 / 4);
$minor-third:                   (6 / 5);
$major-second:                  (9 / 8);
$minor-second:                  (16 / 15);

// Modular scale
// --------------------------------------------------------------------------

$ratio:                         $golden;                        // ratios can be stacked, e.g. $major-third $fifth
$columns:                       12;                             // can be a number or a list of non-uniform column widths

// Breakpoints
// --------------------------------------------------------------------------
// Breakpoints (target width in em's = pixel width / base font size)
// Apply proportional media queries (em's): http://bit.ly/JT9ZzI
// Design from the content out, rather than the canvas in: http://bit.ly/JTa5aA
// Maybe in the (near) future apply Responsive Viewport Units instead of
// breakpoints: http://bit.ly/IXDtMb
// Adjust the breakpoints to best fit content and (visual) design

$breakpoint-small:             20;                             	// <= mobile 320px
$breakpoint-compact:           30;								// <= 480px
$breakpoint-medium:            48;                             	// <= desktop/tablet 768px
$breakpoint-large:             60;								// <= 960px
$breakpoint-wide:              80;
$breakpoint-huge:              90;

// Sizes (handy for consistent margins, paddings, etc. based on ratio)
// --------------------------------------------------------------------------

$norm-size:                     1em;
$size-ratio:                    $ratio;                         // the default ratio set in variables.scss

$xxx-small:                     ms(-4,$norm-size,$size-ratio);
$xx-small:                      ms(-3,$norm-size,$size-ratio);
$x-small:                       ms(-2,$norm-size,$size-ratio);
$smaller:                       ms(-1,$norm-size,$size-ratio);
$small:                         (ms(-1,$norm-size,$size-ratio) + $norm-size)/2;
$med:                           (ms(1,$norm-size,$size-ratio) + $norm-size)/2;
$norm:                          ms(1,$norm-size,$size-ratio);
$large:                         ms(2,$norm-size,$size-ratio);
$x-large:                       ms(3,$norm-size,$size-ratio);
$xx-large:                      ms(5,$norm-size,$size-ratio);
$xxx-large:                     ms(8,$norm-size,$size-ratio);

// Gutter(s)
// --------------------------------------------------------------------------

$gutter:                       $large;
$gutter-imgrid:                .6%;                             // multi-figure images and/or images-grid

// Contain margin and padding
// --------------------------------------------------------------------------

$contain-margin:               6%;
$contain-padding:              6%;

// --------------------------------------------------------------------------

$border-radius:                $xx-small;

// Colors schemer
// --------------------------------------------------------------------------

$main-color:                    rgb(0,0,255);

$cs-primary:                    $main-color;
$cs-color-model:                rgb;                            // possible options are 'rgb' or 'ryb'
$cs-scheme:                     triad;                          // possible color schemes are 'mono', 'complement', 'triad', 'tetrad', 'analogic' and 'accented-analogic'
