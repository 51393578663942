/* ==========================================================================
   Mijn WHD
   ========================================================================== */

.mijn-whd {

	strong {
		font-weight: bold;
	}

	.one-third {
		width: 32%;
		float: right;
	}

	.two-third {
		width: 65%;
		float: left;
	}

	.mijn-whd__intro {
		margin-bottom: 60px;

		&.blue,
		p {
			color: $theme-color-01;
		}

	}

	.mijn-whd__hr {
		border: none;
		margin-top: 20px;
		margin-bottom: 60px;
		display: inline-block;
		height: 1px;
		background-color: $whd-gray-light;
		width: 100%;
	}

	.mijn-whd__subnav {
		position: absolute;
		top: 0;
		right: 0;
	}

	.mijn-whd__tag {
		color: #8a8c89;
		margin: 0 0 15px 0;
		display: block;
		font-size: .9375em;
		min-height: 22px;
	}

	.mijn-whd__title {
		margin-top: 0 !important;
	}

	h1 {
		font-size: 30px;
		font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;
		padding: 15px 0 !important;
	}

	h2 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 26px;
		font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;
		padding-right: 75px;
	}

	h3 {
		font-size: 20px;
		font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;

		&.title-account {
			margin-top: 150px;
			margin-bottom: 10px;
		}
	}

	p {
		line-height: 22px;
		margin-bottom: 20px;
	}

	.mijn-whd__form {
		input[type="checkbox"],
		input[type="radio"] {
			margin-top: 5px !important;
		}

		.field_sublabel_above {
			.ginput_right {
				label {
					margin-top: 10px !important;
				}
			}
		}
		.ginput_left,
		.ginput_right {
			width: 100% !important;
			float: left;
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
	}

	#field_6_7 .gfield_label {
		display: none;
	}

	.btn--register {
		background-color: $whd-gray-light;
		color: $whd-gray;
		padding: 20px 45px 20px 20px;
		position: relative;
		width: auto;
		background-image: url(../images/icon--next-dark.svg);
		background-repeat: no-repeat;
		background-position: center right;
		float: right;
		margin: 0 20px 0 0;
		&:hover {
			color: $snow-white;
			background-color: $whd-gray;
			background-image: url(../images/icon--next.svg);
		}
	}

	.mijn-whd__form.registratie,
	#loginform {
		input[type=submit] {
			background-color: $theme-color-01;
			padding: 19px 45px 19px 20px;
			position: relative;
			background-image: url(../images/icon--next.svg);
			background-repeat: no-repeat;
			background-position: center right;
			float: right;
			margin: 0;
			text-indent: 0;
			border: none;
			color: #fff;
			&:hover {
				background-color: $theme-color-01-dark;
			}
		}
	}

	.mijn-whd__form.profiel {
		input[type=submit] {
			background-color: $theme-color-01;
			padding: 19px 45px 19px 20px;
			position: relative;
			background-image: url(../images/icon--submit.svg);
			background-repeat: no-repeat;
			background-position: center right;
			float: right;
			margin: 0;
			text-indent: 0;
			border: none;
			color: #fff;
			&:hover {
				background-color: $theme-color-01-dark;
			}
		}
	}

	.mijn-whd__form.registratie,
	.mijn-whd__form.profiel,
	#loginform {
		label {
			display: block;
			margin-bottom: 10px;
			a {
				font-weight: bold;
			}
		}
		input[type=password],
		input[type=text] {
			font-family: $good;
			border: none;
			width: 100%;
			padding: 20px !important;
			background-color: $whd-gray-light;
			box-sizing: border-box;
		}
	}

}
