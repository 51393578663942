/* 12HOOG custom Toggle class
   ========================================================================== */

.toggle {

	&.toggle--closed {

		.toggle__content {
			display: none;
		}

		.toggle__icon {
			background-image: img-url("plus.gif");
			background-position: 4px 1px;
		}

		&:hover .toggle__icon {
			background-position: 4px -40px;
		}
	}

	&.toggle--open {
		
		.toggle__content {
			display: block;
		}
	}
}

.toggle__trigger {
	position: relative;
	cursor: pointer;
	
	&:hover .toggle__icon {
		background-position: 4px -21px;
	}
}

.toggle__icon {
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 0;
	top: 2px;
	background-image: img-url("min.gif");
	background-position: 4px 8px;
	background-repeat: no-repeat;
}
