/**
 * 'Base' styles for browsers that don't support media queries
 */


/**
 * Enable automatic content scaling in IE 10, see: http://j.mp/TT6lHf
 * In Metro IE10 snap mode works most of the time correct when using: "width: device-width;",
 * but it doesn't always work as meant on Windows Phone 8 (e.g. Nokia Lumia 820).
 * To fix this issue on Windows Phone 8 for now see: http://trentwalton.com/?p=5994
 */

@-webkit-viewport { width:device-width }
@-moz-viewport { width:device-width }
@-ms-viewport { width:device-width }
@-o-viewport { width:device-width }
@viewport { width:device-width }

/* Base Body/HTML
   ========================================================================== */

html { height: 100%; }

body {
	min-height: 100%;
	color: $whd-gray;
	font-family: $good;
	font-size: $font-size-medium;
	-webkit-font-smoothing: antialiased;
}

strong {
	font-family: $good-bold;
}

/* Base Helper classes
   ========================================================================== */

.ellipsis {
	text-overflow: ellipsis;
	display: block;
	white-space: nowrap;
	overflow: hidden;
}

.js-block {
	cursor: pointer;
}

/* Base type (global, no scope)
   ========================================================================== */

img {
	max-width: 100%;
	height: auto;
}

/* Responsive classes
   ========================================================================== */

.desktop {
	display: none;

	@include respond-to(medium-and-up) {
		display: block;
	}
}

.mobile {
	display: block;

	@include respond-to(medium-and-up) {
		display: none;
	}
}

/* Responsive classes
   ========================================================================== */

.shadow {
	@include box-shadow(0px, 0px, 300px, 0, rgba(0, 0, 0, 0.5) );
}

.page #textWrapper .wrapper{
	h2 {
		color: $whd-gray;
		margin-bottom: $small;
	}

	ul {
		padding: 0;
		margin: $med 0;

		li {
			position: relative;
			padding-left: $med;

			&:before {
				content: "•";
				color: $theme-color-01;
				position: absolute;
				left: 0;
			}
		}
	}

	p {
		margin-bottom: $med;
	}

	strong {
		font-family: $good-bold;
	}
}

.page #textWrapper .bckBlue .wrapper {
	h2 {
		color: $whd-gray;

		@include respond-to(medium-and-up) {
			color: $snow-white;
		}
	}
	ul li:before {
		color: $whd-gray;

		@include respond-to(medium-and-up) {
			color: $snow-white;
		}
	}
}

.page #textWrapper .bckRed .wrapper {
	h2 {
		color: $whd-gray;

		@include respond-to(medium-and-up) {
			color: $snow-white;
		}
	}
	ul li:before {
		color: $whd-gray;

		@include respond-to(medium-and-up) {
			color: $snow-white;
		}
	}
}

#textWrapper #page_5842 ul li,
#textWrapper #page_52 ul li {
	padding-left: 0 !important;

	&:before {
		display: none;
	}
}

#page_219 h1 span{display:none}

#page_219 span {
	display: block;
	margin-bottom: $x-small;
}

#page_219 span.label {
	display: block;
	font-family: $good-bold;
	margin-bottom: 0;
	color: $theme-color-01;
}


/* RET FORM
   ========================================================================== */

#travel-planner {
	@extend %clearfix;

	label {
		color: $whd-gray;
		font-family: $good-bold;
	}

	input[type=radio] {
		margin-right: 5px;
	}

	input[type=text],
	select {
		height: 34px;
	}

	select {
		width: 100%;
		padding: $x-small;
		font-family: $good;
		color: $whd-gray;
		border-radius: 4px;
		-webkit-appearance:none;
		outline: none;
		border: none;
		margin-bottom: $med;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;

		&:focus {
			border: none;
			outline: none;
		}

		option {
			padding: $x-small;
		}

	}

	#resultaat {
		margin: 0;
	}

	.submit {
		float: right;
		border: none;
	}
}

label#single_userinput_time {
	color: red;
}

#single_userinput_departure,
#single_userinput_arrival,
#single_userinput_time {
	width: 100%;
	padding: $x-small;
	font-family: $good;
	color: $whd-gray;
	border-radius: 4px;
	-webkit-appearance:none;
	outline: none;
	border: none;
	margin-bottom: $med;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	&:focus {
		border: none;
		outline: none;
	}
}

#single_userinput_departuretype {
	margin-bottom: $med;

	label {
		margin-right: $small;
	}
}
