/**
 * Normalize
 *
 * Normalize styles, mostly picked from HTML5 Boilerplate (http://html5boilerplate.com/)
 * and Normalize.css (http://necolas.github.com/normalize.css/)
 */


/**
 * 1. Force vertical scrollbar in non-IE (keeps page centred in all browsers regardless of content height)
 * 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: http://bit.ly/JL2rhv
 */

html {
  overflow-y: scroll; // 1
  -webkit-text-size-adjust: 100%; // 2
    -ms-text-size-adjust: 100%; // 2
}

/* Reset browser body margins */

body {
  margin: 0;
}

/* HTML5 display definitions */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

/* Corrects inline-block display not defined in IE9 & FF3 */

audio,
canvas,
video {
  display: inline-block;
}

/* Prevents modern browsers from displaying 'audio' without control */

audio:not([controls]) {
  display: none;
  height: 0;
}

/* Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4 */

[hidden] {
  display: none;
}


/* Headings
   ========================================================================== */

/* Resets all headings to look like normal text */

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
}


/* Block Text
   ========================================================================== */

p {
  margin: 0;
}

blockquote {
  margin: 0;
}

/* Improve readability of pre-formatted text in all browsers */

pre {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}


/* Inline Text
   ========================================================================== */

cite {
  font-style: normal;
}

ins {
  text-decoration: none;
}

dfn {
  font-style: inherit;
}

del {
  text-decoration: none;
}

mark {
  background: none;
  color: inherit;
}

address {
  font-style: normal;
}

code,
kbd,
samp {
  font-family: inherit;
  font-size: inherit;
}

b,
strong {
  font-weight: inherit;
}

em {
  font-style: inherit;
}

small {
  font-size: 100%;
}

/* Addresses quote property not supported in S4 */

q:before,
q:after {
  content: "";
  content: none;
}

/* Set sub, sup without affecting line-height: gist.github.com/413930 */

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

/**
 * 1) Move the subscripted text down, but only half as far down as the superscript moved up
 * 2) Move the superscripted text up
 */

sub { bottom: -.2em; }
sup { top: -.4em; }


/* Lists
   ========================================================================== */

dl,
dd {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

/**
 * Reset list items back to normal (block) display.
 * This makes it possible to use lists for semantic
 * markup without needing to set this each time.
 * Lists only look like ‘lists’ in blocks of copy.
 */

li {
  display: block;
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}


/* Links
   ========================================================================== */

a {
    font-weight: inherit;
    color: inherit;
    text-decoration: none;
}

a img { border: 0; }

/**
 * Improve readability when focused and hovered in all browsers
 * http://people.opera.com/patrickl/experiments/keyboard/test
 */

a:focus,
a:hover,
a:active {
  outline: 0 !important;
}

/* Addresses outline displayed oddly in Chrome */

a:focus { outline: thin dotted; }


/* Images
   ========================================================================== */

/**
 * Removes border when inside 'a' element in IE7/8/9, FF3
 * Remove the gap between images and borders on image containers (http://h5bp.com/e)
 */

img {
  border: 0;
  vertical-align: middle;
}

/* Correct overflow displayed oddly in IE9 */

svg:not(:root) {
  overflow: hidden;
}

/* Addresses margin not present in IE7/8/9, S5, O11 */

figure {
  margin: 0;
}


/* Forms + Buttons
   ========================================================================== */

form {
  margin: 0;
}

/* Define consistent border, margin, and padding */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/**
 * 1. Corrects color not being inherited in IE7/8/9
 * 2. Remove padding so people aren't caught out if they zero out fieldsets
 */

legend {
  border: 0; /* 1 */
  padding: 0; /* 2 */
}

/**
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */

input,
button,
select,
textarea {
  font-size: 100%; /* 1 */
  font-size: inherit; /* 1 */
  font-family: inherit;
  margin: 0; /* 2 */
  padding: 0; /* 2 */
  text-transform: inherit;
  vertical-align: baseline; /* 3 */
}

/* Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet */

button,
input {
  line-height: normal;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0. where (2) destroys native 'audio' and 'video' controls
 * 2. Improves usability and consistency of cursor style between image-type 'input' and others
 * 3. Allow styling of clickable form elements in iOS
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/* Re-set default cursor for disabled elements */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -webkit-box-sizing: content-box; /* 2 */
     -moz-box-sizing: content-box;
      box-sizing: content-box;
}

/* Removes inner padding and search cancel button in Safari 5 and Chrome on OS X */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* Remove inner padding and border in Firefox 4+ */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Removes default vertical scrollbar in IE 8/9
 * 2. Improves readability and alignment in all browsers
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
//  resize: vertical;
}


/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells
 * Set border-collapse' to 'separate' to be able to round corners of a table
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
    box-sizing: border-box;
}
