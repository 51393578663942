/* ==========================================================================
   News wrappers
   ========================================================================== */

.single-whd_news #photo {
	@include respond-to(medium-and-up) {
		height: 610px !important;
	}
}

.news__wrapper {
	@extend .shadow !optional;
	@include respond-to(medium-and-up) {
		margin-top: 500px;
	}

	.content-half p,
	.content-half ol,
	.content-half ul {
		font-size: 18px;
	}

	.content-half p a {
        color: $theme-color-02;
        &:hover {
            text-decoration: underline;
            color: inherit;
        }
	}

	.content-half p strong {
        font-weight: bold;
	}

}

.newsarchive__wrapper {
	@include respond-to(medium-and-up) {
		margin-top: 150px;
	}
}

/* ==========================================================================
   News navigation
   ========================================================================== */

.news__navigation {
	display: block;
	width: 100%;
	float: left;

	ul {
		display: table;
		margin: 0 auto;
		text-align: center;
		li {
			float: left;
			display: inline-block;
			padding: $small $med;
			border: 1px solid green;
		}
	}

	.news__navigation__btn {
		@include transition(all,0.2s, ease-in-out);
		margin: 5px 0;
		padding: 10px;
		width: auto;
		display: inline-block;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background-color: $theme-color-02;
		background-position: center right;
		color: #FFF !important;
		text-decoration: none;
		font-size: 17px;
		font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;

		&.prev {
			float: left;
		}

		&.next {
			float: right;
		}

		&:hover {
			background-color: $theme-color-02-dark;
		}
	}
}

/* ==========================================================================
   News item
   ========================================================================== */

.news__item {
	@include box-sizing(border-box);
	display: block;
	float: left;
	margin-bottom: 20px;
	width: 100%;
	cursor: pointer;

	a {
		color: $whd-gray;
		text-decoration: none;
	}

	img {
		width: 100%;
		vertical-align: middle;

		@include respond-to(compact-and-up) {
			float: left;
			width: 33.33%;
		}

		@include respond-to(medium-and-up) {
			width: 100%;
		}
	}

	h2 {
		margin: 0;
		font-size: 26px;
		font-family: $good-bold;
		line-height: 1;
	}

	span {
		display: block;
		margin: 50px 0 30px 0;
		color: $theme-color-02;
		font-size: $base-font-size;
		font-family: $good-bold;
	}

	@include respond-to(medium-and-up) {
		margin-right: 20px;
		margin-bottom: 20px;
		width: 31.5%;

		&:last-child {
			margin-right: 0;
		}

		&.last {
			margin-right: 0;
		}
	}

	@include respond-to(large-and-up) {
		width: 31.78%;
	}

	&.banners {
		a {
			display: inline-block;
			width: 100%;

			&:first-child {
				margin-bottom: 20px;
			}
		}
	}
}

.news__item__content {
	@include box-sizing(border-box);
	padding: 25px 20px 30px 20px;
	background-color: white;

	p {
		line-height: 1.25;
	}

	@include respond-to(compact-and-up) {
		width: 66.66%;
	}

	@include respond-to(medium-and-up) {
		float: right;
		width: 100%;
		min-height: 300px;
	}
}
