/* ==========================================================================
   Buttons
   ========================================================================== */

.btn {
	@include box-sizing(border-box);
	@include transition(all,0.2s, ease-in-out);
	color: $snow-white;
	text-decoration: none;
	padding: 20px;
	width: 100%;
	display: inline-block;
	font-family: $good-bold;
	border-radius: 3px;
	font-size: $base-font-size;
	position: relative;

}

.btn--small {
	@include box-sizing(border-box);
	@include transition(all,0.2s, ease-in-out);
	color: $snow-white;
	text-decoration: none;
	padding: 10px 40px 10px 20px;
	width: auto;
	display: inline-block;
	font-family: $good-bold;
	border-radius: 3px;
	font-size: $base-font-size;
	line-height: 1;
	position: relative;
}

.btn--red {
	background-color: $theme-color-02;
	color: $snow-white !important;

	&:hover {
		background-color: $theme-color-02-dark;
	}
}

.btn--gray {
	background-color: #e5e5e5 !important;
	cursor: default !important;
	color: #303030 !important;

	&:after {
		background-image: none !important;
	}
}

.btn--black {
	background-color: #303030;

	&:hover {
		background-color: #000000;
	}
}

.btn--ticket {
	background-color: $theme-color-02;
	background: $theme-color-02;
	margin-bottom: 40px;

	&:after {
		position: absolute;
		content: "";
		display: block;
		background-image: url('../images/ticket.png');
		background-position: 0 0;
		background-repeat: no-repeat;
		right: 20px;
		top: 14px;
		width: 33px;
		height: 33px;
	}

	&:hover {
		background-color: $theme-color-02-dark;
	}

	@include respond-to(medium-and-up) {
		margin-top: 20px;
	}
}

.status--teasing,
.status--afterwards,
.status--non-selling {
	.schedule .btn--red,
	.btn--ticket {
		// @include opacity(60);
		color: #f5a0ab;

		&:hover {
			// @include opacity(100);
			color: $snow-white;
		}
	}
}

.btn--small:after {
	position: absolute;
	content: "";
	display: block;
	background-image: url('../images/arrow-right-mobile-small.png');
	background-position: -8px 4px;
	background-repeat: no-repeat;
	right: 20px;
	top: 10px !important;
	width: 11px;
	height: 17px;
}

.btn--red.arrow:after {
	position: absolute;
	content: "";
	display: block;
	background-image: url('../images/arrow-right-mobile-small.png');
	background-position: -8px 4px;
	background-repeat: no-repeat;
	right: 20px;
	top: 20px;
	width: 11px;
	height: 17px;
}

.btn--extern {
	width: 100%;

	&:after {
		position: absolute;
		content: "";
		display: block;
		background-image: url('../images/extern.png');
		background-position: 0 0;
		background-repeat: no-repeat;
		right: 20px;
		top: 20px;
		width: 22px;
		height: 22px;
	}

	@include respond-to(compact-and-up) {
		width: 49%;
		float: left;
		margin-right: 2%;

		&:last-child {
			margin-right: 0;
		}
	}
}

.btn--delete {
	background: $snow-white;
	text-indent: 9999px;
	width: 50px;
	height: 50px;
	border-radius: 0;
	float: right;
	overflow: hidden;

	&:after {
		position: absolute;
		content: "";
		display: block;
		background-image: url('../images/icon--close-gray.svg');
		background-position: 0 0;
		background-repeat: no-repeat;
		width: 18px;
		height: 18px;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);
	}

	&:hover {
		background-color: $gray-dark;

		&:after {
			background-image: url('../images/icon--close.svg');
		}
	}
}

.btn--config {
	color: $theme-color-01;
	background: $snow-white;
	border: 1px solid $whd-gray-light;
	width: auto;
	padding: 15px 50px 15px 15px;
	border-radius: 3px;

	&:after {
		position: absolute;
		content: "";
		display: block;
		background-image: url('../images/icon--config.svg');
		background-position: 0 0;
		background-repeat: no-repeat;
		right: 15px;
		top: 12px;
		width: 25px;
		height: 25px;
	}

	&:hover {
		background-color: $whd-gray-light;
		color: $theme-color-01 !important;
	}
}

.btn--logout {
	color: $theme-color-01;
	background: $snow-white;
	border: 1px solid $whd-gray-light;
	width: auto;
	padding: 15px 50px 15px 15px;
	border-radius: 3px;

	&:after {
		position: absolute;
		content: "";
		display: block;
		background-image: url('../images/icon--logout.svg');
		background-position: 0 0;
		background-repeat: no-repeat;
		right: 15px;
		top: 12px;
		width: 25px;
		height: 25px;
	}

	&:hover {
		background-color: $whd-gray-light;
		color: $theme-color-01 !important;
	}
}
