// --------------------------------------------------------------------------
// Mixins
// --------------------------------------------------------------------------


// Defaults
// --------------------------------------------------------------------------

$oldie: false !default;

// Fonts
// --------------------------------------------------------------------------

// Font variables
@mixin font-vars($weight: false, $letter-spacing: false, $line-height: false, $size: false) {
	@if ($size != false) { font-size: $size; }
	@if ($weight != false) { font-weight: $weight; }
	@if ($line-height != false) { line-height: $line-height; }
	@if ($letter-spacing != false) { letter-spacing: $letter-spacing; }
}

// Shorthand font stack
@mixin font($fontFamily: $sans-font, $weight: false, $letter-spacing: false, $line-height: false, $size: false) {
	@if $fontFamily != false { font-family: $fontFamily; }
	@include font-vars($weight,$letter-spacing,$line-height,$size);
}

// Sans font stack
@mixin sans-font($weight: false, $letter-spacing: false, $line-height: false, $size: false) {
	font-family: $sans-font;
	@include font-vars($weight,$letter-spacing,$line-height,$size);
}

// Serif font stack
@mixin serif-font($weight: false, $letter-spacing: false, $line-height: false, $size: false) {
	font-family: $serif-font;
	@include font-vars($weight,$letter-spacing,$line-height,$size);
}

// Mono font stack
@mixin monospace-font($weight: false, $letter-spacing: false, $line-height: false, $size: false) {
	font-family: $mono-font;
	@include font-vars($weight,$letter-spacing,$line-height,$size);
}

// Headings
@mixin headings($weight: false, $letter-spacing: false, $line-height: false, $size: false) {
	font-family: $headings;
	@include font-vars($weight,$letter-spacing,$line-height,$size);
}

// Clearfix (http://nicolasgallagher.com/micro-clearfix-hack/)
// --------------------------------------------------------------------------

@mixin clearfix() {
	zoom: 1;
	&:before, &:after {
		display: table;
		content: "";
	}
	&:after {
		clear: both;
	}
}

// IE7 inline-block
// ----------------

@mixin ie7-inline-block() {
  *display: inline; /* IE7 inline-block hack */
  *zoom: 1;
}

// IE7 likes to collapse whitespace on either side of the inline-block elements.
// Ems because we're attempting to match the width of a space character. Left
// version is for form buttons, which typically come after other elements, and
// right version is for icons, which come before. Applying both is ok, but it will
// mean that space between those elements will be .6em (~2 space characters) in IE7,
// instead of the 1 space in other browsers.
@mixin ie7-restore-left-whitespace() {
  *margin-left: .3em;

  &:first-child {
    *margin-left: 0;
  }
}

@mixin ie7-restore-right-whitespace() {
  *margin-right: .3em;
}

// Webkit-style focus
// ------------------
@mixin tab-focus() {
  // Default
  outline: thin dotted #333;
  // Webkit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Button backgrounds
// ------------------

@mixin buttonBackground($startColor, $endColor, $textColor: #fff, $textShadow: 0 -1px 0 rgba(0,0,0,.25)) {
  // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  @include gradientBar($startColor, $endColor, $textColor, $textShadow);
  *background-color: $endColor; /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  @include gradient-reset-filter();

  // in these cases the gradient won't cover the background, so we override
  &:hover, &:active, &.active, &.disabled, &[disabled] {
    color: $textColor;
    background-color: $endColor;
    *background-color: darken($endColor, 5%);
  }

  // IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken($endColor, 10%) \9;
  }
}

// /Hide elements
// --------------------------------------------------------------------------

@mixin hidden($param: hidden) {

	// Hide for both screenreaders and browsers (http://css-discuss.incutio.com/wiki/Screenreader_Visibility)
	@if($param == hidden) {
		display: none !important;
		visibility: hidden;
	}

	// Hide only visually, but have it available for screenreaders (http://www.webaim.org/techniques/css/invisiblecontent/ and http://j.mp/visuallyhidden)
	@if($param == visually) {
		position: absolute !important;
		clip: rect(1px 1px 1px 1px); // IE7 fix
		clip: rect(1px, 1px, 1px, 1px);
	}
}

// Hide text (use for image replacement)
// --------------------------------------------------------------------------

@mixin hide-text($param: h5bp) {

	// New H5BP method (http://j.mp/SjVjgP)
	@if($param == h5bp) {
		background-color: transparent;
		border: 0;
		overflow: hidden;
		@if($oldie) {
			*text-indent: -999em;
		}
		&:before {
			content: "";
			display: block;
			width: 0;
			height: 100%;
		}
	}

	// Scott Kellum method (http://wp.me/p4WtR-2xq)
	@if($param == skm) {
		text-indent: 200%;
		overflow: hidden;
		white-space: nowrap;
	}

}

// Center-align a block level element
// --------------------------------------------------------------------------

@mixin center-block($top: 0, $bottom: 0) {
	display: block;
	margin: $top auto $bottom;
}

// Sizing
// --------------------------------------------------------------------------

@mixin size($height: 5px, $width: 5px) {
	height: $height;
	width: $width;
}

@mixin square($size: 5px) {
	@include size($size, $size);
}

// Text shadow
// --------------------------------------------------------------------------

@mixin text-shadow($x: 1px, $y: 1px, $blur: 2px, $color: $shadow-color-alpha) {
	text-shadow: $x $y $blur $color;
}

// Add alphatransparency to any background or border color
// --------------------------------------------------------------------------

@mixin translucent-background($color: $white, $alpha: 1) {
	background-color: hsla(hue($color), saturation($color), lightness($color), $alpha);
}

@mixin translucent-border($color: $white, $alpha: 1) {
	border-color: hsla(hue($color), saturation($color), lightness($color), $alpha);
	@include background-clip(padding-box);
}

// --------------------------------------------------------------------------
// Prefixed mixins
// --------------------------------------------------------------------------

// (Inset) box-shadow
// --------------------------------------------------------------------------

	// *One* box shadow
@mixin box-shadow($x: 3px, $y: 3px, $blur: 6px, $spread: 0, $color: $shadow-color-alpha) {
	-webkit-box-shadow: $x $y $blur $spread $color;
	   -moz-box-shadow: $x $y $blur $spread $color;
		 ms-box-shadow: $x $y $blur $spread $color;
			box-shadow: $x $y $blur $spread $color;
}

	// Inset box shadow
@mixin inset-box-shadow($x: 3px, $y: 3px, $blur: 6px, $spread: 0, $color: $shadow-color-alpha) {
	-webkit-box-shadow: inset $x $y $blur $spread $color;
	   -moz-box-shadow: inset $x $y $blur $spread $color;
		-ms-box-shadow: inset $x $y $blur $spread $color;
			box-shadow: inset $x $y $blur $spread $color;
}

	// *Multiple* box shadows in one comma seperated set of arguments
	// NOTE: make sure to quote the passed arguments string!
@mixin box-shadows($params) {
	-webkit-box-shadow: #{$params};
	   -moz-box-shadow: #{$params};
		 ms-box-shadow: #{$params};
			box-shadow: #{$params};
}

// Transitions
// --------------------------------------------------------------------------

	// Transition *one* set of arguments
@mixin transition($property: all, $duration: 1s, $easing: ease-in-out, $ms: false) {
	@if($property == none) {
		-webkit-transition: none;
		   -moz-transition: none;
			-ms-transition: none; // IE10?
				transition: none;
	}
	@else {
		-webkit-transition: $property $duration $easing;
		   -moz-transition: $property $duration $easing;
			-ms-transition: $property $duration $easing; // IE10?
				transition: $property $duration $easing;
	}
}

	// Transition *multiple* sets of arguments
	// NOTE: make sure to quote the passed arguments string!
@mixin transitions($params) {
	-webkit-transition: #{$params};
	   -moz-transition: #{$params};
		-ms-transition: #{$params}; // IE10?
			transition: #{$params};
}

// Transform
// --------------------------------------------------------------------------
// values: translate(x,y) | translate3d(x,y,z) | scale(x,y) | scale3d(x,y,z) | rotate(angle) | rotate3d(x,y,z,angle) | skew(x-angle,y-angle) | perspective(n)
// note:   The transform property applies a 2D or 3D transformation to an element. This property allows you to rotate, scale, move, skew, etc., elements.

@mixin transform($params) {
	-webkit-transform: $params;
	   -moz-transform: $params; // 2D transforms only
		-ms-transform: $params; // 2D transforms only
			transform: $params;
}

// Transform Origin
// --------------------------------------------------------------------------
// values:  50% 50% (default) | 0 0 | 0% 100% | 20px 10px | -10em 5em

@mixin set-transform-origin($origin) {
	-webkit-transform-origin: $origin;
	   -moz-transform-origin: $origin;
		-ms-transform-origin: $origin;
			transform-origin: $origin;
}

// Rotate
@mixin rotate($deg: 10deg) {
	-webkit-transform: rotate($deg);
	   -moz-transform: rotate($deg);
		-ms-transform: rotate($deg);
			transform: rotate($deg);
}

// Skew
@mixin skew($deg: 10deg) {
	-webkit-transform: rotate($deg);
	   -moz-transform: rotate($deg);
		-ms-transform: rotate($deg);
			transform: rotate($deg);
}

// Scale
@mixin scale($x: 2, $y: false) {
	@if ($y) {
		-webkit-transform: scale($x, $y);
		   -moz-transform: scale($x, $y);
			-ms-transform: scale($x, $y);
				transform: scale($x, $y);
    } @else {
		-webkit-transform: scale($x);
		   -moz-transform: scale($x);
			-ms-transform: scale($x);
				transform: scale($x);
    }
}

// Scale + Rotate
@mixin scale-rotate($mod, $deg) {
	-webkit-transform: scale($mod) rotate($deg);
	   -moz-transform: scale($mod) rotate($deg);
		-ms-transform: scale($mod) rotate($deg);
			transform: scale($mod) rotate($deg);
}

// Backface visibility (http://bit.ly/M6v9sM)
// --------------------------------------------------------------------------

@mixin backface-visibility() {
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
			backface-visibility: hidden;
}

// Resize
// --------------------------------------------------------------------------
// values: none (default) | both | horizontal | vertical
// note:   Applies to elements with 'overflow' other than visible.

@mixin resize($direction: horizontal) {
	-webkit-resize: $direction;
	   -moz-resize: $direction;
			resize: $direction;
}

// --------------------------------------------------------------------------
// Background origin
//
// values: padding-box (default) | border-box | content-box
// note:   If the background-attachment property for the background image is "fixed", this property has no effect.

@mixin background-origin($origin) {
	-webkit-background-origin: $origin;
	   -moz-background-origin: $origin;
			background-origin: $origin;
}

// Background clipping
// --------------------------------------------------------------------------
// values: border-box (default) | padding-box | content-box
// note:      The background-clip property specifies the painting area of the background.

@mixin background-clip($clip) {
	-webkit-background-clip: $clip;
	   -moz-background-clip: $clip;
			background-clip: $clip;
}

// Background origin + clipping
// --------------------------------------------------------------------------

@mixin background-origin-clip($origin, $clip) {
	-webkit-background-origin: $origin;
	   -moz-background-origin: $origin;
			background-origin: $origin;
	  -webkit-background-clip: $clip;
		 -moz-background-clip: $clip;
			  background-clip: $clip;
}

// Background size
// --------------------------------------------------------------------------
// values: auto{1,2} (default) | [length] | [percentage] | cover | contain

@mixin background-size($values: auto) {
	-webkit-background-size: $values;
	   -moz-background-size: $values;
		 -o-background-size: $values;
			background-size: $values;
}

// CSS Animations + Keyframes
// --------------------------------------------------------------------------
// note: Always set animation and keyframes with the same animation name.
//
// animation values:
//   (1) animation-name
//   (2) duration (in seconds (e.g. 3s); negetive values not allowed)
//   (3) [optional] timing-function (defaults to 'ease'; values can be: ease-out | ease-in | ease-in-out | linear | step-start [see: http://www.css3files.com/animation/#val_gen])
//   (4) [optional] delay (defaults to '0'; if negative it will begin part-way through its play cycle)
//   (5) [optional] iteration-count (defaults to '1'; set to 'infinite' to run forever; decimal values cause it to end in between its play cycle)
//   (6) [optional] direction (defaults to 'normal'; set to 'alternate' to play every second iteration of the animation back to front)
//   (7) [optional] fill mode (defaults to 'none'; values can be: forwards [prevents the animation from restarting] | backwards | both)
//
//   IMPORTANT NOTE: when passing two sets of animation arguments, make sure to quote the passed string (see usage example 3)!
//
// keyframes values:
//   (1) set on one site the placement of the keyframe in animation: 0% (or 'from'), 100% (or 'to'), 33%, 60%, etc.
//   (2) set on the other side one or more CSS properties and values that define the state of the animation at this point
//
// usage 1:
//   .animate-me { @include animation(slideout 2s ease-out .5s forwards); }
//   @include keyframes(slideout) {
//     to { margin-left: -15em; }
//   }
//
// usage 2:
//   .animate-me { @include animation(fadeout 2s ease-in 1s infinite); }
//   @include keyframes(fadeout) {
//     0% { opacity: 1; }
//     100% { opacity: 0; }
//   }
//
// usage 3:
//   .animate-me { @include animation("change-color 3s infinite,change-size 2s ease-in 1s infinite"); }
//   @include keyframes(change-color) {
//     0% {
//       background-color: red;
//       border-color: black;
//     }
//     100% {
//       background-color: orange;
//       border-color: yellow;
//     }
//   }
//   @include keyframes(change-size) {
//     0% { @include scale(1); }
//     65% { @include scale(1.2); }
//     100% { @include scale(1.4); }
//   }
//
// For more complex examples see: http://www.css3files.com/animation/

@mixin animation($params){
    -webkit-animation: #{$params};
       -moz-animation: #{$params};
        -ms-animation: #{$params};
         -o-animation: #{$params};
            animation: #{$params};
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes $animation-name{ @content; }
   	   @-moz-keyframes $animation-name{ @content; }
    	@-ms-keyframes $animation-name{ @content; }
    	 @-o-keyframes $animation-name{ @content; }
    		@keyframes $animation-name{ @content; }
}

// Columns
// --------------------------------------------------------------------------
// columnCount values: auto (default) | [number]
// columnWidth values: auto (default) | [lenght]
// columnGap values:   normal (default) | [lenght]

@mixin columns($columnCount, $column-width, $columnGap: 1em) {
	-webkit-column-count: $columnCount;
	   -moz-column-count: $columnCount;
			column-count: $columnCount;
	-webkit-column-width: $column-width;
	   -moz-column-width: $column-width;
			column-width: $column-width;
	  -webkit-column-gap: $columnGap;
		 -moz-column-gap: $columnGap;
			  column-gap: $columnGap;
}

// Column rules
// --------------------------------------------------------------------------
// note: The column-rule property sets the width, style, and color of the rule between columns.

@mixin column-rule($columnRuleColor: $gray, $columnRuleStyle: solid, $columnRuleWidth: 1px) {
	-webkit-column-rule-color: $columnRuleColor;
	   -moz-column-rule-color: $columnRuleColor;
			column-rule-color: $columnRuleColor;
	-webkit-column-rule-style: $columnRuleStyle;
	   -moz-column-rule-style: $columnRuleStyle;
			column-rule-style: $columnRuleStyle;
	-webkit-column-rule-width: $columnRuleWidth;
	   -moz-column-rule-width: $columnRuleWidth;
			column-rule-width: $columnRuleWidth;
}

// Column break
// --------------------------------------------------------------------------

@mixin column-break($breakPoint: before) {
	@if ($breakPoint == after) {
		-webkit-column-break-after: always;
		   -moz-column-break-after: always;
					   break-after: always;
	}
	@else {
		-webkit-column-break-before: always;
		   -moz-column-break-before: always;
					   break-before: always;
	}
}

// Box display
// --------------------------------------------------------------------------
// boxOrient values: inline-axis (default) | horizontal | vertical | block-axis | inherit
// boxPack values:   start (default) | end | center | justify
// baxAlign values:  stretch (default) | start | end | center | baseline

@mixin box($boxOrient: horizontal, $boxPack: center, $boxAlign: center) {
				   display: -webkit-box;
				   display: -moz-box;
				   display: box;
		-webkit-box-orient: $boxOrient;
		   -moz-box-orient: $boxOrient;
			-ms-box-orient: $boxOrient;
				box-orient: $boxOrient;
		  -webkit-box-pack: $boxPack;
			 -moz-box-pack: $boxPack;
			  -ms-box-pack: $boxPack;
				  box-pack: $boxPack;
		 -webkit-box-align: $boxAlign;
			-moz-box-align: $boxAlign;
			 -ms-box-align: $boxAlign;
				 box-align: $boxAlign;
}

// Flex box
// --------------------------------------------------------------------------
// values: flex | inline-flex
// note:   New annotation as of August 2012:; http://css-tricks.com/old-flexbox-and-new-flexbox/

@mixin flex-box($inline: false) {
	@if($inline == inline) {
		display: -webkit-inline-flex;
		display: -moz-inline-flex;
		display: -ms-inline-flex;
		display: inline-flex;
	} @else {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: flex;
	}
}


// Flex direction
// --------------------------------------------------------------------------
// values: row (default) | row-reverse | column | column-reverse

@mixin flex($direction: column) {
	-webkit-flex-direction: $direction;
	   -moz-flex-direction: $direction;
	    -ms-flex-direction: $direction;
			flex-direction: $direction;
}

// Flex flexiblity
// --------------------------------------------------------------------------
// values: 0 (default) | [value]
// note:   The flexibility of the element — all flex is relative, e.g. a child with a box-flex of 2 is twice as flexible as a child with a box-flex of 1.

@mixin flex($val: 1) {
	-webkit-flex: $val;
	   -moz-flex: $val;
	    -ms-flex: $val;
			flex: $val;
}

// Box sizing
// --------------------------------------------------------------------------
// values: content-box (default) | border-box | inherit
// note:   The box-sizing property allows you to define certain elements to fit an area in a certain way.

@mixin box-sizing($type: content-box) {
	-webkit-box-sizing: $type;
	   -moz-box-sizing: $type;
			box-sizing: $type;
}

// Gradients
// --------------------------------------------------------------------------

@mixin gradient($direction: horizontal, $startColor: $gray-lighter, $endColor: $gray, $deg: 45deg, $image: '') {
	@if($direction == horizontal) {
		background-color: $endColor;
		background-repeat: repeat-x;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $startColor), color-stop(100%, $endColor)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(left, $startColor, $endColor); // Safari 5.1+, Chrome 10+
		background-image: -moz-linear-gradient(left, $startColor, $endColor); // FF 3.6+
		background-image: -ms-linear-gradient(left, $startColor, $endColor); // IE10
		background-image: linear-gradient(left, $startColor, $endColor); // Le standard
//            -ms-filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#{$startColor}', endColorstr='#{$endColor}', GradientType=1); // IE8/9
//                filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#{$startColor}', endColorstr='#{$endColor}', GradientType=1); // IE7 and down
	}
	@else if($direction == vertical) {
		background-color: $endColor;
		background-repeat: repeat-x;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $startColor), color-stop(100%, $endColor)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
		background-image: -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
		background-image: -ms-linear-gradient(top, $startColor, $endColor); // IE10
		background-image: linear-gradient(top, $startColor, $endColor); // The standard
//            -ms-filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#{$startColor}', endColorstr='#{$endColor}', GradientType=0); // IE8/9
//                filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#{$startColor}', endColorstr='#{$endColor}', GradientType=0); // IE7 and down
	}
	@else if($direction == combine-vertical) {
		background-color: $endColor;
		background-repeat: repeat-x;
		background-image: $image, -webkit-gradient(linear, left top, left bottom, color-stop(0%, $startColor), color-stop(100%, $endColor)); // Safari 4+, Chrome 2+
		background-image: $image, -webkit-linear-gradient(top, $startColor, $endColor); // Safari 5.1+, Chrome 10+
		background-image: $image, -moz-linear-gradient(top, $startColor, $endColor); // FF 3.6+
		background-image: $image, -ms-linear-gradient(top, $startColor, $endColor); // IE10
		background-image: $image, linear-gradient(top, $startColor, $endColor); // The standard
	}
	@else if($direction == directional) {
		background-color: $endColor;
		background-repeat: repeat-x;
		background-image: -webkit-linear-gradient($deg, $startColor, $endColor); // Safari 5.1+, Chrome 10+
		background-image: -moz-linear-gradient($deg, $startColor, $endColor); // FF 3.6+
		background-image: -ms-linear-gradient($deg, $startColor, $endColor); // IE10
		background-image: linear-gradient($deg, $startColor, $endColor); // The standard
	}
}

// Gradient Bar Colors for buttons and alerts
@mixin gradientBar($primaryColor, $secondaryColor, $textColor: #fff, $textShadow: 0 -1px 0 rgba(0,0,0,.25)) {
  color: $textColor;
  text-shadow: $textShadow;
  @include gradient('vertical', $primaryColor, $secondaryColor);
  border-color: $secondaryColor $secondaryColor darken($secondaryColor, 15%);
  border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fade-in(rgba(0,0,0,.1), 0.15);
}

// Reset filters for IE
@mixin gradient-reset-filter() {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

// Striped gradients
// --------------------------------------------------------------------------

@mixin stripes($direction: horizontal, $size: 100%, $firstColor: $white, $secondColor: $gray, $firstColorStop: 50%, $secondColorStop: 50%) {
	@if($direction == horizontal) {
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop($firstColorStop, $firstColor), color-stop($secondColorStop, $secondColor)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient($firstColor $firstColorStop, $secondColor $secondColorStop); // Safari 5.1+, Chrome 10+
		background-image: -moz-linear-gradient($firstColor $firstColorStop, $secondColor $secondColorStop); // FF 3.6+
		background-image: -ms-linear-gradient($firstColor $firstColorStop, $secondColor $secondColorStop); // IE10
		background-image: linear-gradient($firstColor $firstColorStop, $secondColor $secondColorStop);
	}
	@else if($direction == vertical) {
		background-image: -webkit-gradient(linear, left top, right top, color-stop($firstColorStop, $firstColor), color-stop($secondColorStop, $secondColor)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(0, $firstColor $firstColorStop, $secondColor $secondColorStop); // Safari 5.1+, Chrome 10+
		background-image: -moz-linear-gradient(0, $firstColor $firstColorStop, $secondColor $secondColorStop); // FF 3.6+
		background-image: -ms-linear-gradient(0, $firstColor $firstColorStop, $secondColor $secondColorStop); // IE10
		background-image: linear-gradient(0, $firstColor $firstColorStop, $secondColor $secondColorStop);
	}
	background-size: $size $size;
}

// Opacity
// --------------------------------------------------------------------------

@mixin opacity($opacity: 100) {
	  -moz-opacity: $opacity / 100;
		   opacity: $opacity / 100;
//      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity='#{$opacity}')"; // IE8/9
//          filter: alpha(opacity='#{$opacity}'); // IE7 and down
}

// Hyphenation (see: http://bit.ly/JixVfL)
// --------------------------------------------------------------------------

@mixin hyphenate($hyphen: true) {
	@if $hyphen == true {
		 // -ms-word-break: break-all; // IE8
			 // word-break: break-all; // Chrome
			 // word-break: break-word; // Non standard for webkit
		-webkit-hyphens: auto; // Safari 5.1+, iOS 4.2+, Future Chrome Versions (?!)
		   -moz-hyphens: auto; // FF6+ (English), FF8+
			-ms-hyphens: auto; // IE10+
				hyphens: auto;
		-webkit-locale: auto;
	} @else if($hyphen == false) {
		 // -ms-word-break: normal; // IE8
			 // word-break: normal; // Chrome, Non standard for webkit
		-webkit-hyphens: none; // Safari 5.1+, iOS 4.2+, Future Chrome Versions (?!)
		   -moz-hyphens: none; // FF6+ (English), FF8+
			-ms-hyphens: none; // IE10+
				hyphens: none;
	}
}

// Input placeholder text
// --------------------------------------------------------------------------
// Example:;
// @include input-placeholder {
//   color: #bfbfbf;
//   font-style: italic;
// }

@mixin input-placeholder {
	&::-webkit-input-placeholder { @content; }
		 &:-ms-input-placeholder { @content; } // IE10+
			  &:-moz-placeholder { @content; }
				   &:placeholder { @content; }
}

// Image 2x (HiDPI, Retina, Hi-res)
// --------------------------------------------------------------------------
// Specify a minimum Webkit device pixel ratio of 1.25 instead of 1.5, to serve
// 2x images to Android devices that are between 1 and 1.5x (like the Nexus 7).
// Firefox and Opera will respond to 1.5x on these devices, but Chrome will not.
// Specify min-resolution, which covers Firefox 19. Opera on Android also supports
// min-resolution, but Opera Mini does not support dppx, so the dpi unit is used.
//
// Link: http://j.mp/T9h80Z
//
// Usage:
//   .logo {
//     @include image-2x('logo-2x.png',100px,25px);
//     background: img-url('logo.png') no-repeat;
//   }

@mixin image-2x($image, $width, $height) {
	@media (-webkit-min-device-pixel-ratio: 1.25),
				(-o-min-device-pixel-ratio: 5/4),
						   (min-resolution: 120dpi) {
		background-image: img-url($image);
		background-size: $width $height;
	}
}
// Calculate fluid ratio based on two dimensions (width/height) */
// --------------------------------------------------------------------------

@mixin fluid-ratio($large-size, $small-size) {
	$width-large: nth($large-size, 1);
	$width-small: nth($small-size, 1);
	$height-large: nth($large-size, 2);
	$height-small: nth($small-size, 2);
	$slope: ($height-large - $height-small) / ($width-large - $width-small);
	$height: $height-small - $width-small * $slope;

	padding-top: $slope * 100%;
	height: $height;

	background-size: cover;
	-moz-background-size: cover;  /* Firefox 3.6 */
	background-position: center;  /* Internet Explorer 7/8 */
}

// Debug description
// --------------------------------------------------------------------------
// Show some help text :after an element, used in _debug.scss
// value: text (the description)

@mixin debug-text($text) {
	&:after{
		content: $text;
		position: absolute;
		font-size: $small;
		background: rgba(255,255,255,0.8);
		border: 1px solid $gray;
		color: $gray;
		margin-top: 1.6em;
	}
}

@mixin no-debug-text() {
	&:after{
		content: "";
	}
}
