// --------------------------------------------------------------------------
// Modular Scale (https://github.com/scottkellum/modular-scale)
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// Defaults

$base-font-size:                16px !default;
$property:                      font-size !default;
$round-pixels:                  true !default;
$class-slug:                    ms !default;
$columns:                       12 !default;
$ratio:                         1.618 !default;


// --------------------------------------------------------------------------
// Modular scale functions

@function modular-scale($multiple, $base-font-size, $ratio) {
	// return the $base-font-size if $multiple is zero
	@if $multiple == 0 {
		@if type-of($base-font-size) == "list" {
			$base-font-size: sort-list($base-font-size);
			@return nth($base-font-size, 1); }
		// return just the simple $base-font-size value if it's not a list
		@return $base-font-size; }

	// if multiple base-sizes are passed in as a list
	// and multiple ratios are passed in as a list
	// calculate values in using each base-size / ratio combination
	@if type-of($base-font-size) == "list" and type-of($ratio) == "list" {
		@if unit(ms-multibase-multiratio($multiple, $base-font-size, $ratio)) == 'px' and $round-pixels == true {
			@return round(ms-multibase-multiratio($multiple, $base-font-size, $ratio)); }
		@return ms-multibase-multiratio($multiple, $base-font-size, $ratio); }

	// if multiple base-sizes are passed in as a list
	// calculate values in using each base-size
	@if type-of($base-font-size) == "list" and type-of($ratio) == "number" {
    @if unit(ms-multibase($multiple, $base-font-size, $ratio)) == 'px' and $round-pixels == true {
      @return round(ms-multibase($multiple, $base-font-size, $ratio)); }
    @return ms-multibase($multiple, $base-font-size, $ratio); }

	// if multiple ratios are passed in as a list
	// calculate values in using each ratio
	@if type-of($base-font-size) == "number" and type-of($ratio) == "list" {
    @if unit(ms-multiratio($multiple, $base-font-size, $ratio)) == 'px' and $round-pixels == true {
      @return round(ms-multiratio($multiple, $base-font-size, $ratio)); }
    @return ms-multiratio($multiple, $base-font-size, $ratio); }

	// If there are no lists just run the simple function
  @if unit(exponent($ratio, $multiple) * $base-font-size) == 'px' and $round-pixels == true {
    @return round(exponent($ratio, $multiple) * $base-font-size); }
  @return exponent($ratio, $multiple) * $base-font-size; }

// calculate values in using each base-size / ratio combination
@function ms-multibase-multiratio($multiple, $base-font-size, $ratio) {
	// start with an empty list to place all values in
	$scale-values: ();
	// make sure base sizes are in ascending order
	$base-font-size: sort-list($base-font-size);
	// take each base-size in turn
	$k: 1;
	@while $k <= length($base-font-size) {
		// add each $base-font-size to the list except the first
		@if $k > 1 {
			$scale-values: append($scale-values, nth($base-font-size, $k)); }
		// take each ratio in turn
		$j: 1;
		@while $j <= length($ratio) {
			// reset $modular-scale for each set
			$modular-scale: nth($base-font-size, $k);
			// do the scale for each base-size using this ratio
			@if $multiple > 0 {
				// up $multiple times
				// and add the result to $scale-values
				@for $i from 1 through $multiple {
					$modular-scale: exponent(nth($ratio, $j), $i) * nth($base-font-size, $k);
					$scale-values: append($scale-values, $modular-scale); }
				// and down until the value is lower than the lowest $base-font-size
				// and add the result to $scale-values
				$i: -1;
				$modular-scale: nth($base-font-size, $k);
				@while $modular-scale >= nth($base-font-size, 1) {
					$modular-scale: exponent(nth($ratio, $j), $i) * nth($base-font-size, $k);
					$scale-values: append($scale-values, $modular-scale);
					$i: $i - 1; } }
			@if $multiple < 0 {
				// do the scale down for each set to below 1px
				$i: 0;
				$modular-scale: nth($base-font-size, $k);
				@while $modular-scale > 1 {
					$modular-scale: exponent(nth($ratio, $j), $i) * nth($base-font-size, $k);
					$scale-values: append($scale-values, $modular-scale);
					$i: $i - 1; } }
			$j: $j + 1; }
		$k: $k + 1; }
	// return trimmed and sorted final list
	@return trim-sort($multiple, $scale-values, $base-font-size); }

// calculate values in using each base-size
@function ms-multibase($multiple, $base-font-size, $ratio) {
	// start with an empty list to place all values in
	$scale-values: ();
	// make sure base sizes are in ascending order
	$base-font-size: sort-list($base-font-size);
	// take each base-size in turn
	$k: 1;
	@while $k <= length($base-font-size) {
		// add each $base-font-size to the list except the first
		@if $k > 1 {
			$scale-values: append($scale-values, nth($base-font-size, $k)); }
		// reset $modular-scale for each set
		$modular-scale: nth($base-font-size, $k);
		// do the scale for each base-size using this ratio
		@if $multiple > 0 {
			// up $multiple times
			// and add the result to $scale-values
			@for $i from 1 through $multiple {
				$modular-scale: exponent($ratio, $i) * nth($base-font-size, $k);
				$scale-values: append($scale-values, $modular-scale); }
			// and down until the value is lower than the lowest $base-font-size
			// and add the result to $scale-values
			$i: -1;
			$modular-scale: nth($base-font-size, $k);
			@while $modular-scale >= nth($base-font-size, 1) {
				$modular-scale: exponent($ratio, $i) * nth($base-font-size, $k);
				$scale-values: append($scale-values, $modular-scale);
				$i: $i - 1; } }
		@if $multiple < 0 {
			// do the scale down for each set to below 1px
			$i: 0;
			$modular-scale: nth($base-font-size, $k);
			@while $modular-scale > 1 {
				$modular-scale: exponent($ratio, $i) * nth($base-font-size, $k);
				$scale-values: append($scale-values, $modular-scale);
				$i: $i - 1; } }
		$k: $k + 1; }
	// return trimmed and sorted final list
	@return trim-sort($multiple, $scale-values, $base-font-size); }

// calculate values in using each ratio
@function ms-multiratio($multiple, $base-font-size, $ratio) {
	// start with an empty list to place all values in
	$scale-values: ();
	// If $multiple is a positive integer (up the scale)
	@if $multiple > 0 {
		// take each ratio in turn
		$j: 1;
		@while $j <= length($ratio) {
			// reset $modular-scale for each set
			$modular-scale: $base-font-size;
			// do the scale using this ratio thru the multiple, and add the result to $scale-values
			@for $i from 1 through $multiple {
				$modular-scale: exponent(nth($ratio, $j), $i) * $base-font-size;
				$scale-values: append($scale-values, $modular-scale); }
			$j: $j + 1; }
		// sort acsending
		$scale-values: sort-list($scale-values);
		// return the final value using the laced list
		@return nth($scale-values, $multiple); }
	// If $multiple is a negative integer (down the scale)
	@if $multiple < 0 {
		// take each ratio in turn
		$j: 1;
		@while $j <= length($ratio) {
			// reset $modular-scale for each set
			$modular-scale: $base-font-size;
			// do the scale using this ratio thru the multiple, and add the result to $scale-values
			@for $i from 1 through $multiple * -1 {
				$modular-scale: exponent(nth($ratio, $j), -$i) * $base-font-size;
				$scale-values: append($scale-values, $modular-scale); }
			$j: $j + 1; }
		// sort decending
		$scale-values: sort-list($scale-values, "dec");
		// return the final value using the laced list
		@return nth($scale-values, $multiple * -1); } }

// trim and sort the final list
@function trim-sort($multiple, $scale-values, $base-font-size) {
	@if $multiple > 0 {
		// trim list so we can count from the lowest $base-font-size
		$scale-values: trim-list($scale-values, nth($base-font-size, 1));
		// sort acsending
		$scale-values: sort-list($scale-values);
		// return the final value using the laced list
		@return nth($scale-values, $multiple); }
	@else {
		// trim list so we can count from the lowest $base-font-size
		$scale-values: trim-list($scale-values, nth($base-font-size, 1), "dec");
		// sort acsending
		$scale-values: sort-list($scale-values, "dec");
		// return the final value using the laced list
		@return nth($scale-values, -$multiple); } }


// --------------------------------------------------------------------------
// Sass exponent support

@function exponent($base, $exponent) {
	// reset value
	$value: $base;
	// positive intergers get multiplied
	@if $exponent > 1 {
		@for $i from 2 through $exponent {
			$value: $value * $base; } }
	// negitive intergers get divided. A number divided by itself is 1
	@if $exponent < 1 {
		@for $i from 0 through -$exponent {
			$value: $value / $base; } }
	// return the last value written
	@return $value; }


// --------------------------------------------------------------------------
// Sass list sorting support

@function sort-list($list, $dir: "asc") {
	// built-in list sorting in Sass would make this go away.
	// declare some empty lists to put our new order and temporary values
	$new-order: ();
	$temp: ();
	// fill $temp with the contents of $list
	$temp: join($temp, $list);
	// if sorting ascending
	@if $dir == "asc" {
		// loop through all values in $list
		@for $i from 1 through length($list) {
			// impossibly high starter value to compare
			$low: 1000000;
			// check for lowest value in $temp
			@for $j from 1 through length($temp) {
				@if nth($temp, $j) < $low {
					$low: nth($temp, $j); } }
			// add lowest value to $new-order
			$new-order: append($new-order, $low);
			// empty $temp for the next comparison
			$temp: ();
			// re-populate $temp with remaining values to sort
			@for $k from 1 through length($list) {
				@if nth($list, $k) > $low {
					$temp: append($temp, nth($list, $k)); } } } }
	@if $dir == "dec" {
		// loop through all values in $list
		@for $i from 1 through length($list) {
			// 0 starter value
			$high: 0;
			// check for highest value in $temp
			@for $j from 1 through length($temp) {
				@if nth($temp, $j) > $high {
					$high: nth($temp, $j); } }
			$new-order: append($new-order, $high);
			// empty $temp for the next comparison
			$temp: ();
			// re-populate $temp with remaining values to sort
			@for $k from 1 through length($list) {
				@if nth($list, $k) < $high {
					$temp: append($temp, nth($list, $k)); } } } }
	@return $new-order; }


// --------------------------------------------------------------------------
// Sass list trimming support

@function trim-list($list, $start, $dir: "asc") {
	// built-in list trimming in Sass would make this go away.
	// declare some empty lists to put our trimmed values
	$trimmed: ();
	// if sorting ascending
	@if $dir == "asc" {
		// loop through all values in $list
		@for $i from 1 through length($list) {
			@if nth($list, $i) >= $start {
				$trimmed: append($trimmed, nth($list, $i)); } } }
	@if $dir == "dec" {
		// loop through all values in $list
		@for $i from 1 through length($list) {
			@if nth($list, $i) <= $start {
				$trimmed: append($trimmed, nth($list, $i)); } } }
	@return $trimmed; }


// --------------------------------------------------------------------------
// ratio.scss

@function ratio($ratio, $steps, $start: 'small') {
  $x: 1;
  $return: ();
  @for $i from 0 through $steps - 1 {
    $xr: $x * exponent($ratio, $i);
    $return: append($return, $xr, comma); }

  @if $start == 'small' and $ratio < 1 {
    $return: reverse($return); }
  @else if $start == 'large' and $ratio > 1 {
    $return: reverse($return); }
  @return $return; }


// --------------------------------------------------------------------------
// Modular scale shortcut, mixin and classes mixin

@function ms($multiple, $font-size: $base-font-size, $ratio: $ratio) {
	// Return the value from the Modular Scale function
	@return modular-scale($multiple, $font-size, $ratio); }

@mixin modular-scale($property, $multiple, $font-size: $base-font-size, $ratio: $ratio) {
	// Print the $property and return the value from the Modular Scale function
	#{$property}: modular-scale($multiple, $font-size, $ratio); }

@mixin modular-scale-classes($multiple, $property, $class-slug: $class-slug, $font-size: $base-font-size, $ratio: $ratio) {
	// Loop from 0 through the value of $multiple and generate a range of classes
	@for $i from 0 through $multiple {
		.#{$class-slug}-#{$i} {
			@include modular-scale($property, $i, $font-size, $ratio); } } }


// --------------------------------------------------------------------------
// Column (grid) helpers

// Calculate the total sum of a list (context)
@function list-sum($list) {
  // zero out the initial sum
  $sum: 0;
  // loop through each value in the list adding it to $list-sum
  @for $i from 1 through length($list) {
    $sum: $sum + nth(nth($list, $i), 1); }
  @return nth($sum, 1); }

// Find column count
@function column-count($columns) {
  @if type-of($columns) == number {
    @return $columns; }
  @if type-of($columns) == list {
    @return length($columns); } }

@function repeat($count, $repeat) {
  $list: $repeat;
  @for $i from 2 through $count {
    $list: join($repeat, $list); }
  @return $list; }

// Reverses direction of a list
@function reverse($list) {
  $length: length($list);
  $return: ();
  @for $i from 0 to $length {
    $return: append($return, nth($list, $length - $i), comma); }
  @return $return; }

// Column sum
@function column-sum($columns: $columns) {
  @if type-of($columns) == 'number' {
    @return $columns; }
  @else if type-of($columns) == 'list' {
    $sum: 0;
    @each $column in $columns {
      $sum: $sum + nth($column, 1); }
    @return $sum; } }


// --------------------------------------------------------------------------
// Compound function

@function compound($c1: 1, $c2: 1, $c3: 1, $c4: 1, $c5: 1, $c6: 1) {
  $common-multiple: $c1 * $c2 * $c3 * $c4 * $c5 * $c6;
  $compound-grid: ();
  $compound-counter: 1;
  @for $i from 1 through $common-multiple {
    $add-col: false;
    @if $c1 != 1 {
      @if $i / $c1 == round($i / $c1) {
        $add-col: true; } }
    @if $c2 != 1 {
      @if $i / $c2 == round($i / $c2) {
        $add-col: true; } }
    @if $c3 != 1 {
      @if $i / $c3 == round($i / $c3) {
        $add-col: true; } }
    @if $c4 != 1 {
      @if $i / $c4 == round($i / $c4) {
        $add-col: true; } }
    @if $c5 != 1 {
      @if $i / $c5 == round($i / $c5) {
        $add-col: true; } }
    @if $c6 != 1 {
      @if $i / $c6 == round($i / $c6) {
        $add-col: true; } }
    @if $add-col {
      $compound-grid: join($compound-grid, $compound-counter, comma);
      $compound-counter: 1; }
    @else {
      $compound-counter: $compound-counter + 1; } }
  @return $compound-grid; }


// --------------------------------------------------------------------------
// Column function

// Calculate the width spanning multiple columns
@function column-span($span, $location: 1, $columns: $columns) {
  $columns-sum: column-sum($columns);
  // Equal width columns
  @if type-of($columns) == number {
    $span: $span;
    @return $span / $columns-sum * 100%; }
  // Variable width columns
  @if type-of($columns) == list {
      // zero out initial sum
      $sum: 0;
      $holder: ();
      // from start point to end point
      @for $i from $location to $location + $span {
        $holder: append($holder, nth(nth($columns, $i), 1), comma); }
      @return column-sum($holder) / $columns-sum * 100%; }
  @return 10%; }

@function grid-span($span, $location: 1, $columns: $columns) {
  @return column-span($span, $location, $columns); }
