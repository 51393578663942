@charset "UTF-8";
/*!-----------------------------------------------------------------------------------

	Copyright 2013 (C) 12HOOG Webspecialisten
	Made by 12HOOG Webspecialisten, ALL RIGHTS RESERVED
	Design & realisation by 12HOOG Webspecialisten | Jasper Rooduijn & Bart van der Waerden
	Handcoded with help of the 12HOOG Theme Framework and WordPress
	E-mail  : info@12hoog.nl
	Website : http://www.12hoog.nl

	File: 12HOOG Theme Framework main SCSS file

-----------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:700|Open+Sans");
/* orange */
/* olive green */
.toggle {
  cursor: pointer; }

.is-collapsed {
  display: none; }

.is-expanded {
  display: block; }

tbody.is-expanded {
  display: table-row-group; }

/**
 * Gridset
 *
 * This are grid related 'extend only' selectors
 * For more information about these selectors see:
 * - http://j.mp/UvgHgT and
 * - http://amperedesign.com/?p=171
 */
/* Clearfix (http://nicolasgallagher.com/micro-clearfix-hack/) */
#travel-planner {
  zoom: 1; }
  #travel-planner:before, #travel-planner:after {
    display: table;
    content: ""; }
  #travel-planner:after {
    clear: both; }

/**
* Layout
*
* Grid structure and layout per breakpoint (project specific)
*/
/**
 *Sizes in human readable format
 */
/* Whole */
/* Halves */
/* Thirds */
/* Quarters */
/* Fifths */
/* Sixths */
/* Eighths */
/* Tenths */
/* Twelfths */
/**
 * Sizes based on modular scales
 */
/* Golden 2 column set */
/* Golden 3 column set */
/* Golden 4 column set */
/* Compound 4 column set */
/* Asymmetric 4 column set */
/**
 * Normalize
 *
 * Normalize styles, mostly picked from HTML5 Boilerplate (http://html5boilerplate.com/)
 * and Normalize.css (http://necolas.github.com/normalize.css/)
 */
/**
 * 1. Force vertical scrollbar in non-IE (keeps page centred in all browsers regardless of content height)
 * 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: http://bit.ly/JL2rhv
 */
html {
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

/* Reset browser body margins */
body {
  margin: 0; }

/* HTML5 display definitions */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

/* Corrects inline-block display not defined in IE9 & FF3 */
audio,
canvas,
video {
  display: inline-block; }

/* Prevents modern browsers from displaying 'audio' without control */
audio:not([controls]) {
  display: none;
  height: 0; }

/* Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4 */
[hidden] {
  display: none; }

/* Headings
   ========================================================================== */
/* Resets all headings to look like normal text */
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0; }

/* Block Text
   ========================================================================== */
p {
  margin: 0; }

blockquote {
  margin: 0; }

/* Improve readability of pre-formatted text in all browsers */
pre {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/* Inline Text
   ========================================================================== */
cite {
  font-style: normal; }

ins {
  text-decoration: none; }

dfn {
  font-style: inherit; }

del {
  text-decoration: none; }

mark {
  background: none;
  color: inherit; }

address {
  font-style: normal; }

code,
kbd,
samp {
  font-family: inherit;
  font-size: inherit; }

b,
strong {
  font-weight: inherit; }

em {
  font-style: inherit; }

small {
  font-size: 100%; }

/* Addresses quote property not supported in S4 */
q:before,
q:after {
  content: "";
  content: none; }

/* Set sub, sup without affecting line-height: gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/**
 * 1) Move the subscripted text down, but only half as far down as the superscript moved up
 * 2) Move the superscripted text up
 */
sub {
  bottom: -.2em; }

sup {
  top: -.4em; }

/* Lists
   ========================================================================== */
dl,
dd {
  margin: 0;
  padding: 0; }

ol,
ul {
  margin: 0;
  padding: 0; }

/**
 * Reset list items back to normal (block) display.
 * This makes it possible to use lists for semantic
 * markup without needing to set this each time.
 * Lists only look like ‘lists’ in blocks of copy.
 */
li {
  display: block;
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

/* Links
   ========================================================================== */
a {
  font-weight: inherit;
  color: inherit;
  text-decoration: none; }

a img {
  border: 0; }

/**
 * Improve readability when focused and hovered in all browsers
 * http://people.opera.com/patrickl/experiments/keyboard/test
 */
a:focus,
a:hover,
a:active {
  outline: 0 !important; }

/* Addresses outline displayed oddly in Chrome */
a:focus {
  outline: thin dotted; }

/* Images
   ========================================================================== */
/**
 * Removes border when inside 'a' element in IE7/8/9, FF3
 * Remove the gap between images and borders on image containers (http://h5bp.com/e)
 */
img {
  border: 0;
  vertical-align: middle; }

/* Correct overflow displayed oddly in IE9 */
svg:not(:root) {
  overflow: hidden; }

/* Addresses margin not present in IE7/8/9, S5, O11 */
figure {
  margin: 0; }

/* Forms + Buttons
   ========================================================================== */
form {
  margin: 0; }

/* Define consistent border, margin, and padding */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/**
 * 1. Corrects color not being inherited in IE7/8/9
 * 2. Remove padding so people aren't caught out if they zero out fieldsets
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */
input,
button,
select,
textarea {
  font-size: 100%;
  /* 1 */
  font-size: inherit;
  /* 1 */
  font-family: inherit;
  margin: 0;
  /* 2 */
  padding: 0;
  /* 2 */
  text-transform: inherit;
  vertical-align: baseline;
  /* 3 */ }

/* Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet */
button,
input {
  line-height: normal; }

/**
 * 1. Avoid the WebKit bug in Android 4.0. where (2) destroys native 'audio' and 'video' controls
 * 2. Improves usability and consistency of cursor style between image-type 'input' and others
 * 3. Allow styling of clickable form elements in iOS
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/* Re-set default cursor for disabled elements */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -webkit-box-sizing: content-box;
  /* 2 */
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

/* Removes inner padding and search cancel button in Safari 5 and Chrome on OS X */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Removes default vertical scrollbar in IE 8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells
 * Set border-collapse' to 'separate' to be able to round corners of a table
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

/**
 * 'Base' styles for browsers that don't support media queries
 */
/**
 * Enable automatic content scaling in IE 10, see: http://j.mp/TT6lHf
 * In Metro IE10 snap mode works most of the time correct when using: "width: device-width;",
 * but it doesn't always work as meant on Windows Phone 8 (e.g. Nokia Lumia 820).
 * To fix this issue on Windows Phone 8 for now see: http://trentwalton.com/?p=5994
 */
@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@-o-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

/* Base Body/HTML
   ========================================================================== */
html {
  height: 100%; }

body {
  min-height: 100%;
  color: #2C312A;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased; }

strong {
  font-family: "Noto Serif", serif; }

/* Base Helper classes
   ========================================================================== */
.ellipsis, .select2-container .select2-choice span, #list--arrangement.list p,
#list.list p {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden; }

.js-block {
  cursor: pointer; }

/* Base type (global, no scope)
   ========================================================================== */
img {
  max-width: 100%;
  height: auto; }

/* Responsive classes
   ========================================================================== */
.desktop {
  display: none; }
  @media only screen and (min-width: 48em) {
    .desktop {
      display: block; } }

.mobile {
  display: block; }
  @media only screen and (min-width: 48em) {
    .mobile {
      display: none; } }

/* Responsive classes
   ========================================================================== */
.shadow, .news__wrapper, .programma__wrapper {
  -webkit-box-shadow: 0px 0px 300px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 300px 0 rgba(0, 0, 0, 0.5);
  ms-box-shadow: 0px 0px 300px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 300px 0 rgba(0, 0, 0, 0.5); }

.page #textWrapper .wrapper h2 {
  color: #2C312A;
  margin-bottom: 0.8090234858em; }

.page #textWrapper .wrapper ul {
  padding: 0;
  margin: 1.309em 0; }
  .page #textWrapper .wrapper ul li {
    position: relative;
    padding-left: 1.309em; }
    .page #textWrapper .wrapper ul li:before {
      content: "•";
      color: #D93600;
      position: absolute;
      left: 0; }

.page #textWrapper .wrapper p {
  margin-bottom: 1.309em; }

.page #textWrapper .wrapper strong {
  font-family: "Noto Serif", serif; }

.page #textWrapper .bckBlue .wrapper h2 {
  color: #2C312A; }
  @media only screen and (min-width: 48em) {
    .page #textWrapper .bckBlue .wrapper h2 {
      color: white; } }

.page #textWrapper .bckBlue .wrapper ul li:before {
  color: #2C312A; }
  @media only screen and (min-width: 48em) {
    .page #textWrapper .bckBlue .wrapper ul li:before {
      color: white; } }

.page #textWrapper .bckRed .wrapper h2 {
  color: #2C312A; }
  @media only screen and (min-width: 48em) {
    .page #textWrapper .bckRed .wrapper h2 {
      color: white; } }

.page #textWrapper .bckRed .wrapper ul li:before {
  color: #2C312A; }
  @media only screen and (min-width: 48em) {
    .page #textWrapper .bckRed .wrapper ul li:before {
      color: white; } }

#textWrapper #page_5842 ul li,
#textWrapper #page_52 ul li {
  padding-left: 0 !important; }
  #textWrapper #page_5842 ul li:before,
  #textWrapper #page_52 ul li:before {
    display: none; }

#page_219 h1 span {
  display: none; }

#page_219 span {
  display: block;
  margin-bottom: 0.3819820591em; }

#page_219 span.label {
  display: block;
  font-family: "Noto Serif", serif;
  margin-bottom: 0;
  color: #D93600; }

/* RET FORM
   ========================================================================== */
#travel-planner label {
  color: #2C312A;
  font-family: "Noto Serif", serif; }

#travel-planner input[type=radio] {
  margin-right: 5px; }

#travel-planner input[type=text],
#travel-planner select {
  height: 34px; }

#travel-planner select {
  width: 100%;
  padding: 0.3819820591em;
  font-family: "Open Sans", sans-serif;
  color: #2C312A;
  border-radius: 4px;
  -webkit-appearance: none;
  outline: none;
  border: none;
  margin-bottom: 1.309em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  #travel-planner select:focus {
    border: none;
    outline: none; }
  #travel-planner select option {
    padding: 0.3819820591em; }

#travel-planner #resultaat {
  margin: 0; }

#travel-planner .submit {
  float: right;
  border: none; }

label#single_userinput_time {
  color: red; }

#single_userinput_departure,
#single_userinput_arrival,
#single_userinput_time {
  width: 100%;
  padding: 0.3819820591em;
  font-family: "Open Sans", sans-serif;
  color: #2C312A;
  border-radius: 4px;
  -webkit-appearance: none;
  outline: none;
  border: none;
  margin-bottom: 1.309em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  #single_userinput_departure:focus,
  #single_userinput_arrival:focus,
  #single_userinput_time:focus {
    border: none;
    outline: none; }

#single_userinput_departuretype {
  margin-bottom: 1.309em; }
  #single_userinput_departuretype label {
    margin-right: 0.8090234858em; }

/**
 * Web fonts
 *
 * Font events for web fonts loaded with Typekit and/or Google's
 * WebFont Loader to control the Flash of Unstyled Text (FOUT)
 */
.wf-loading {
  visibility: hidden;
  /* IE7/8 */
  opacity: 0; }

.wf-active {
  -webkit-transition: opacity 0.24s ease-in-out;
  -moz-transition: opacity 0.24s ease-in-out;
  -ms-transition: opacity 0.24s ease-in-out;
  transition: opacity 0.24s ease-in-out;
  visibility: visible;
  /* IE7/8 */
  opacity: 1; }

.wf-inactive {
  visibility: visible;
  /* IE7/8 */
  opacity: 1; }

/* iCheck plugin Line skin, blue
----------------------------------- */
.icheckbox_line-blue,
.iradio_line-blue {
  position: relative;
  display: block;
  margin: 5px 10px 5px 0;
  padding: 12px;
  font-size: 16px;
  line-height: 16px;
  color: #0D5592;
  background: #3384CC;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  font-family: 'ff-good-web-pro';
  float: left; }

.icheckbox_line-blue .icheck_line-icon,
.iradio_line-blue .icheck_line-icon {
  position: absolute;
  top: 50%;
  left: 13px;
  width: 13px;
  height: 11px;
  margin: -5px 0 0 0;
  padding: 0;
  overflow: hidden;
  background: url(line.png) no-repeat;
  border: none; }

.icheckbox_line-blue.hover,
.icheckbox_line-blue.checked.hover,
.iradio_line-blue.hover {
  background: #FFF;
  color: #0056BF; }

.icheckbox_line-blue.checked,
.iradio_line-blue.checked {
  background: #FFF;
  color: #0056BF; }

.icheckbox_line-blue.checked .icheck_line-icon,
.iradio_line-blue.checked .icheck_line-icon {
  background-position: -15px 0; }

.icheckbox_line-blue.disabled,
.iradio_line-blue.disabled {
  background: #ADD7F0;
  cursor: default; }

.icheckbox_line-blue.disabled .icheck_line-icon,
.iradio_line-blue.disabled .icheck_line-icon {
  background-position: -30px 0; }

.icheckbox_line-blue.checked.disabled,
.iradio_line-blue.checked.disabled {
  background: #ADD7F0; }

.icheckbox_line-blue.checked.disabled .icheck_line-icon,
.iradio_line-blue.checked.disabled .icheck_line-icon {
  background-position: -45px 0; }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_line-blue .icheck_line-icon,
  .iradio_line-blue .icheck_line-icon {
    background-image: url(line@2x.png);
    -webkit-background-size: 60px 13px;
    background-size: 60px 13px; } }

/**
 * CAT
 */
/* iCheck plugin Line skin, cat
----------------------------------- */
.icheckbox_line-cat,
.iradio_line-cat {
  position: relative;
  display: block;
  margin: 5px 10px 5px 0;
  padding: 12px 12px 12px 40px;
  font-size: 16px;
  line-height: 16px;
  color: #0D5592;
  border: none;
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  font-family: 'ff-good-web-pro';
  float: left;
  background: url(check-inactive.gif) no-repeat left top #3384CC; }

.icheckbox_line-cat .icheck_line-icon,
.iradio_line-cat .icheck_line-icon {
  position: absolute;
  top: 50%;
  left: 13px;
  width: 13px;
  height: 11px;
  margin: -5px 0 0 0;
  padding: 0;
  overflow: hidden;
  background: url(line.png) no-repeat;
  border: none; }

.icheckbox_line-cat.hover,
.icheckbox_line-cat.checked.hover,
.iradio_line-cat.hover {
  color: #0056BF;
  background: url(check-active.gif) no-repeat left top #FFF; }

.icheckbox_line-cat.checked,
.iradio_line-cat.checked {
  color: #0056BF;
  background: url(check-active.gif) no-repeat left top #FFF; }

.icheckbox_line-cat.checked .icheck_line-icon,
.iradio_line-cat.checked .icheck_line-icon {
  background-position: -15px 0; }

.icheckbox_line-cat.disabled,
.iradio_line-cat.disabled {
  background: #ADD7F0;
  cursor: default; }

.icheckbox_line-cat.disabled .icheck_line-icon,
.iradio_line-cat.disabled .icheck_line-icon {
  background-position: -30px 0; }

.icheckbox_line-cat.checked.disabled,
.iradio_line-cat.checked.disabled {
  background: #ADD7F0; }

.icheckbox_line-cat.checked.disabled .icheck_line-icon,
.iradio_line-cat.checked.disabled .icheck_line-icon {
  background-position: -45px 0; }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_line-cat .icheck_line-icon,
  .iradio_line-cat .icheck_line-icon {
    background-image: url(line@2x.png);
    -webkit-background-size: 60px 13px;
    background-size: 60px 13px; } }

/* ==========================================================================
   Panoramabar
   ========================================================================== */
.actions_wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0; }

.help,
.news,
.social {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  float: left;
  padding: 15px; }
  .help:after,
  .news:after,
  .social:after {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: block;
    width: 12px;
    height: 12px;
    border-right: 4px solid white;
    border-bottom: 4px solid white;
    content: ""; }
  @media only screen and (min-width: 30em) {
    .help,
    .news,
    .social {
      height: 116px; } }

.help {
  width: 100%;
  float: left; }
  .help p {
    margin: 0;
    padding: 0 15px 15px 0;
    color: white;
    font-size: 22px;
    font-family: "Noto Serif", serif;
    line-height: 1.2; }
  @media only screen and (min-width: 30em) {
    .help {
      padding: 15px 200px 15px 15px; } }
  @media only screen and (min-width: 48em) {
    .help {
      padding: 15px;
      width: 50%; } }
  @media only screen and (min-width: 60em) {
    .help {
      padding: 15px 150px 15px 15px; } }

.news,
.social {
  width: 50%; }
  .news p,
  .social p {
    display: none;
    font-size: 16px;
    line-height: 1.285714286; }
    @media only screen and (min-width: 30em) {
      .news p,
      .social p {
        display: block; } }
  .news h2,
  .social h2 {
    margin: 0;
    padding: 0 15px 15px 0;
    font-size: 16px;
    font-family: "Noto Serif", serif; }
    @media only screen and (min-width: 30em) {
      .news h2,
      .social h2 {
        padding: 0 15px 0 0;
        margin: 0 0 10px 0;
        font-size: 22px; } }
  @media only screen and (min-width: 48em) {
    .news,
    .social {
      width: 25%; } }

.news {
  color: #0065BF; }
  .news:after {
    border-right: 4px solid #0065BF;
    border-bottom: 4px solid #0065BF; }

.status--teasing .actions_wrapper,
.status--afterwards .actions_wrapper {
  width: 100%; }

.status--teasing .news,
.status--teasing .social,
.status--afterwards .news,
.status--afterwards .social {
  width: 50%; }

.status--teasing .news,
.status--afterwards .news {
  color: white;
  background-color: #666600; }
  .status--teasing .news:after,
  .status--afterwards .news:after {
    border-right: 4px solid white;
    border-bottom: 4px solid white; }

.social p {
  color: #91b5d5; }

.social h2 {
  color: white; }

/* ==========================================================================
   Search
   ========================================================================== */
.search h1 {
  display: inline;
  color: #666600; }

.search #subTitel {
  float: left;
  padding: 86px 0 0;
  width: 170px;
  height: 49px;
  border-bottom: 1px solid #000; }

.search #titel {
  margin: 0;
  padding: 20px 0 20px 0;
  width: 100%; }
  @media only screen and (min-width: 48em) {
    .search #titel {
      width: 540px;
      height: 95px;
      float: left;
      border-bottom: 1px solid #000;
      clear: right;
      padding: 40px 0 0;
      margin: 0 0 0 20px; }
      .search #titel h1 {
        font-size: 3em;
        line-height: 120%; } }

.search #subNav {
  padding: 0 0 10px;
  width: 100%;
  border-bottom: 1px solid #000; }
  @media only screen and (min-width: 48em) {
    .search #subNav {
      width: 170px;
      float: left;
      padding: 10px 0 0;
      border: none; } }
  .search #subNav ul {
    margin: 0;
    padding: 0; }
    .search #subNav ul li {
      margin: 0 0 10px;
      padding: 0;
      width: 100%;
      list-style: none; }
      .search #subNav ul li a {
        display: block;
        padding: 10px 10px;
        border-radius: 2px;
        background: #E8E8E8;
        color: #8a8c89;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        font-family: "Noto Serif", serif; }
        @media only screen and (min-width: 48em) {
          .search #subNav ul li a {
            text-align: left; } }

.search ul#results {
  margin: 0;
  padding: 0; }
  @media only screen and (min-width: 48em) {
    .search ul#results {
      width: 540px;
      float: left;
      margin: 0 0 0 20px; } }
  .search ul#results li {
    padding: 20px 0 10px;
    border-bottom: 1px solid #000;
    list-style: none; }
    .search ul#results li h1 {
      color: #2C312A;
      font-size: 16px; }

.search #bottomNav {
  width: 100%; }
  @media only screen and (min-width: 48em) {
    .search #bottomNav {
      width: 540px;
      float: left;
      margin: 0 0 20px 190px; } }
  .search #bottomNav ul {
    margin: 0;
    padding: 20px 0 0; }
    .search #bottomNav ul li {
      margin: 0;
      padding: 0;
      list-style: none; }
      .search #bottomNav ul li a {
        display: block;
        padding: 10px 10px;
        border-radius: 2px;
        background: #E8E8E8;
        color: white;
        text-decoration: none;
        font-size: 16px;
        font-family: "Noto Serif", serif; }

.search #subNav ul li a.active {
  background-color: #0065bf;
  color: white; }

.search #bottomNav ul li.left a {
  margin: 0;
  padding: 15px 20px 15px 40px;
  background-color: #0065BF;
  background-position: left center; }

.search #bottomNav ul li.right a {
  margin: 0;
  padding: 15px 43px 15px 20px;
  background-color: #666600;
  background-position: right center; }

.search #bottomNav .arrowLeft {
  position: absolute;
  width: 50px;
  height: 100%;
  background-position: 5px 18px;
  background-repeat: no-repeat; }

.search #bottomNav .arrowRight {
  position: absolute;
  right: 0;
  z-index: 50;
  width: 50px;
  height: 100%;
  background-position: 15px 18px;
  background-repeat: no-repeat; }

@media only screen and (min-width: 48em) {
  .search #titel.noresults {
    margin-left: 200px;
    height: auto;
    border: none; } }

/* ==========================================================================
   Planner - Mobile
   ========================================================================== */
#planner {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  color: white;
  font-size: 24px;
  font-family: "Noto Serif", serif;
  background-color: #666600; }
  #planner .step {
    font-size: 18px;
    position: absolute;
    right: 80px;
    bottom: 22px;
    z-index: 10;
    font-size: 16px; }
    @media only screen and (min-width: 48em) {
      #planner .step {
        right: 110px;
        bottom: 45px; } }
    @media only screen and (min-width: 60em) {
      #planner .step {
        bottom: 45px; } }
  #planner form {
    clear: both;
    overflow: hidden; }
  #planner select {
    width: 100%;
    padding: 16px 20px;
    border: 0;
    background: transparent;
    color: #2C312A;
    font-size: 24px;
    font-family: "Noto Serif", serif;
    line-height: 26px;
    -webkit-appearance: none; }
    @media only screen and (min-width: 60em) {
      #planner select {
        font-size: 30px; } }
  #planner .submit {
    background-color: #333300; }
    @media only screen and (min-width: 48em) {
      #planner .submit {
        float: right; } }
    #planner .submit a {
      position: relative;
      float: right;
      clear: both;
      width: 60px;
      height: 60px;
      background-color: black; }
      #planner .submit a:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        content: "";
        display: block;
        width: 18px;
        height: 8px;
        background-color: #333300;
        left: 0;
        top: 50%;
        margin-top: -7px;
        margin-left: -12px; }
        @media only screen and (min-width: 48em) {
          #planner .submit a:before {
            background-color: #666600; } }
      #planner .submit a:hover {
        background-color: #A40D21; }
        @media only screen and (min-width: 48em) {
          #planner .submit a:hover {
            background-color: #A40D21; } }
      @media only screen and (min-width: 48em) {
        #planner .submit a {
          background-color: black;
          width: 90px;
          height: 112px;
          background-position: -312px -114px; } }
      @media only screen and (min-width: 60em) {
        #planner .submit a {
          background-position: -312px -108px; } }
  #planner ul {
    margin: 0;
    padding: 0;
    width: 200%;
    list-style: none; }
    #planner ul li {
      float: left;
      width: 50%;
      position: relative; }
      #planner ul li .inner {
        padding: 20px;
        padding: 15px; }
        @media only screen and (min-width: 48em) {
          #planner ul li .inner {
            float: left;
            padding: 20px;
            margin: 0; }
            #planner ul li .inner div {
              float: left; } }
      #planner ul li .text {
        padding: 8px 0; }
        @media only screen and (min-width: 48em) {
          #planner ul li .text {
            padding: 20px 0; } }
  #planner .select_stap_1,
  #planner .select_stap_2 {
    border-radius: 3px;
    background-color: white;
    background-position: right center;
    background-repeat: no-repeat; }

/* ==========================================================================
   Overige planner styles
   ========================================================================== */
@media only screen and (min-width: 48em) {
  #planner ul li .inner .select_stap_1,
  #planner ul li .inner .select_stap_2 {
    width: 350px;
    margin: 12px 20px 5px 20px; } }

@media only screen and (min-width: 60em) {
  #planner ul li .inner .select_stap_1,
  #planner ul li .inner .select_stap_2 {
    width: 500px; } }

#planner .submit #plannerSubmit.loading {
  background-image: url("../images/ajax-loader.gif");
  background-position: center center !important; }

#planner .submit #plannerSubmit.loading:hover {
  background-color: black; }

/* ==========================================================================
   Background images
   ========================================================================== */
.twitter a,
.flickr a,
.facebook a,
.menu-icon a,
.search-icon a,
.ticket-icon a,
.submit a,
.logo a,
.close a,
.button,
#btnSearch,
.newsWrapper .icon,
.newsWrapper2 .icon,
.icons,
.shareButtons a,
.buttons a,
.filter__sentence__close,
.share__twitter,
.share__facebook,
.button:after,
#plannerHeader .opnieuw a span {
  background-image: url("../images/slice-mobile.png");
  background-repeat: no-repeat;
  text-indent: -9999px; }

.newsWrapper2 ul li,
.knop,
.btnRed .title span,
.btnBlue .title span,
#photo .prev a,
.newsWrapper2 ul li,
.txt #tijden li a.knopTickets,
.search #bottomNav .arrowRight,
.search #bottomNav ul li.right a,
#list.block li a .button {
  background-image: url("../images/arrow-right-mobile.png");
  background-repeat: no-repeat; }

#filterOverlay .knop,
.btnGrey .title span {
  background-image: url("../images/arrow-right-b-mobile.png");
  background-repeat: no-repeat; }

.knopTerug,
#photo .next a,
.search #bottomNav .arrowLeft,
.search #bottomNav ul li.left a {
  background-image: url("../images/arrow-left-mobile.png");
  background-repeat: no-repeat; }

#planner .select_stap_1,
#planner .select_stap_2,
.mainBlock .title.active span {
  background-image: url("../images/arrow-down-b-mobile.png");
  background-repeat: no-repeat; }

.btn--filter:before {
  background-image: url("../images/plus-negativ.png"); }

.btn--info:before {
  background-image: url("../images/down.png"); }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px), only screen and (-o-min-device-pixel-ratio: 3 / 2) and (max-width: 767px), only screen and (min--moz-device-pixel-ratio: 1.5) and (max-width: 767px), only screen and (min-device-pixel-ratio: 1.5) and (max-width: 767px) {
  .twitter a,
  .flickr a,
  .facebook a,
  .menu-icon a,
  .search-icon a,
  .ticket-icon a,
  .submit a,
  .logo a,
  .close a,
  .button,
  .icons,
  #btnSearch,
  .newsWrapper .icon,
  .newsWrapper2 .icon,
  .shareButtons a,
  .buttons a,
  .filter__sentence__close,
  .share__twitter,
  .share__facebook,
  .button:after,
  #plannerHeader .opnieuw a span {
    background-image: url("../images/slice-mobile@2x.png");
    background-size: 425px 280px; }
  .newsWrapper2 ul li,
  .knop,
  .btnRed .title span,
  .btnBlue .title span,
  #photo .prev a,
  .newsWrapper2 ul li,
  .txt #tijden li a.knopTickets,
  .search #bottomNav .arrowRight,
  .search #bottomNav ul li.right,
  #list.block li a .button {
    background-image: url("../images/arrow-right-mobile@2x.png");
    background-size: 30px 14px; }
  #filterOverlay .knop,
  .btnGrey .title span {
    background-image: url("../images/arrow-right-b-mobile@2x.png");
    background-size: 30px 14px; }
  .knopTerug,
  #photo .next a,
  .search #bottomNav .arrowLeft,
  .search #bottomNav ul li.left {
    background-image: url("../images/arrow-left-mobile@2x.png");
    background-size: 30px 14px; }
  #planner .select_stap_1,
  #planner .select_stap_2,
  .mainBlock .title.active span {
    background-image: url("../images/arrow-down-b-mobile@2x.png");
    background-size: 30px 14px; }
  #planner .submit #plannerSubmit.loading {
    background-size: 16px; }
  .btn--filter:before {
    background-image: url("../images/plus-negativ@2x.png");
    background-size: 16px; }
  .btn--info:before {
    background-image: url("../images/min-down@2x.png");
    background-size: 16px; }
  .btn--info:before {
    background-image: url("../images/down@2x.png"); } }

/**
 * Lightbox
 */
/* Custom teasing box */
.signup {
  background-color: #666600;
  color: white;
  padding: 4.235801032em 2.617924em;
  position: relative; }
  .signup h2 {
    font-size: 20px;
    font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;
    margin-bottom: 1.6em; }
  .signup .signup__close {
    background-color: #D93600;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 40px;
    height: 40px;
    background-image: url("../images/slice.png");
    background-position: -420px -300px; }
    .signup .signup__close:hover {
      background-color: black; }

/* Custom lightbox settings */
.lightbox {
  width: auto;
  max-width: 100%; }
  @media only screen and (min-width: 30em) {
    .lightbox {
      max-width: 500px;
      margin: 20px auto; } }
  .lightbox .mfp-close {
    display: none; }

.lightbox--ticket {
  background-color: white;
  color: white;
  padding: 1.309em 1.309em;
  position: relative;
  margin: 0; }
  @media only screen and (min-width: 30em) {
    .lightbox--ticket {
      max-width: 600px;
      margin: 20px auto; } }
  @media only screen and (min-width: 48em) {
    .lightbox--ticket {
      max-width: 700px;
      margin: 20px auto; } }
  @media only screen and (min-width: 60em) {
    .lightbox--ticket {
      max-width: 800px;
      margin: 20px auto; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #2c312a;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  border-radius: 50%;
  background-color: #666600;
  width: 48px;
  height: 48px;
  line-height: 44px;
  position: absolute;
  right: -17px;
  top: -17px;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  color: #fafafa;
  font-style: normal;
  font-size: 28px;
  font-family: "Noto Serif", serif; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after,
  .mfp-arrow .mfp-b,
  .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after,
  .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before,
  .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after,
  .mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before,
  .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after,
  .mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before,
  .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/* ==========================================================================
   Buttons
   ========================================================================== */
.btn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: white;
  text-decoration: none;
  padding: 20px;
  width: 100%;
  display: inline-block;
  font-family: "Noto Serif", serif;
  border-radius: 3px;
  font-size: 18px;
  position: relative; }

.btn--small {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: white;
  text-decoration: none;
  padding: 10px 40px 10px 20px;
  width: auto;
  display: inline-block;
  font-family: "Noto Serif", serif;
  border-radius: 3px;
  font-size: 18px;
  line-height: 1;
  position: relative; }

.btn--red {
  background-color: #666600;
  color: white !important; }
  .btn--red:hover {
    background-color: #333300; }

.btn--gray {
  background-color: #e5e5e5 !important;
  cursor: default !important;
  color: #303030 !important; }
  .btn--gray:after {
    background-image: none !important; }

.btn--black {
  background-color: #303030; }
  .btn--black:hover {
    background-color: #000000; }

.btn--ticket {
  background-color: #666600;
  background: #666600;
  margin-bottom: 40px; }
  .btn--ticket:after {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../images/ticket.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    right: 20px;
    top: 14px;
    width: 33px;
    height: 33px; }
  .btn--ticket:hover {
    background-color: #333300; }
  @media only screen and (min-width: 48em) {
    .btn--ticket {
      margin-top: 20px; } }

.status--teasing .schedule .btn--red,
.status--teasing .btn--ticket,
.status--afterwards .schedule .btn--red,
.status--afterwards .btn--ticket,
.status--non-selling .schedule .btn--red,
.status--non-selling .btn--ticket {
  color: #f5a0ab; }
  .status--teasing .schedule .btn--red:hover,
  .status--teasing .btn--ticket:hover,
  .status--afterwards .schedule .btn--red:hover,
  .status--afterwards .btn--ticket:hover,
  .status--non-selling .schedule .btn--red:hover,
  .status--non-selling .btn--ticket:hover {
    color: white; }

.btn--small:after {
  position: absolute;
  content: "";
  display: block;
  background-image: url("../images/arrow-right-mobile-small.png");
  background-position: -8px 4px;
  background-repeat: no-repeat;
  right: 20px;
  top: 10px !important;
  width: 11px;
  height: 17px; }

.btn--red.arrow:after {
  position: absolute;
  content: "";
  display: block;
  background-image: url("../images/arrow-right-mobile-small.png");
  background-position: -8px 4px;
  background-repeat: no-repeat;
  right: 20px;
  top: 20px;
  width: 11px;
  height: 17px; }

.btn--extern {
  width: 100%; }
  .btn--extern:after {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../images/extern.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    right: 20px;
    top: 20px;
    width: 22px;
    height: 22px; }
  @media only screen and (min-width: 30em) {
    .btn--extern {
      width: 49%;
      float: left;
      margin-right: 2%; }
      .btn--extern:last-child {
        margin-right: 0; } }

.btn--delete {
  background: white;
  text-indent: 9999px;
  width: 50px;
  height: 50px;
  border-radius: 0;
  float: right;
  overflow: hidden; }
  .btn--delete:after {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../images/icon--close-gray.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .btn--delete:hover {
    background-color: #595959; }
    .btn--delete:hover:after {
      background-image: url("../images/icon--close.svg"); }

.btn--config {
  color: #D93600;
  background: white;
  border: 1px solid #E8E8E8;
  width: auto;
  padding: 15px 50px 15px 15px;
  border-radius: 3px; }
  .btn--config:after {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../images/icon--config.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    right: 15px;
    top: 12px;
    width: 25px;
    height: 25px; }
  .btn--config:hover {
    background-color: #E8E8E8;
    color: #D93600 !important; }

.btn--logout {
  color: #D93600;
  background: white;
  border: 1px solid #E8E8E8;
  width: auto;
  padding: 15px 50px 15px 15px;
  border-radius: 3px; }
  .btn--logout:after {
    position: absolute;
    content: "";
    display: block;
    background-image: url("../images/icon--logout.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    right: 15px;
    top: 12px;
    width: 25px;
    height: 25px; }
  .btn--logout:hover {
    background-color: #E8E8E8;
    color: #D93600 !important; }

/* 12HOOG custom Toggle class
   ========================================================================== */
.toggle.toggle--closed .toggle__content {
  display: none; }

.toggle.toggle--closed .toggle__icon {
  background-image: url(../images/plus.gif);
  background-position: 4px 1px; }

.toggle.toggle--closed:hover .toggle__icon {
  background-position: 4px -40px; }

.toggle.toggle--open .toggle__content {
  display: block; }

.toggle__trigger {
  position: relative;
  cursor: pointer; }
  .toggle__trigger:hover .toggle__icon {
    background-position: 4px -21px; }

.toggle__icon {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 2px;
  background-image: url(../images/min.gif);
  background-position: 4px 8px;
  background-repeat: no-repeat; }

/*
Version: 3.4.0 Timestamp: Tue May 14 08:27:33 PDT 2013
*/
.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle; }

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  -webkit-box-sizing: border-box;
  /* webkit */
  -khtml-box-sizing: border-box;
  /* konqueror */
  -moz-box-sizing: border-box;
  /* firefox */
  -ms-box-sizing: border-box;
  /* ie */
  box-sizing: border-box;
  /* css3 */ }

.select2-container .select2-choice {
  display: block;
  padding: 16px 0 16px 8px;
  overflow: hidden;
  position: relative;
  font-size: 24px;
  line-height: 100%;
  border: 1px solid #FFF;
  white-space: nowrap;
  line-height: 26px;
  color: #2C312A;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff; }

.mobile .select2-container .select2-choice {
  font-size: 25px; }

body.desktop .select2-container .select2-choice {
  margin-top: -8px; }

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #FFF;
  border-radius: 0 0 3px 3px; }

.select2-container.select2-allowclear .select2-choice span {
  margin-right: 42px; }

.select2-container .select2-choice span {
  margin-right: 26px;
  display: block; }
  @media only screen and (min-width: 48em) {
    .select2-container .select2-choice span {
      width: 300px; } }
  @media only screen and (min-width: 60em) {
    .select2-container .select2-choice span {
      width: 340px; } }

.select2-container a.select2-choice {
  background: url("../images/arrow-down-b-mobile@2x.png") no-repeat right center #FFF;
  background-size: 30px 14px; }

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 8px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  outline: 0; }

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block; }

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer; }

.select2-drop-mask {
  left: 0;
  top: 0;
  z-index: 9998;
  width: 100% !important;
  height: 100% !important;
  position: fixed; }

.select2-drop {
  font-family: 'ff-good-web-pro', Arial, sans-serif;
  font-size: 35px;
  width: 100%;
  margin-top: -1px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background: #fff;
  color: #2C312A;
  border: 1px solid #FFF;
  border-top: 0;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px; }

.mobile .select2-drop {
  font-size: 20px !important; }

.select2-drop-auto-width {
  border-top: 1px solid #FFF;
  width: auto; }

.select2-drop-auto-width .select2-search {
  padding-top: 4px; }

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #FFF;
  border-bottom: 0;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0; }

.select2-container .select2-choice div {
  display: inline-block;
  width: 30px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  background: #FFF; }

.select2-container .select2-choice div b {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../images/slice-mobile.png");
  background-repeat: no-repeat;
  background-position: -185px 10px; }

.select2-search {
  display: none;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap; }

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  border: 1px solid #FFF;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px; }

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border: 1px solid #FFF;
  outline: none; }

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  background-color: #FFF;
  color: #A6A6A6; }

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #FFF;
  border-top-color: transparent; }

.select2-dropdown-open .select2-choice div {
  background: transparent;
  border-left: none;
  filter: none; }

.select2-dropdown-open .select2-choice div b {
  background-position: -185px -42px; }

/* results */
.select2-results {
  max-height: 400px;
  margin: 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent; }

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0; }

.select2-results ul.select2-result-sub > li .select2-result-label {
  padding-left: 20px; }

.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 40px; }

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 60px; }

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 80px; }

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 100px; }

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 110px; }

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label {
  padding-left: 120px; }

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none; }

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold; }

.select2-results .select2-result-label {
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid #f2f2f2;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.select2-results .select2-highlighted {
  background: #E7E7E7;
  color: #2C312A; }

.select2-results li em {
  background: #feffde;
  font-style: normal; }

.select2-results .select2-highlighted em {
  background: transparent; }

.select2-results .select2-highlighted ul {
  background: white;
  color: #000; }

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item; }

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default; }

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default; }

.select2-results .select2-selected {
  display: none; }

.select2-more-results.select2-active {
  background: #f4f4f4 url("select2-spinner.gif") no-repeat 100%; }

.select2-more-results {
  background: #f4f4f4;
  display: list-item; }

.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #FFF;
  cursor: default; }

.select2-container.select2-container-disabled .select2-choice div {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0; }

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none; }

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #FFF;
  cursor: text;
  overflow: hidden;
  background-color: #fff; }

.select2-locked {
  padding: 3px 5px 3px 5px !important; }

.select2-container-multi .select2-choices {
  min-height: 26px; }

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #FFF;
  outline: none; }

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none; }

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap; }

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: transparent !important; }

.select2-default {
  color: #999 !important; }

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;
  line-height: 13px;
  color: #333;
  cursor: default;
  border: 1px solid #FFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #FFF; }

.select2-container-multi .select2-choices .select2-search-choice span {
  cursor: default; }

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4; }

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: none; }

.select2-container-multi .select2-search-choice-close {
  left: 3px; }

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
  background-position: right -11px; }

.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px; }

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #FFF;
  cursor: default; }

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #FFF;
  background-image: none;
  background-color: #f4f4f4; }

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: none; }

/* end multiselect */
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline; }

.select2-offscreen, .select2-offscreen:focus {
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  outline: 0;
  left: 0px; }

.select2-display-none {
  display: none; }

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll; }

/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
  .select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice div b {
    /*background-image: url('../images/select2x2.png') !important;*/
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important; }
  .select2-search input {
    background-position: 100% -21px !important; } }

/* ==========================================================================
   8.0 Kaart - Mobile
   ========================================================================== */
#kaart {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%; }
  #kaart img {
    max-width: none; }

#kaartFilterButtonContainer {
  position: relative;
  width: 100%;
  pointer-event: none; }

#kaartFilterButton {
  padding: 20px; }

#kaartFilterButton a {
  float: left;
  clear: both;
  padding: 10px 60px 10px 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #0065BF;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

#kaartFilterButton a:hover {
  background-color: #005199; }

#kaartFilterContainer {
  position: relative;
  width: 100%;
  pointer-event: none; }

#kaartFilter {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  width: 100%;
  height: auto;
  background-color: #0065BF;
  pointer-events: auto; }

#kaartFilter .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 60px;
  height: 60px; }

#kaartFilter .close a {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #005199;
  background-position: -180px -118px; }

#kaartFilter .close a:hover {
  background-color: #005199; }

#kaartFilter .wrapper {
  padding: 15px; }

#kaartFilter .wrapper h1 {
  color: #233b48;
  font-size: 20px; }

#kaartFilter .wrapper hr {
  margin: 6px 0 20px 0;
  height: 1px;
  border: none;
  background-color: #4d94d2; }

#kaartFilter input {
  display: none; }

#kaartFilter label {
  display: block;
  margin: 4px 0;
  padding: 15px 0px 15px 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #3384CC;
  color: #0D5592;
  font-size: 18px;
  font-family: "Noto Serif", serif;
  cursor: pointer; }

#kaartFilter label.active {
  background-color: white;
  color: #0065BF; }

#kaartFilter label span {
  float: right;
  margin-top: -9px; }

#kaartFilter .noBackground label {
  padding: 5px 0;
  background-color: transparent;
  color: #3284CD;
  font-size: 25px; }

#kaartFilter .noBackground label.active {
  background-color: transparent;
  color: white; }

#kaartOpenContainer {
  display: none; }

.post-type-archive-whd_arrangement .kaart--small,
.post-type-archive-whd_programma .kaart--small {
  display: none; }

/* ==========================================================================
   20.0 Kaart - Desktop (min 768px)
   ========================================================================== */
@media only screen and (min-width: 48em) {
  .post-type-archive-whd_programma .kaart--small {
    display: block; }
  .post-type-archive-whd_programma #kaart {
    height: 580px; }
  .post-type-archive-whd_programma #kaartOpenContainer {
    display: block;
    position: absolute;
    width: 100%; }
    .post-type-archive-whd_programma #kaartOpenContainer .toggleView {
      right: 0;
      left: auto; }
    .post-type-archive-whd_programma #kaartOpenContainer .big {
      display: block; }
    .post-type-archive-whd_programma #kaartOpenContainer .small {
      display: none; }
  #kaartFilterButtonContainer {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0; }
  #kaartFilterButton {
    width: 120px;
    pointer-events: auto;
    background-color: #E7E7E7; }
  #kaartFilterContainer {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    overflow: hidden; }
  #kaartFilter {
    display: block;
    height: 490px;
    width: 270px;
    pointer-events: auto; }
  #kaartFilter .close {
    width: 78px;
    height: 78px; }
  #kaartFilter .close a {
    width: 78px;
    height: 78px;
    background-position: -560px -280px; }
  #kaartFilter .wrapper h1 {
    margin: 13px 0 50px; } }

.backgroundsize .cbp-bislideshow li {
  background-size: cover;
  background-position: center center; }

.cbp-bislideshow li {
  position: absolute;
  width: 101%;
  height: 101%;
  top: -0.5%;
  left: -0.5%;
  opacity: 0;
  transition: opacity 1s ease 0s; }

.backgroundsize .cbp-bislideshow li img {
  display: none; }

.cbp-bislideshow li img {
  display: block;
  width: 100%; }

/* ==========================================================================
   Featured item
   ========================================================================== */
.featured__title {
  color: white;
  padding: 25px 20px; }
  @media only screen and (min-width: 48em) {
    .featured__title {
      padding: 0;
      text-align: right; } }
  .featured__title h1 {
    margin: 0 0 15px 0;
    font-size: 26px;
    font-family: "Noto Serif", serif;
    line-height: 1;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5); }
    @media only screen and (min-width: 48em) {
      .featured__title h1 {
        font-size: 34px; } }
  .featured__title .btn--small {
    margin-left: 0;
    margin-bottom: 10px;
    text-shadow: none;
    background-color: #D93600;
    min-width: 220px; }
    @media only screen and (min-width: 48em) {
      .featured__title .btn--small {
        margin-left: 10px;
        margin-bottom: 0;
        min-width: auto; } }
    .featured__title .btn--small:hover {
      background-color: #a62900; }

.featured__items h2 {
  margin: 80px 0 20px 0;
  font-size: 18px;
  font-family: "Noto Serif", serif;
  line-height: 1;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  color: #fafafa; }

.featured__item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 25px 20px;
  color: white;
  transition: all 0.3s; }
  @media only screen and (min-width: 48em) {
    .featured__item {
      float: left;
      margin-bottom: 0;
      margin-top: 80px;
      height: 150px; }
      .featured__item:hover {
        transition: all 0.3s;
        margin-top: 60px;
        height: 170px; } }
  .featured__item:nth-child(1) {
    display: none;
    cursor: default; }
    .featured__item:nth-child(1):hover {
      transition: none;
      margin-top: 80px;
      height: 150px; }
    .featured__item:nth-child(1) h2 {
      margin: 0;
      font-size: 26px;
      font-family: "Noto Serif", serif;
      line-height: 1;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      color: #fafafa; }
    @media only screen and (min-width: 48em) {
      .featured__item:nth-child(1) {
        display: block;
        background-color: #D93600; } }
  @media only screen and (min-width: 48em) {
    .featured__item:nth-child(2) {
      background-color: olive; }
      .featured__item:nth-child(2):hover {
        background-color: #4d4d00; } }
  .featured__item:nth-child(3) {
    display: none; }
    @media only screen and (min-width: 48em) {
      .featured__item:nth-child(3) {
        display: block;
        background-color: #999900; }
        .featured__item:nth-child(3):hover {
          background-color: #666600; } }
  .featured__item:nth-child(4) {
    display: none; }
    @media only screen and (min-width: 60em) {
      .featured__item:nth-child(4) {
        display: block;
        background-color: #b3b300; }
        .featured__item:nth-child(4):hover {
          background-color: olive; } }
  .featured__item h2 {
    margin: 0;
    font-size: 18px;
    font-family: "Noto Serif", serif;
    line-height: 1; }
    @media only screen and (min-width: 48em) {
      .featured__item h2 {
        height: 55px;
        overflow: hidden; } }
  .featured__item span {
    display: block;
    color: rgba(255, 255, 255, 0.75);
    font-size: 18px;
    font-family: "Open Sans", sans-serif; }
    @media only screen and (min-width: 48em) {
      .featured__item span {
        position: absolute;
        bottom: 20px; } }
  .featured__item .featured__tag {
    margin-top: 10px; }
  @media only screen and (min-width: 48em) {
    .featured__item {
      width: 33.333%; }
      .featured__item:last-child {
        margin-right: 0; }
      .featured__item.last {
        margin-right: 0; } }
  @media only screen and (min-width: 60em) {
    .featured__item {
      width: 25%; } }

#search {
  position: relative;
  z-index: 10;
  display: none;
  width: 100%;
  background-color: #2C312A; }
  #search input#btnSearch[type="submit"] {
    margin-right: 0; }
  #search form {
    padding: 15px;
    height: 100px; }
    @media only screen and (min-width: 48em) {
      #search form {
        padding: 20px 0 30px 0;
        height: 120px; } }
  #search .searchWrapper {
    float: left;
    margin-right: -60px;
    width: 100%; }
    @media only screen and (min-width: 48em) {
      #search .searchWrapper {
        margin-right: -80px; } }
    #search .searchWrapper div {
      margin-right: 60px;
      padding: 10px 15px;
      -webkit-border-top-left-radius: 3px;
      -moz-border-radius-topleft: 3px;
      border-top-left-radius: 3px;
      -webkit-border-bottom-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      border-bottom-left-radius: 3px;
      background: white; }
      @media only screen and (min-width: 48em) {
        #search .searchWrapper div {
          margin-right: 80px; } }
      #search .searchWrapper div input {
        padding: 0;
        width: 100%;
        height: 40px;
        border: none;
        background-color: transparent; }
        @media only screen and (min-width: 48em) {
          #search .searchWrapper div input {
            height: 60px; } }
  #search #btnSearch {
    float: right;
    width: 60px;
    height: 60px;
    border: none;
    -webkit-border-top-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-left-radius: 0px;
    background-color: #E7E7E7;
    background-position: -60px -60px; }
    @media only screen and (min-width: 48em) {
      #search #btnSearch {
        width: 80px;
        height: 80px;
        background-position: 0 -200px; } }
    #search #btnSearch:hover {
      background-color: #B9B9B9; }

#header {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 60px;
  background-color: #666600; }

.menu-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 60px;
  height: 60px;
  text-align: right; }
  .menu-icon a {
    display: block;
    float: right;
    width: 60px;
    height: 60px;
    background-position: 4px 0; }
    .menu-icon a:hover {
      background-color: black; }
  .menu-icon .active {
    width: 320px;
    background-color: #D01029; }

.logo {
  width: 100%;
  height: 60px;
  text-align: center; }
  .logo a {
    display: inline-block;
    width: 38px;
    height: 60px;
    background-position: 0 -60px; }

.search-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  width: 60px;
  height: 60px; }
  @media only screen and (min-width: 48em) {
    .search-icon {
      width: 80px;
      height: 80px; } }
  .search-icon a {
    display: block;
    width: 60px;
    height: 60px;
    background-color: black;
    background-position: -60px 0; }
    @media only screen and (min-width: 48em) {
      .search-icon a {
        width: 80px;
        height: 80px;
        background-position: 0px -120px; } }
    .search-icon a:hover {
      background-color: #A40D21; }
    .search-icon a .active {
      background-position: -120px -60px; }
      @media only screen and (min-width: 48em) {
        .search-icon a .active {
          background-position: -320px -200px; } }

.status--teasing .teaser a,
.status--afterwards .teaser a,
.status--non-selling .teaser a {
  -moz-opacity: 0.6;
  opacity: 0.6; }
  .status--teasing .teaser a:hover,
  .status--afterwards .teaser a:hover,
  .status--non-selling .teaser a:hover {
    -moz-opacity: 1;
    opacity: 1; }

.status--teasing .ticket-icon,
.status--afterwards .ticket-icon,
.status--non-selling .ticket-icon {
  -moz-opacity: 0.6;
  opacity: 0.6; }
  .status--teasing .ticket-icon:hover,
  .status--afterwards .ticket-icon:hover,
  .status--non-selling .ticket-icon:hover {
    -moz-opacity: 1;
    opacity: 1; }

.status--teasing .ticket__lable:after,
.status--afterwards .ticket__lable:after,
.status--non-selling .ticket__lable:after {
  -moz-opacity: 0.6;
  opacity: 0.6; }

.status--teasing .ticket__lable:hover:after,
.status--afterwards .ticket__lable:hover:after,
.status--non-selling .ticket__lable:hover:after {
  -moz-opacity: 1;
  opacity: 1; }

#topNav ul.meta li.active a {
  color: white; }

#menu {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  font-size: 1.25em;
  font-family: "Noto Serif", serif; }
  #menu .wrapper {
    position: relative;
    z-index: 10;
    display: none;
    width: 260px; }
    #menu .wrapper .red {
      background-color: #4d4d00; }
    #menu .wrapper .white {
      background-color: #E7E7E7; }
  #menu ul {
    margin: 0;
    padding: 15px;
    list-style: none; }
    #menu ul li {
      margin-top: 10px; }
      #menu ul li:first-child {
        margin-top: 0; }
      #menu ul li a {
        display: block;
        padding: 8px 15px;
        border-radius: 7px;
        text-decoration: none; }
    #menu ul.social {
      float: left;
      margin-bottom: 15px;
      margin-left: 15px;
      padding: 0; }
      @media only screen and (min-width: 48em) {
        #menu ul.social {
          margin-left: 25px;
          margin-bottom: 25px; } }
      #menu ul.social li {
        float: left;
        margin: 0 15px 0 0; }
  #menu .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(44, 49, 42, 0.8); }

#menu.mobile .red ul.social li a,
#menu ul.social li.twitter a,
#menu ul.social li.flickr a,
#menu ul.social li.facebook a {
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: transparent; }
  #menu.mobile .red ul.social li a:hover,
  #menu ul.social li.twitter a:hover,
  #menu ul.social li.flickr a:hover,
  #menu ul.social li.facebook a:hover {
    background-color: transparent; }

#menu ul.social:after {
  display: none; }

#menu ul.social li.twitter a {
  background-position: 0px -120px; }

#menu ul.social li.flickr a {
  background-position: -50px -120px; }

#menu ul.social li.facebook a {
  background-position: -100px -120px; }

#menu .red ul li a {
  color: white; }

#menu.mobile .red ul li a,
#menu .red ul li.active a,
#menu .red ul li a:hover {
  background-color: #6b6b00; }

#menu .white ul li a {
  color: #B2B0B0; }

#menu.mobile .white ul li a,
#menu .white ul li.active a,
#menu .white ul li a:hover {
  background-color: #D5D5D5; }

/* ==========================================================================
   12.0 (Top) Navigation - Desktop (min 768px)
   ========================================================================== */
@media only screen and (min-width: 48em) {
  #topNav {
    width: 100%;
    height: 30px;
    background-color: #2C312A;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    z-index: 10; }
    #topNav ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 30px;
      overflow: hidden; }
      #topNav ul li {
        float: left;
        margin-right: 20px;
        height: 26px;
        display: table;
        margin-top: 4px; }
        #topNav ul li .active a, #topNav ul li:hover {
          color: white; }
        #topNav ul li a {
          color: #969895;
          text-decoration: none;
          height: 26px;
          display: table-cell;
          vertical-align: middle; }
  #menuDesktopTop ul.socialNav {
    margin-top: 4px; }
    #menuDesktopTop ul.socialNav li a {
      padding: 0;
      border-radius: 0; }
      #menuDesktopTop ul.socialNav li a:hover {
        background-color: inherit; }
  #menuDesktopTop ul li.twitter a,
  #topNav ul li.twitter a {
    display: block;
    width: 20px;
    height: 20px;
    background-position: -110px -150px; }
    #menuDesktopTop ul li.twitter a:hover,
    #topNav ul li.twitter a:hover {
      background-position: -110px -230px; }
  #menuDesktopTop ul li.flickr a,
  #topNav ul li.flickr a {
    display: block;
    width: 20px;
    height: 20px;
    background-position: -190px -150px; }
    #menuDesktopTop ul li.flickr a:hover,
    #topNav ul li.flickr a:hover {
      background-position: -190px -230px; }
  #menuDesktopTop ul li.facebook a,
  #topNav ul li.facebook a {
    display: block;
    width: 20px;
    height: 20px;
    background-position: -270px -150px; }
    #menuDesktopTop ul li.facebook a:hover,
    #topNav ul li.facebook a:hover {
      background-position: -270px -230px; }
  #menuDesktopTop ul li.twitter,
  #menuDesktopTop ul li.flickr,
  #topNav ul li.twitter,
  #topNav ul li.flickr {
    margin-right: 5px; }
  #menuDesktopTop ul li.facebook,
  #topNav ul li.facebook {
    margin-right: 5px; }
  #menuDesktopTop ul li.language-switch {
    margin-right: 15px;
    margin-left: 10px; }
    #menuDesktopTop ul li.language-switch a {
      width: 30px;
      height: 20px;
      display: block;
      background-color: #969895;
      color: #FFFFFF;
      text-align: center;
      line-height: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 16px; }
      #menuDesktopTop ul li.language-switch a:hover {
        background-color: #bcbebc; }
  #topNav ul li.language-switch {
    margin-top: 5px;
    margin-right: 0px;
    margin-left: 10px; }
    #topNav ul li.language-switch a {
      width: 30px;
      height: 20px;
      display: block;
      background-color: #969895;
      color: #FFFFFF;
      text-align: center;
      line-height: 20px; }
      #topNav ul li.language-switch a:hover {
        background-color: #bcbebc; }
  #menuDesktopTop {
    background-color: rgba(44, 49, 42, 0.75);
    height: 50px; }
    #menuDesktopTop ul li {
      margin-top: 5px; }
      #menuDesktopTop ul li.active a {
        background-color: #20231e; }
      #menuDesktopTop ul li a {
        color: #bcbcbc; }
        #menuDesktopTop ul li a:hover,
        #menuDesktopTop ul li a .active a {
          background-color: #20231e; }
      #menuDesktopTop ul li:first-child a {
        background-color: transparent;
        color: #fff; }
  #menuDesktop {
    background-color: rgba(217, 54, 0, 0.75);
    height: 80px; }
    #menuDesktop ul {
      padding: 5px 0 0 0; } }
    @media only screen and (min-width: 48em) and (min-width: 60em) {
      #menuDesktop ul {
        padding: 0; } }

@media only screen and (min-width: 48em) {
      #menuDesktop ul li {
        margin-top: 20px; }
        #menuDesktop ul li.active a {
          background-color: #c03000; }
        #menuDesktop ul li a {
          color: white; }
          #menuDesktop ul li a:hover,
          #menuDesktop ul li a .active a {
            background-color: #c03000; }
  #menuDesktopTop,
  #menuDesktop {
    width: 100%;
    font-size: 18px;
    font-family: "Noto Serif", serif;
    position: relative;
    z-index: 10; }
    #menuDesktopTop ul,
    #menuDesktop ul {
      margin: 0 0 0 5px;
      list-style: none;
      display: inline-block; }
      #menuDesktopTop ul li,
      #menuDesktop ul li {
        float: left;
        margin-left: 10px;
        line-height: 1;
        height: 40px; }
        #menuDesktopTop ul li a,
        #menuDesktop ul li a {
          padding: 10px;
          display: block;
          text-decoration: none;
          border-radius: 3px; } }

/* ==========================================================================
   News wrappers
   ========================================================================== */
@media only screen and (min-width: 48em) {
  .single-whd_news #photo {
    height: 610px !important; } }

@media only screen and (min-width: 48em) {
  .news__wrapper {
    margin-top: 500px; } }

.news__wrapper .content-half p,
.news__wrapper .content-half ol,
.news__wrapper .content-half ul {
  font-size: 18px; }

.news__wrapper .content-half p a {
  color: #666600; }
  .news__wrapper .content-half p a:hover {
    text-decoration: underline;
    color: inherit; }

.news__wrapper .content-half p strong {
  font-weight: bold; }

@media only screen and (min-width: 48em) {
  .newsarchive__wrapper {
    margin-top: 150px; } }

/* ==========================================================================
   News navigation
   ========================================================================== */
.news__navigation {
  display: block;
  width: 100%;
  float: left; }
  .news__navigation ul {
    display: table;
    margin: 0 auto;
    text-align: center; }
    .news__navigation ul li {
      float: left;
      display: inline-block;
      padding: 0.8090234858em 1.309em;
      border: 1px solid green; }
  .news__navigation .news__navigation__btn {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin: 5px 0;
    padding: 10px;
    width: auto;
    display: inline-block;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: #666600;
    background-position: center right;
    color: #FFF !important;
    text-decoration: none;
    font-size: 17px;
    font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif; }
    .news__navigation .news__navigation__btn.prev {
      float: left; }
    .news__navigation .news__navigation__btn.next {
      float: right; }
    .news__navigation .news__navigation__btn:hover {
      background-color: #333300; }

/* ==========================================================================
   News item
   ========================================================================== */
.news__item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  float: left;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer; }
  .news__item a {
    color: #2C312A;
    text-decoration: none; }
  .news__item img {
    width: 100%;
    vertical-align: middle; }
    @media only screen and (min-width: 30em) {
      .news__item img {
        float: left;
        width: 33.33%; } }
    @media only screen and (min-width: 48em) {
      .news__item img {
        width: 100%; } }
  .news__item h2 {
    margin: 0;
    font-size: 26px;
    font-family: "Noto Serif", serif;
    line-height: 1; }
  .news__item span {
    display: block;
    margin: 50px 0 30px 0;
    color: #666600;
    font-size: 18px;
    font-family: "Noto Serif", serif; }
  @media only screen and (min-width: 48em) {
    .news__item {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 31.5%; }
      .news__item:last-child {
        margin-right: 0; }
      .news__item.last {
        margin-right: 0; } }
  @media only screen and (min-width: 60em) {
    .news__item {
      width: 31.78%; } }
  .news__item.banners a {
    display: inline-block;
    width: 100%; }
    .news__item.banners a:first-child {
      margin-bottom: 20px; }

.news__item__content {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 25px 20px 30px 20px;
  background-color: white; }
  .news__item__content p {
    line-height: 1.25; }
  @media only screen and (min-width: 30em) {
    .news__item__content {
      width: 66.66%; } }
  @media only screen and (min-width: 48em) {
    .news__item__content {
      float: right;
      width: 100%;
      min-height: 300px; } }

/* ==========================================================================
   Programma single
   ========================================================================== */
@media only screen and (min-width: 48em) {
  .single-whd_arrangement #photo,
  .single-whd_programma #photo {
    height: 610px !important; } }

.single-whd_arrangement .program__header #kaartFilterButtonContainer,
.single-whd_programma .program__header #kaartFilterButtonContainer {
  position: relative; }

.single-whd_arrangement .program__header #map,
.single-whd_programma .program__header #map {
  height: 580px;
  position: absolute !important;
  top: -100%; }

@media only screen and (min-width: 48em) {
  .programma__wrapper {
    margin-top: 450px; } }

.programma__wrapper h1 {
  margin: 0 0 30px 0 !important; }

.programma__wrapper .content-half p,
.programma__wrapper .content-half ol,
.programma__wrapper .content-half ul {
  font-size: 16px; }

.programma__wrapper .content-half em {
  font-weight: 700;
  color: #666600; }

.programma__wrapper .content-half strong {
  font-weight: 700; }

.programma__wrapper .content-half a {
  color: #666600; }

.visitors-info p {
  font-family: "Noto Serif", serif;
  margin: 0 0 30px 0;
  font-size: 20px;
  line-height: 24px; }

.visitors-info .btn {
  margin-bottom: 10px; }

/* ==========================================================================
   Checkout popup
   ========================================================================== */
#checkout {
  height: 85%; }

#checkout iframe {
  width: 100%;
  height: 100%;
  background-color: white;
  background-image: url("../images/ajax-loader-ticket.gif");
  background-position: center center !important;
  background-repeat: no-repeat; }
  @media only screen and (min-width: 48em) {
    #checkout iframe {
      height: 90%;
      min-height: 650px; } }

.checkout-close {
  position: absolute;
  top: 20px;
  right: 6%;
  z-index: 1001;
  width: 110px;
  cursor: pointer;
  padding: 10px; }
  .checkout-close:after {
    top: 10px; }

.checkout-header {
  position: absolute;
  top: 0;
  left: 300px;
  z-index: 1000; }

/* ==========================================================================
   Programma archive
   ========================================================================== */
@media only screen and (min-width: 48em) {
  .post-type-archive-whd_arrangement h1,
  .post-type-archive-whd_programma h1 {
    padding: 50px 0 15px 0; } }

/**
 * Basic MixItUp Styles
 */
#list--arrangement.list li.mix,
#list--arrangement.block li.mix,
#list.list li.mix,
#list.block li.mix {
  display: none; }

#list--arrangement.block.loading li,
#list.block.loading li {
  transition: all 0.3s;
  opacity: 0.2; }

#list--arrangement.block.loading:before,
#list.block.loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  z-index: 100;
  background-image: url(../images/loader.gif);
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 1.309em; }

/**
 * Info overlay window
 */
#infoOverlay {
  position: absolute;
  background-color: #0065BF;
  width: 100%;
  z-index: 200;
  display: none;
  color: white;
  font-size: 15px; }
  #infoOverlay .container {
    background-color: transparent; }
  #infoOverlay .close {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    z-index: 10; }
    #infoOverlay .close a {
      width: 60px;
      height: 60px;
      display: block;
      background-color: #0D5592;
      background-position: -180px -118px; }
  #infoOverlay h3 {
    font-family: "Noto Serif", serif;
    font-size: 20px;
    margin: 0.8090234858em 0 0 0;
    display: block; }
  #infoOverlay ul {
    margin: 0.8090234858em 0 0 0;
    list-style-type: disc;
    padding-left: 20px; }
    #infoOverlay ul li {
      list-style-image: disc;
      list-style: outside;
      display: list-item;
      padding-left: 5px; }
  #infoOverlay .inner {
    padding: 15px; }

/**
* Programma buttons
*/
.program__buttons form.program__search {
  display: inline-block;
  position: relative;
  width: 100%; }
  @media only screen and (min-width: 48em) {
    .program__buttons form.program__search {
      width: 30%;
      float: right; } }
  .program__buttons form.program__search input[type=search] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px 46px 15px 15px;
    background-color: #E8E8E8;
    color: #8a8c89;
    font-size: 18px;
    font-family: "Noto Serif", serif;
    width: 100%;
    display: block;
    position: relative;
    text-decoration: none;
    margin-bottom: 1.309em;
    border: none; }
  .program__buttons form.program__search input[type=submit] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    background-color: #b5b5b5;
    font-size: 18px;
    font-family: "Noto Serif", serif;
    display: block;
    position: absolute;
    text-decoration: none;
    border: none;
    border-radius: 0;
    margin-right: 0;
    background-image: url(../images/slice.png);
    background-position: -14px -135px; }

.program__buttons .btn {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 46px 15px 15px;
  border-radius: 3px;
  background-color: #E8E8E8;
  color: #8a8c89;
  font-size: 18px;
  font-family: "Noto Serif", serif;
  width: 100%;
  display: block;
  position: relative;
  text-decoration: none;
  margin-bottom: 1.309em; }
  .program__buttons .btn:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px; }
  .program__buttons .btn:hover {
    background-color: #0065bf;
    color: white; }
  @media only screen and (min-width: 48em) {
    .program__buttons .btn {
      width: 30%;
      float: left; } }

@media only screen and (min-width: 48em) {
  .program__buttons .btn {
    margin-bottom: 2.617924em; }
  .program__buttons .btn + .btn {
    margin-left: 1.309em; } }

.btn--info:before {
  background-repeat: no-repeat;
  background-position: 5px 6px; }

.btn--info:hover:before {
  background-position: 5px -90px; }

.btn--filter:before {
  background-repeat: no-repeat;
  background-position: 2px 3px; }

.btn--filter:hover:before {
  background-position: 2px -38px; }

.sidebar-open .btn--filter:before {
  background-repeat: no-repeat;
  background-position: 2px 9px;
  background-image: url("../images/min-negativ.png"); }

.sidebar-open .btn--filter:hover:before {
  background-position: 2px -20px; }

.btn--reset {
  padding: 15px 46px 15px 15px;
  border-radius: 3px;
  background-color: #E8E8E8;
  color: white;
  font-size: 18px;
  font-family: "Noto Serif", serif;
  border: none;
  outline: none;
  background: #D93600;
  position: relative; }
  .btn--reset:after {
    position: absolute;
    top: 17px;
    right: 15px;
    display: inline-block;
    width: 11px;
    height: 20px;
    background-image: url("../images/slice.png");
    background-position: -356px -158px;
    content: ""; }
  .btn--reset:hover {
    background: #a62900; }

/**
* Programma archive page
*/
#content {
  position: relative;
  background-color: white; }
  #content .container .wrapper {
    padding: 15px; }

.container--border {
  border-top: 1px solid #E8E8E8;
  padding-top: 60px; }

.program h1 {
  font-family: "Noto Serif", serif;
  font-size: 30px;
  margin: 18px 0 20px 0 !important;
  display: block;
  width: 100%; }

/**
* Programma list
*/
#list--arrangement,
#list {
  position: relative;
  overflow: hidden; }

#list--arrangement,
#list {
  min-height: 1200px; }

#list--arrangement li.fail-message,
#list li.fail-message {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  margin-top: 1.309em;
  display: none; }
  #list--arrangement li.fail-message h3,
  #list li.fail-message h3 {
    color: #D93600;
    font-size: 1.3em;
    font-family: "Noto Serif", serif; }
  #list--arrangement li.fail-message .span,
  #list li.fail-message .span {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    font-weight: 700; }

#list--arrangement.fail li.fail-message,
#list.fail li.fail-message {
  display: block; }

#list--arrangement.list,
#list.list {
  margin: 0; }
  #list--arrangement.list li,
  #list.list li {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #E7E7E7;
    margin: 10px 0;
    padding: 0.8090234858em;
    width: 100%;
    position: relative; }
    #list--arrangement.list li:hover .title,
    #list--arrangement.list li:hover p,
    #list.list li:hover .title,
    #list.list li:hover p {
      color: #666600; }
    #list--arrangement.list li:hover .button,
    #list.list li:hover .button {
      background-color: #005199; }
  #list--arrangement.list .list__content,
  #list.list .list__content {
    width: 80%; }
  #list--arrangement.list a,
  #list.list a {
    color: #2C312A;
    text-decoration: none;
    display: block; }
  #list--arrangement.list .button,
  #list.list .button {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    display: block;
    background-color: #0065BF;
    background-image: none; }
    #list--arrangement.list .button:after,
    #list.list .button:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -5px;
      margin-top: -7px;
      width: 12px;
      height: 16px;
      background-position: -145px -22px; }
  #list--arrangement.list .title,
  #list.list .title {
    font-family: "Noto Serif", serif;
    color: #2C312A;
    margin-bottom: 0.8090234858em; }
  #list--arrangement.list .image,
  #list--arrangement.list span,
  #list--arrangement.list .tags,
  #list.list .image,
  #list.list span,
  #list.list .tags {
    display: none; }
  #list--arrangement.list .list__line,
  #list.list .list__line {
    display: none; }

#list--arrangement.block,
#list.block {
  padding: 0;
  list-style: none;
  margin-left: -1.309em;
  display: -ms-flexbox;
  display: -webkit-flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @supports (flex-wrap: wrap) {
    #list--arrangement.block,
    #list.block {
      /* hide from the incomplete Flexbox implementation in Firefox */
      display: flex; } }
  #list--arrangement.block li,
  #list.block li {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.5;
    float: left;
    width: 25%;
    padding-left: 1.309em;
    margin-bottom: 1.309em;
    position: relative;
    overflow: hidden; }
    #list--arrangement.block li .image,
    #list.block li .image {
      position: relative; }
    #list--arrangement.block li .list__content span,
    #list.block li .list__content span {
      color: #8a8c89;
      margin: 20px 0;
      display: block;
      font-size: .9375em;
      min-height: 22px; }
    #list--arrangement.block li .title a,
    #list.block li .title a {
      font-family: "Noto Serif", serif;
      font-size: 20px;
      margin-bottom: 25px;
      line-height: 1.2;
      color: #2C312A;
      min-height: 75px;
      max-height: 75px;
      overflow: hidden;
      display: block; }
    #list--arrangement.block li p,
    #list.block li p {
      color: #2C312A;
      font-size: 1em;
      min-height: 74px; }
    #list--arrangement.block li .tags,
    #list.block li .tags {
      margin-top: 35px;
      display: block;
      font-size: .9375em;
      min-height: 22px; }
    #list--arrangement.block li .list__content,
    #list.block li .list__content {
      padding-bottom: 1.309em; }
    #list--arrangement.block li .list__line,
    #list.block li .list__line {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: #E8E8E8;
      width: 100%;
      bottom: 0;
      right: -1.309em;
      border: none;
      height: 1px;
      position: absolute; }

@media only screen and (min-width: 48em) {
  .sidebar-open #list--arrangement.block,
  .sidebar-open #list.block {
    width: 75%;
    float: right;
    min-height: 170px; }
    .sidebar-open #list--arrangement.block li,
    .sidebar-open #list.block li {
      width: 33.33%; } }

/**
 * Sentence filter
 */
.step__wrapper {
  width: 100%;
  float: left; }
  @media only screen and (min-width: 48em) {
    .step__wrapper {
      width: 49%;
      margin-right: 2%; }
      .step__wrapper:last-child {
        margin-right: 0; } }
  @media only screen and (min-width: 60em) {
    .step__wrapper {
      margin-right: 0; } }

.filter__sentence {
  display: none;
  border-top: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  padding: 45px 0 25px 0;
  font-size: 18px;
  position: relative;
  margin-bottom: 2.617924em; }
  .filter__sentence.active {
    display: block; }
  @media only screen and (min-width: 30em) {
    .filter__sentence {
      padding: 45px 0 15px 0;
      margin-bottom: 2.617924em; } }
  @media only screen and (min-width: 48em) {
    .filter__sentence {
      padding: 40px 0 20px 0;
      font-size: 22px; } }
  @media only screen and (min-width: 80em) {
    .filter__sentence {
      padding: 40px 0 20px 0;
      font-size: 22px; } }
  .filter__sentence .text {
    display: block;
    float: left;
    margin-bottom: 20px;
    width: 100%; }
    @media only screen and (min-width: 48em) {
      .filter__sentence .text {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 60em) {
      .filter__sentence .text {
        display: inline-block;
        margin-bottom: 0;
        width: auto; } }
  @media only screen and (min-width: 60em) {
    .filter__sentence .text--first,
    .filter__sentence .text--second {
      margin-right: 20px; } }

.filter__sentence__step {
  float: left;
  margin: -10px 0 20px 0;
  font-size: 18px;
  position: relative;
  z-index: 10;
  width: 100%; }
  @media only screen and (min-width: 48em) {
    .filter__sentence__step {
      width: 100%;
      float: left; } }
  @media only screen and (min-width: 60em) {
    .filter__sentence__step {
      margin: 0 0 16px 0;
      font-size: 22px;
      width: auto; } }
  .filter__sentence__step .select2-container a.select2-choice {
    background-color: red;
    border: 1px solid #E8E8E8;
    background-color: #F8F8F8;
    font-size: 22px;
    padding: 8px 0 8px 8px; }
    @media only screen and (min-width: 48em) {
      .filter__sentence__step .select2-container a.select2-choice span {
        width: 200px; } }
    @media only screen and (min-width: 60em) {
      .filter__sentence__step .select2-container a.select2-choice span {
        width: 240px; } }
  @media only screen and (min-width: 60em) {
    .filter__sentence__step #s2id_planning_2 a.select2-choice span {
      width: 160px; } }
  @media only screen and (min-width: 80em) {
    .filter__sentence__step #s2id_planning_2 a.select2-choice span {
      width: 200px; } }
  .filter__sentence__step select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 10px;
    font-family: "Noto Serif", serif;
    border: 1px solid #E8E8E8;
    border-radius: 3px;
    margin-right: 13px;
    padding: 10px;
    cursor: pointer; }
    @media only screen and (min-width: 48em) {
      .filter__sentence__step select {
        margin-right: 8px; } }
    .filter__sentence__step select:focus {
      outline: none; }

.filter__sentence__close {
  width: 12px;
  height: 12px;
  display: block;
  margin-bottom: 20px;
  position: absolute;
  right: 0;
  top: 20px;
  background-position: -196px -248px; }

.post-type-archive-whd_arrangement .select2-results,
.post-type-archive-whd_programma .select2-results {
  border: 1px solid #f2f2f2;
  margin: 0 0; }

/**
 * Sidebar filter
 */
.program__filter {
  display: none;
  width: 100%;
  font-family: "Noto Serif", serif;
  padding: 1.309em 0 0 0; }
  @media only screen and (min-width: 48em) {
    .program__filter {
      width: 33.33%; } }
  .program__filter form {
    margin-bottom: 30px;
    font-size: 18px;
    color: #8a8c89; }
    .program__filter form ul {
      margin: 0;
      padding: 0; }
    .program__filter form .intro {
      color: #8a8c89;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 1.309em; }
  .program__filter label {
    display: block;
    cursor: pointer;
    position: relative;
    padding: 0 0 30px 0; }
    .program__filter label:hover {
      color: #2C312A; }
    @media only screen and (min-width: 48em) {
      .program__filter label {
        padding: 0 0 30px 40px; } }
  .program__filter .text {
    color: #2c312a;
    font-size: 20px;
    margin-bottom: 30px;
    padding-left: 40px; }
    .program__filter .text:hover {
      color: #666600; }
  .program__filter .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background-color: #E8E8E8;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: 0;
    border: none; }
    @media only screen and (min-width: 48em) {
      .program__filter .checkbox {
        left: 0; } }
    .program__filter .checkbox:focus {
      outline: none; }

.program__filter .checkbox:checked:after {
  content: '';
  background-image: url("../images/checkmark@2x.png");
  background-size: 19px 16px;
  width: 19px;
  height: 16px;
  position: absolute;
  top: 7px;
  left: 5px; }

.program__filter .active {
  color: #2C312A; }

.sidebar-open .program__filter {
  display: block;
  width: 100%; }
  @media only screen and (min-width: 48em) {
    .sidebar-open .program__filter {
      width: 25%;
      float: left; } }

/**
 * Share program
 */
.share--program {
  position: absolute;
  right: 15px;
  top: 40px; }
  .share--program p {
    display: none; }
    @media only screen and (min-width: 30em) {
      .share--program p {
        padding-top: 15px;
        display: inline-block; } }
  @media only screen and (min-width: 30em) {
    .share--program {
      top: 24px; } }
  @media only screen and (min-width: 48em) {
    .share--program {
      right: 0;
      top: 80px; } }

.single-whd_arrangement .share--program,
.single-whd_programma .share--program {
  position: relative;
  float: right;
  height: 40px;
  top: 0;
  right: 0;
  margin: 0 0 20px 0; }
  .single-whd_arrangement .share--program p,
  .single-whd_programma .share--program p {
    display: inline-block;
    padding-top: 15px; }

.filter__tag {
  color: #D93600;
  margin: 1.309em 0;
  display: inline-block; }

.remove__tag {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  background-image: url("../images/remove.png");
  background-size: 10px 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 3px; }
  .remove__tag:before {
    content: "";
    display: block;
    width: 0;
    height: 100%; }

/* ==========================================================================
   Socials homepage
   ========================================================================== */
.socials {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  width: 100%;
  font-family: "Noto Serif", serif; }
  @media only screen and (min-width: 48em) {
    .socials {
      width: 50%; } }
  .socials img {
    vertical-align: middle; }
  .socials a {
    margin: 0;
    color: white;
    text-decoration: underline;
    line-height: 1.25; }

/* Socials Twitter & Facebook
   ========================================================================== */
.social--twitter,
.social--facebook {
  position: relative;
  float: left;
  margin-bottom: 20px;
  width: 100%; }
  .social--twitter .social__image,
  .social--facebook .social__image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: none;
    background-position: center center;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
  @media only screen and (min-width: 30em) {
    .social--twitter,
    .social--facebook {
      width: 100%;
      min-height: 215px; }
      .social--twitter .social__image,
      .social--facebook .social__image {
        width: 50%;
        display: block;
        float: right; } }

.social--facebook {
  background-color: #335b99; }
  .social--facebook .social__image {
    background-image: url(../images/placeholder-fb.jpg); }

.social--twitter {
  background-color: #0085b5; }
  .social--twitter .social__image {
    background-image: url(../images/placeholder-tw.jpg); }

.social__content {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  padding: 20px 20px 40px 20px;
  width: 100%;
  color: white; }
  @media only screen and (min-width: 30em) {
    .social__content {
      width: 50%; } }

.social__content:before {
  display: block;
  margin-bottom: 15px;
  width: 20px;
  height: 25px;
  background-image: url("../images/slice.png");
  content: ""; }

.social--facebook .social__content:before {
  background-position: -593px -228px; }

.social--twitter .social__content:before {
  background-position: -670px -228px; }

/* Socials instagram
   	========================================================================== */
.social--instagram {
  float: right;
  display: inline-block;
  position: relative; }
  .social--instagram:after {
    content: "";
    position: absolute;
    right: 0;
    top: 8px;
    width: 92px;
    height: 26px;
    background-image: url("../images/instagram.png"); }
  .social--instagram .instagram__img {
    padding: 12px;
    background-color: #bdb6b0;
    display: inline-block; }
    .social--instagram .instagram__img img {
      width: 100%; }
  .social--instagram .instagram__avatar {
    border-radius: 3px;
    margin: 0 10px 10px 0;
    border: 1px solid #929aa2; }
  .social--instagram .instagram__name {
    color: #3f729b; }
  .social--instagram ul {
    margin-top: 10px; }
    .social--instagram ul li {
      float: left;
      color: #c1c1c1;
      margin-right: 10px; }
      .social--instagram ul li:before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 13px;
        margin-right: 10px;
        background-image: url("../images/insta-hart.png");
        background-repeat: no-repeat; }
  @media only screen and (min-width: 48em) {
    .social--instagram {
      display: block;
      margin-left: 1.309em; } }

.social--instagram header img {
  width: 30px; }

/* ==========================================================================
   Mijn WHD
   ========================================================================== */
.mijn-whd strong {
  font-weight: bold; }

.mijn-whd .one-third {
  width: 32%;
  float: right; }

.mijn-whd .two-third {
  width: 65%;
  float: left; }

.mijn-whd .mijn-whd__intro {
  margin-bottom: 60px; }
  .mijn-whd .mijn-whd__intro.blue,
  .mijn-whd .mijn-whd__intro p {
    color: #D93600; }

.mijn-whd .mijn-whd__hr {
  border: none;
  margin-top: 20px;
  margin-bottom: 60px;
  display: inline-block;
  height: 1px;
  background-color: #E8E8E8;
  width: 100%; }

.mijn-whd .mijn-whd__subnav {
  position: absolute;
  top: 0;
  right: 0; }

.mijn-whd .mijn-whd__tag {
  color: #8a8c89;
  margin: 0 0 15px 0;
  display: block;
  font-size: .9375em;
  min-height: 22px; }

.mijn-whd .mijn-whd__title {
  margin-top: 0 !important; }

.mijn-whd h1 {
  font-size: 30px;
  font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 15px 0 !important; }

.mijn-whd h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 26px;
  font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif;
  padding-right: 75px; }

.mijn-whd h3 {
  font-size: 20px;
  font-family: "good-bold","Helvetica Neue",Helvetica,Arial,sans-serif; }
  .mijn-whd h3.title-account {
    margin-top: 150px;
    margin-bottom: 10px; }

.mijn-whd p {
  line-height: 22px;
  margin-bottom: 20px; }

.mijn-whd .mijn-whd__form input[type="checkbox"],
.mijn-whd .mijn-whd__form input[type="radio"] {
  margin-top: 5px !important; }

.mijn-whd .mijn-whd__form .field_sublabel_above .ginput_right label {
  margin-top: 10px !important; }

.mijn-whd .mijn-whd__form .ginput_left,
.mijn-whd .mijn-whd__form .ginput_right {
  width: 100% !important;
  float: left;
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mijn-whd #field_6_7 .gfield_label {
  display: none; }

.mijn-whd .btn--register {
  background-color: #E8E8E8;
  color: #2C312A;
  padding: 20px 45px 20px 20px;
  position: relative;
  width: auto;
  background-image: url(../images/icon--next-dark.svg);
  background-repeat: no-repeat;
  background-position: center right;
  float: right;
  margin: 0 20px 0 0; }
  .mijn-whd .btn--register:hover {
    color: white;
    background-color: #2C312A;
    background-image: url(../images/icon--next.svg); }

.mijn-whd .mijn-whd__form.registratie input[type=submit],
.mijn-whd #loginform input[type=submit] {
  background-color: #D93600;
  padding: 19px 45px 19px 20px;
  position: relative;
  background-image: url(../images/icon--next.svg);
  background-repeat: no-repeat;
  background-position: center right;
  float: right;
  margin: 0;
  text-indent: 0;
  border: none;
  color: #fff; }
  .mijn-whd .mijn-whd__form.registratie input[type=submit]:hover,
  .mijn-whd #loginform input[type=submit]:hover {
    background-color: #a62900; }

.mijn-whd .mijn-whd__form.profiel input[type=submit] {
  background-color: #D93600;
  padding: 19px 45px 19px 20px;
  position: relative;
  background-image: url(../images/icon--submit.svg);
  background-repeat: no-repeat;
  background-position: center right;
  float: right;
  margin: 0;
  text-indent: 0;
  border: none;
  color: #fff; }
  .mijn-whd .mijn-whd__form.profiel input[type=submit]:hover {
    background-color: #a62900; }

.mijn-whd .mijn-whd__form.registratie label,
.mijn-whd .mijn-whd__form.profiel label,
.mijn-whd #loginform label {
  display: block;
  margin-bottom: 10px; }
  .mijn-whd .mijn-whd__form.registratie label a,
  .mijn-whd .mijn-whd__form.profiel label a,
  .mijn-whd #loginform label a {
    font-weight: bold; }

.mijn-whd .mijn-whd__form.registratie input[type=password],
.mijn-whd .mijn-whd__form.registratie input[type=text],
.mijn-whd .mijn-whd__form.profiel input[type=password],
.mijn-whd .mijn-whd__form.profiel input[type=text],
.mijn-whd #loginform input[type=password],
.mijn-whd #loginform input[type=text] {
  font-family: "Open Sans", sans-serif;
  border: none;
  width: 100%;
  padding: 20px !important;
  background-color: #E8E8E8;
  box-sizing: border-box; }

/* ==========================================================================
   Newsletter
   ========================================================================== */
.newsletter {
  position: relative;
  padding: 50px 15px 40px 15px;
  background-color: #303030;
  color: white; }
  .newsletter#hoofdsponsoren {
    text-align: center;
    padding: 0 15px 40px 15px; }
    .newsletter#hoofdsponsoren img {
      margin-left: 30px;
      margin-right: 30px;
      max-height: 60px; }
  .newsletter h2 {
    display: block;
    margin: 0;
    font-family: "Noto Serif", serif; }
  .newsletter p {
    display: block;
    margin: 6px 0 20px 0; }
    @media only screen and (min-width: 60em) {
      .newsletter p {
        margin: 6px 0 0 0; } }

.newsletter__form {
  position: relative; }
  @media only screen and (min-width: 60em) {
    .newsletter__form {
      position: absolute;
      top: -5px;
      right: 0;
      width: 62%; } }

.newsletter__email {
  float: left;
  width: 100%; }
  .newsletter__email input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 20px 10px 0;
    padding: 20px 20px 20px 60px;
    width: 98%;
    border: none;
    border-radius: 3px;
    background-image: url("../images/mail.png");
    background-position: 20px 22px;
    background-repeat: no-repeat;
    outline: none; }
  @media only screen and (min-width: 48em) {
    .newsletter__email {
      max-width: 530px; } }
  @media only screen and (min-width: 60em) {
    .newsletter__email {
      margin: 0;
      width: 420px; } }

label.error {
  display: block;
  color: #666600;
  float: left;
  margin-bottom: 1.309em; }
  @media only screen and (min-width: 60em) {
    label.error {
      margin: 0 20px 0 0;
      width: 350px;
      position: absolute;
      top: -25px; } }

.newsletter__submit {
  position: relative;
  display: inline-block;
  padding: 17px 40px 19px 20px;
  border: none;
  border-radius: 3px;
  background-color: black;
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-family: "Noto Serif", serif;
  float: left;
  outline: none; }
  .newsletter__submit:after {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    width: 11px;
    height: 20px;
    background-image: url("../images/slice.png");
    background-position: -356px -158px;
    content: ""; }
    @media only screen and (min-width: 60em) {
      .newsletter__submit:after {
        top: 22px; } }
  .newsletter__submit.loading:after {
    background-image: url("../images/ajax-loader.gif");
    background-repeat: no-repeat;
    background-position: center center; }
  .newsletter__submit:hover {
    background-color: #333300; }
  @media only screen and (min-width: 48em) {
    .newsletter__submit {
      float: right; } }

.newsletter__result {
  display: none;
  position: relative; }
  @media only screen and (min-width: 60em) {
    .newsletter__result {
      position: absolute;
      top: 21px;
      right: 0;
      width: 62%;
      margin-right: 0; } }
  .newsletter__result .result {
    color: white;
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    line-height: 20px; }
    @media only screen and (min-width: 48em) {
      .newsletter__result .result {
        margin-top: 52px; } }
  @media only screen and (min-width: 48em) {
    .newsletter__result {
      max-width: 530px; }
      .newsletter__result .again {
        float: right; } }
  @media only screen and (min-width: 60em) {
    .newsletter__result {
      margin: -50px 20px 0 0;
      width: 60%;
      float: right; } }

.event__picture {
  position: relative; }
  .event__picture img {
    position: relative; }

#list.block li .list__content span.ticket__lable,
.ticket__lable {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: rgba(48, 48, 48, 0.6);
  color: white;
  position: absolute;
  padding: 0.8090234858em;
  bottom: 0px;
  right: 50px;
  height: 50px;
  margin: 0; }
  #list.block li .list__content span.ticket__lable strong,
  .ticket__lable strong {
    font-family: "Noto Serif", serif; }
  #list.block li .list__content span.ticket__lable:after,
  .ticket__lable:after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 0;
    right: -50px;
    display: inline-block;
    background-color: #666600;
    background-position: -100px -367px;
    background-image: url("../images/slice.png"); }

.content-sidebar {
  float: left;
  margin-top: 40px; }
  .content-sidebar .table {
    width: 100%;
    float: left; }
    .content-sidebar .table tr {
      margin-bottom: 15px;
      float: left;
      width: 100%; }
      .content-sidebar .table tr:last-child {
        margin-bottom: 0; }
    .content-sidebar .table .lable {
      width: 40%;
      font-family: "Noto Serif", serif;
      float: left; }
      @media only screen and (min-width: 30em) {
        .content-sidebar .table .lable {
          width: 25%; } }
      @media only screen and (min-width: 48em) {
        .content-sidebar .table .lable {
          width: 40%; } }
    .content-sidebar .table .lable__info {
      width: 58%;
      float: left; }
      @media only screen and (min-width: 30em) {
        .content-sidebar .table .lable__info {
          width: 73%; } }
      @media only screen and (min-width: 48em) {
        .content-sidebar .table .lable__info {
          width: 58%; } }
  @media only screen and (min-width: 48em) {
    .content-sidebar {
      margin-top: 0;
      width: 33.33%;
      float: right; } }
  @media only screen and (min-width: 30em) {
    .content-sidebar .btn--ticket {
      width: 50%; } }
  @media only screen and (min-width: 48em) {
    .content-sidebar .btn--ticket {
      width: 100%; } }

.share, .share--program {
  margin: 14px 0 0 0;
  font-size: 14px;
  margin-bottom: 40px; }
  @media only screen and (min-width: 48em) {
    .share, .share--program {
      margin-top: -15px;
      float: right; } }
  .share p, .share--program p {
    color: #8a8c89; }

.share__socials {
  display: inline-block;
  float: right;
  margin-top: 0;
  margin-left: 0; }

.share__twitter,
.share__facebook {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-indent: -9999px;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 3px;
  float: left; }

.share__twitter {
  background-color: #00aeff;
  margin: 0 10px;
  background-position: -70px -181px; }
  .share__twitter:hover {
    background-color: #0098fd; }

.share__facebook {
  background-color: #3b5998;
  background-position: -131px -181px; }
  .share__facebook:hover {
    background-color: #3b408b; }

.content-sidebar__event {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #E8E8E8;
  padding: 40px;
  line-height: 1.5;
  float: left;
  width: 100%; }
  @media only screen and (min-width: 30em) {
    .content-sidebar__event {
      width: 48%;
      margin-right: 2%; } }
  @media only screen and (min-width: 48em) {
    .content-sidebar__event {
      width: 100%;
      margin-left: 0; } }
  .content-sidebar__event img {
    width: 100%; }
  .content-sidebar__event span {
    color: #8a8c89;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block; }
  .content-sidebar__event .title {
    font-family: "Noto Serif", serif;
    font-size: 20px;
    margin-bottom: 50px;
    line-height: 1.2; }
  .content-sidebar__event .tags {
    margin-top: 35px;
    display: block; }
  .content-sidebar__event .event__picture .ticket__lable {
    bottom: 0; }

.content-sidebar__banner {
  width: 100%;
  float: left;
  margin-top: 40px; }
  @media only screen and (min-width: 30em) {
    .content-sidebar__banner {
      width: 48%;
      margin-top: 0;
      margin-left: 2%; } }
  @media only screen and (min-width: 48em) {
    .content-sidebar__banner {
      width: 100%;
      margin-top: 40px;
      margin-left: 0; } }
  .content-sidebar__banner img {
    width: 100%;
    vertical-align: middle; }
  .content-sidebar__banner table {
    float: left;
    margin-top: 30px; }
    .content-sidebar__banner table a {
      text-decoration: none; }
    .content-sidebar__banner table tr {
      margin-bottom: 5px;
      display: block;
      line-height: 24px; }
    @media only screen and (min-width: 48em) {
      .content-sidebar__banner table {
        margin-top: 50px; } }
  @media only screen and (min-width: 48em) {
    .content-sidebar__banner {
      margin-bottom: 0; } }

@media only screen and (min-width: 30em) {
  .single-whd_arrangement .content-sidebar__banner,
  .single-whd_programma .content-sidebar__banner {
    margin-top: 40px;
    margin-left: 0; } }

/* ==========================================================================
   Schedule (programma detail)
   ========================================================================== */
.schedule {
  float: left;
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
  width: 100%;
  font-family: "Noto Serif", serif; }
  .schedule li {
    margin-bottom: 30px;
    position: relative; }
    @media only screen and (min-width: 48em) {
      .schedule li {
        margin-bottom: 20px; } }
    .schedule li:last-child {
      margin-bottom: 0; }
    .schedule li::before {
      content: none !important; }
    .schedule li .schedule__date {
      display: inline-block;
      float: left;
      width: 50%; }
      @media only screen and (min-width: 48em) {
        .schedule li .schedule__date {
          padding-top: 20px;
          width: 25%; } }
    .schedule li .schedule__time {
      display: inline-block;
      float: left;
      width: 50%; }
      @media only screen and (min-width: 30em) {
        .schedule li .schedule__time {
          display: block;
          width: 100%;
          margin-top: 10px; } }
      @media only screen and (min-width: 48em) {
        .schedule li .schedule__time {
          padding-top: 10px;
          width: 25%;
          text-align: left;
          padding-left: 0.8090234858em; } }
    .schedule li .btn {
      float: right;
      margin-top: 20px;
      margin-bottom: 0; }
      @media only screen and (min-width: 30em) {
        .schedule li .btn {
          width: 50%;
          margin-top: -46px; } }
      @media only screen and (min-width: 48em) {
        .schedule li .btn {
          margin-top: -5px;
          width: 33.33%; } }

.timeschedule table {
  width: 100%;
  font-size: 18px;
  background-color: white;
  margin-bottom: 20px; }
  .timeschedule table th.corner {
    vertical-align: bottom; }
    .timeschedule table th.corner span {
      display: block;
      position: relative; }
      .timeschedule table th.corner span:after {
        position: absolute;
        right: 15px;
        bottom: 0px;
        display: block;
        width: 12px;
        height: 12px;
        border-right: 4px solid white;
        border-bottom: 4px solid white;
        content: ""; }
  .timeschedule table tr:first-child td {
    padding-top: 20px;
    padding-bottom: 5px; }
  .timeschedule table td.no-border,
  .timeschedule table tr:last-child td {
    border-bottom: none; }
  .timeschedule table td {
    text-align: left;
    border-bottom: 2px solid #D93600;
    padding: 5px 0; }
    .timeschedule table td:first-child, .timeschedule table td:last-child {
      width: 15px; }
    .timeschedule table td:nth-child(2) {
      width: 140px; }
    .timeschedule table td:nth-child(3) {
      width: 510px; }
    .timeschedule table td:nth-child(4) {
      width: 140px; }
    .timeschedule table td:nth-child(5) {
      width: 140px; }
    .timeschedule table td.url {
      text-align: right;
      white-space: nowrap; }
  .timeschedule table th {
    background-color: #D93600;
    color: #fff;
    font-size: 22px;
    font-family: "Noto Serif", serif;
    font-weight: normal;
    text-align: left;
    padding: 15px 0; }
    .timeschedule table th:first-child, .timeschedule table th:last-child {
      padding: 15px 0;
      width: 15px; }
    .timeschedule table th:nth-child(2) {
      width: 650px; }
    .timeschedule table th:nth-child(3) {
      width: 140px; }
    .timeschedule table th:nth-child(4) {
      width: 140px; }
    .timeschedule table th.location {
      font-family: "Open Sans", sans-serif; }
  .timeschedule table .time {
    font-family: "Noto Serif", serif;
    color: #D93600;
    white-space: nowrap; }

.timeschedule .mobile tr:first-child td {
  padding-top: 20px;
  padding-bottom: 5px; }

.timeschedule .mobile th {
  padding: 15px 0; }

.timeschedule .mobile td {
  padding: 5px 0; }

.timeschedule .mobile th:nth-child(2),
.timeschedule .mobile td:nth-child(2) {
  width: 96%; }

.timeschedule .mobile th:nth-child(1),
.timeschedule .mobile th:nth-child(3),
.timeschedule .mobile td:nth-child(1),
.timeschedule .mobile td:nth-child(3) {
  width: 2%; }

.timeschedule .mobile .location {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  margin-top: 10px; }

.timeschedule .mobile .time {
  display: block; }

.timeschedule .mobile .title {
  display: block;
  margin: 5px 0; }

.timeschedule .mobile .url {
  float: right; }

/**
 * DEAFULT COLORS:
 * Grijs: $whd-gray
 * Rood: $theme-color-02
 * Donker rood: $theme-color-02-dark;
 * Donkerder rood: $theme-color-02-darker
 */
/* ==========================================================================
   0.0 Base
   ========================================================================== */
::-webkit-input-placeholder {
  color: #ADADAD;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

:-moz-placeholder {
  color: #ADADAD;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

::-moz-placeholder {
  color: #ADADAD;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

:-ms-input-placeholder {
  color: #ADADAD;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

select,
input {
  color: #ADADAD;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

/* ==========================================================================
   Content sections home
   ========================================================================== */
.content-section {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 15px; }

.content-section h1 {
  margin: 0;
  font-size: 34px;
  font-family: "Noto Serif", serif; }

.content-section__header {
  margin: 0 0 25px 0; }

.content-section__header h1 {
  display: block;
  color: #2C312A;
  font-size: 34px;
  font-family: "Noto Serif", serif;
  margin: 0 0 10px 0; }

.content-section__header a {
  position: relative;
  color: #303030;
  text-decoration: none;
  font-family: "Noto Serif", serif; }

.content-section__header a:after {
  position: absolute;
  width: 11px;
  height: 17px;
  content: "";
  margin-left: 10px;
  background-image: url("../images/slice.png");
  background-position: -675px -312px; }

.content-half {
  float: left;
  font-size: 16px;
  line-height: 22px; }
  .content-half p {
    margin-bottom: 30px; }
    .content-half p:last-child {
      margin-bottom: 0; }
  .content-half ul {
    margin-bottom: 1.309em; }
    .content-half ul li {
      margin-bottom: 0.3819820591em;
      float: none;
      padding-left: 20px;
      position: relative; }
      .content-half ul li:before {
        content: "•";
        display: inline-block;
        position: absolute;
        left: 0;
        color: #D93600; }

.content-half h1 {
  font-family: "Noto Serif", serif;
  font-size: 37px;
  line-height: 42px;
  margin-bottom: 30px; }

.content-half p {
  margin-bottom: 1.618em; }

.content-half span {
  font-family: "Noto Serif", serif;
  font-size: 18px;
  color: #e8122e;
  margin-bottom: 30px;
  display: block; }

/* ==========================================================================
   Algemeen
   ========================================================================== */
.alignright {
  float: right;
  margin-bottom: 5px;
  margin-left: 15px; }

.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px; }

.size-full {
  max-width: 100%; }

.printText {
  display: none;
  visibility: hidden; }

#printLogo {
  display: none; }

#background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#photoSlide {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%; }

#photoSlide .photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#photoSlide .photoSlide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

/* ==========================================================================
   4.0 Content pagina - Mobile
   ========================================================================== */
#text {
  position: relative;
  background-color: white; }

#textWrapper {
  padding: 0; }

.mainBlock {
  float: left;
  clear: both;
  width: 100%; }

.mainBlock .title {
  margin: 0;
  padding: 15px; }

.mainBlock .title span {
  float: right;
  margin-top: -5px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  background-position: center center; }

.mainBlock .title.active span {
  background-color: #E7E7E7; }

.mainBlock .wrapper {
  padding: 15px; }

.btnRed .title {
  background-color: #666600;
  color: white; }

.btnRed .title.active {
  background-color: white;
  color: #666600; }

.btnRed .title span {
  background-color: #333300; }

.btnBlue .title {
  background-color: #D93600;
  color: white; }

.btnBlue .title.active {
  background-color: white;
  color: #D93600; }

.btnBlue .title span {
  background-color: #0E5691; }

.btnGrey .title {
  background-color: #E7E7E7;
  color: #2B322B; }

.btnGrey .title.active {
  background-color: white;
  color: #2B322B; }

.btnGrey .title span {
  background-color: #FAFAFA; }

/* FAQ */
#page_5842 ul,
#page_52 ul {
  margin: 0;
  padding: 0;
  max-width: 630px;
  border-top: 2px solid #666600;
  list-style: none; }

#page_5842 ul li,
#page_52 ul li {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #666600; }

#page_5842 ul li h2,
#page_52 ul li h2 {
  padding: 10px 0 5px 0;
  color: #666600;
  font-weight: bold;
  font-size: 18px; }

#page_5842 ul li h3,
#page_52 ul li h3 {
  padding: 8px 0 8px 50px;
  background-image: url("../images/plus.gif");
  background-position: 12px 8px;
  background-repeat: no-repeat;
  font-weight: bold;
  font-size: 17px;
  font-family: "Open Sans", sans-serif; }

#page_5842 ul li h3.active,
#page_52 ul li h3.active {
  background-image: url("../images/min.gif");
  background-position: 12px 14px; }

#page_5842 ul li h3:hover,
#page_52 ul li h3:hover {
  cursor: pointer; }

#page_5842 ul li p,
#page_52 ul li p {
  padding-left: 50px; }

/* ==========================================================================
   6.0 Programma - Mobile
   ========================================================================== */
#photo {
  position: relative;
  width: 100%;
  height: 280px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#photo .next {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -30px;
  width: 60px;
  height: 60px; }

#photo .prev {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -30px;
  width: 60px;
  height: 60px; }

#photo .prev a,
#photo .next a {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #2C312A;
  background-position: center center;
  text-indent: -9999px; }

#photo .pattern {
  width: 100%;
  height: 100%;
  background-image: url("../images/WHD_patroon"); }

#programmaContent {
  background-color: #E7E7E7; }

.programmaWrapper {
  float: left;
  margin-right: -60px;
  width: 100%; }

.programmaWrapper .programmaContainer {
  margin-right: 60px;
  padding: 15px;
  background-color: white; }

.ticketWrapper {
  float: left;
  width: 100%; }

.ticketWrapper .programmaContainer {
  padding: 15px;
  background-color: white; }

#programmaContent h1 {
  margin: 0 0 15px 0;
  color: #2C312A;
  line-height: 110%; }

.tags {
  color: #666600;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

.txt .tags a {
  color: #666600; }

.txt .tags a:hover {
  color: #D93600; }

.icons {
  display: inline-block;
  margin-right: 5px;
  width: 57px;
  height: 57px;
  float: left;
  margin-bottom: 10px !important; }

.schip {
  background-position: -250px 0px; }

.bus {
  background-position: -250px -60px; }

.eigenvervoer {
  background-position: -250px -120px; }

.english {
  background-position: -250px -180px; }

.fiets {
  background-position: -310px 0; }

.helikopter {
  background-position: -310px -60px; }

.legitimatie {
  background-position: -310px -120px; }

.nieuw {
  background-position: -310px -180px; }

.rolstoel {
  background-position: -370px 0; }

.step {
  background-position: -370px -60px; }

.tevoet {
  background-position: -370px -120px; }

.trein {
  background-position: -370px -180px; }

.sidebar {
  float: right;
  width: 60px; }

.shareButtons div {
  display: none; }

.shareButtons a {
  display: block;
  width: 60px;
  height: 60px;
  background-color: #666600;
  cursor: pointer; }

.shareButtons a:hover {
  background-color: black; }

.shareButtons a.share, .shareButtons a.share--program {
  background-position: 0px -170px; }

.shareButtons a.twitter {
  background-position: -60px -170px; }

.shareButtons a.facebook {
  background-position: -120px -170px; }

.shareButtons a.pinterest {
  background-position: -180px -170px; }

.shareButtons a.share, .shareButtons a.share--program {
  position: relative; }

.shareButtons a.share.active, .shareButtons a.active.share--program {
  background-color: black; }

.shareButtons a.share.active:after, .shareButtons a.active.share--program:after {
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: " ";
  pointer-events: none; }

.shareButtons a.share.active:after, .shareButtons a.active.share--program:after {
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-color: transparent;
  border-top-color: black; }

#tijden {
  margin: 15px 0;
  padding: 0;
  border-top: 2px solid #666600;
  list-style: none;
  font-size: 18px; }

#tijden li {
  clear: both; }

#tijden li a {
  color: #2C312A; }

#tijden li a strong {
  display: block;
  padding: 10px 0 10px 30px;
  border-bottom: 2px solid #666600;
  background: url("../images/plus.gif") no-repeat left center;
  font-family: "Noto Serif", serif;
  cursor: pointer; }

#tijden li.active a strong {
  background: url("../images/min.gif") no-repeat left center; }

#tijden li ul {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
  /*display: none;*/ }

#tijden li ul li {
  margin: 0;
  padding: 13px 0 13px 30px;
  border-bottom: 1px solid #666600; }

.txt #tijden li span {
  float: right; }

.txt #tijden li span a {
  margin-top: -5px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-family: "Noto Serif", serif; }

.txt #tijden li span a.knopTickets {
  padding: 5px 40px 5px 10px;
  background-color: #D93600;
  background-position: center right;
  background-repeat: no-repeat;
  color: white; }

.txt #tijden li span a.knopTickets:hover {
  background-color: #005199;
  color: white; }

.txt #tijden li span a.knopUitverkocht {
  padding: 5px 10px 5px 10px;
  background-color: #E7E7E7;
  color: #2C312A; }

.txt #tijden li span a.knopUitverkocht:hover {
  background-color: #B9B9B9;
  color: #2C312A; }

/* ==========================================================================
   7.0 Planner - Mobile
   ========================================================================== */
#plannerHeader {
  padding: 15px 15px 0 15px;
  background-color: #D93600;
  color: white;
  font-size: 18px; }

#plannerHeader .title {
  display: none;
  padding: 15px;
  background-color: #666600; }

#plannerHeader .title h1 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 20px; }

#plannerHeader .title p {
  font-family: "Noto Serif", serif; }

#plannerHeader .opnieuw {
  float: right;
  padding: 0;
  width: 100%;
  height: 70px;
  font-size: 18px;
  font-family: "Noto Serif", serif;
  line-height: 16px; }

#plannerHeader .opnieuw a {
  display: block;
  padding: 18px 15px 0 15px;
  height: 52px;
  background-color: #666600;
  color: white;
  color: white;
  text-align: center; }

#plannerHeader .opnieuw a:hover {
  background-color: black; }

#plannerHeader .opnieuw span {
  float: right;
  margin: -18px -15px;
  width: 60px;
  height: 60px;
  background-position: -180px -118px; }

#plannerHeader .buttons {
  position: absolute;
  top: 90px;
  right: 0;
  width: 159px;
  height: 50px;
  background-color: #D93600; }

#plannerHeader .buttons a {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 53px;
  height: 50px;
  background-color: #D93600; }

#plannerHeader .buttons a.active,
#plannerHeader .buttons a:hover {
  background-color: #005199; }

#plannerHeader .buttons a.active:after {
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
  content: " ";
  pointer-events: none; }

#plannerHeader .buttons a.active:after {
  left: 27px;
  margin-left: -8px;
  border-width: 8px;
  border-color: transparent;
  border-top-color: #005199; }

#plannerHeader .buttons div {
  display: none;
  clear: both;
  height: 50px; }

#plannerHeader .buttons div a {
  background-color: #D93600; }

#plannerHeader .buttons div a:hover {
  background-color: #005199; }

.buttons a.share, .buttons a.share--program {
  background-position: -2px -175px; }

.buttons a.print {
  background-position: -60px -240px; }

.buttons a.email {
  background-position: -0px -240px; }

.buttons a.twitter {
  background-position: -62px -175px; }

.buttons a.facebook {
  background-position: -122px -175px; }

.buttons a.pinterest {
  background-position: -182px -175px; }

.plannerList {
  margin: 0;
  padding: 0 !important;
  list-style: none;
  font-size: 1.2308em; }

.plannerList li {
  margin: 10px 0;
  width: 100%;
  background-color: #E7E7E7; }

.plannerList li a {
  display: block;
  color: #2C312A;
  text-decoration: none; }

.plannerList li a .image {
  display: none; }

.plannerList li a .listWrapper {
  float: left;
  margin-right: -50px;
  width: 100%; }

.plannerList li a .listWrapper div {
  margin-right: 50px;
  padding: 10px; }

.plannerList li a .listWrapper div div {
  margin-right: 0;
  padding: 0; }

.plannerList li a .listWrapper div div {
  margin-right: 0;
  padding: 0; }

.plannerList li a .title {
  font-weight: bold; }

.plannerList li a .button {
  font-family: "Noto Serif", serif;
  display: block;
  float: right;
  width: 50px;
  height: 80px;
  background-color: #D93600;
  background-position: -125px 10px; }

.txt .plannerList li:hover a {
  color: #2C312A; }

.txt .plannerList li:hover .button {
  background-color: #005199; }

.gm-style-iw h2 {
  display: block;
  font-family: "Noto Serif", serif; }

.infoBox .info {
  overflow: auto;
  padding: 10px;
  max-height: 230px;
  background-color: white;
  -webkit-box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.6);
  box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.6);
  font-size: 18px;
  font-family: "Noto Serif", serif; }

.infoBox .info a {
  color: #2C312A;
  text-decoration: none; }

.infoBox .info img {
  margin-bottom: 9px; }

.infoBox .info .title {
  margin-bottom: 6px; }

.infoBox .info .noImage {
  margin-bottom: 0;
  width: 170px; }

.infoBox .info ul {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none; }

/*.infoBox .info ul.titleAbove { margin: 20px 0 0 0; }*/
.infoBox .info ul li {
  border-top: 1px solid #D5D5D5;
  background-color: #E7E7E7; }

.infoBox .info ul li:first-child {
  border-top: none; }

.infoBox .info ul li a {
  display: block;
  padding: 5px; }

.infoBox .info .content {
  overflow: auto;
  margin: 20px 0 0 0;
  max-height: 160px; }

.infoBox .info .content ul {
  margin: 0; }

.arrow-down {
  margin: 0 auto 30px auto;
  padding: 0;
  width: 0;
  height: 0;
  border-top: 15px solid white;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  background-color: 0; }

.black {
  color: #2C312A; }

.container {
  position: relative; }

.clearfix, .lightbox {
  clear: both; }

.left {
  position: relative;
  float: left; }

.right {
  position: relative;
  float: right; }

.submit a {
  background-position: -120px 0; }

.txt {
  font-size: 15px;
  line-height: 22px; }

.txt h1 {
  margin: 10px 0 20px 0;
  padding: 0;
  color: #666600;
  font-size: 32px;
  font-family: "Noto Serif", serif; }

.txt h1.date {
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid #2C312A;
  color: #2C312A;
  font-size: 22px; }

.txt h1.title40 {
  display: inline-block;
  font-size: 42px;
  line-height: 47px; }

.txt h2 {
  margin: 0;
  padding: 0;
  font-size: 1.2308em;
  font-family: "Noto Serif", serif; }

.txt h3 {
  margin: 0;
  padding: 0;
  font-size: 1.2308em;
  font-family: "Noto Serif", serif; }

.txt h3.red {
  color: #666600; }

.txt a {
  color: #D93600;
  text-decoration: none; }

.txt a:hover {
  color: #666600; }

.txt hr {
  clear: both;
  height: 1px;
  border: none;
  background-color: #D93600; }

.txt ul {
  margin: 0;
  padding: 0 0 0 20px; }

.txt p + ul {
  margin-bottom: 0px; }

.txt h2 + p {
  margin-top: 0px; }

.knop {
  display: block;
  margin: 5px 0;
  padding: 10px 30px 10px 10px;
  width: 80%;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #666600;
  background-position: center right;
  color: white !important;
  text-decoration: none;
  font-size: 17px;
  font-family: "Noto Serif", serif; }
  .knop.inline {
    width: auto;
    display: inline-block; }

.knop:hover {
  background-color: black; }

.knopTerug {
  display: inline-block;
  margin: 5px 0;
  padding: 10px 10px 10px 30px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #D93600;
  background-position: center left;
  color: white !important;
  text-decoration: none;
  font-size: 17px;
  font-family: "Noto Serif", serif; }

.knopTerug:hover {
  background-color: #005199; }

#allFaq {
  color: #2C312A;
  font-size: 18px;
  font-family: "Noto Serif", serif; }

.red {
  color: #666600; }

.blue {
  color: #D93600; }

.txt h2.big {
  font-size: 42px;
  line-height: 110%; }

.wrapper {
  position: relative; }

.noresize {
  width: auto !important; }

#bestellen {
  display: none; }

/* ==========================================================================
   10.0 Helper classes - Mobile
   ========================================================================== */
.bg--white {
  background-color: white; }

.bg--gray {
  background-color: #E8E8E8; }

.bg--red {
  background-color: #666600; }

.bg--blue {
  background-color: #D93600; }

.clearfix, .lightbox {
  zoom: 1; }

.clearfix:before, .lightbox:before {
  display: table;
  content: ""; }

.clearfix:after, .lightbox:after {
  display: table;
  clear: both;
  content: ""; }

/*
	 * Image replacement
	 */
.ir {
  overflow: hidden;
  border: 0;
  background-color: transparent;
  /* IE 6/7 fallback */
  *text-indent: -9999px; }

.ir:before {
  display: block;
  width: 0;
  height: 150%;
  content: ""; }

/*
	 * Hide from both screenreaders and browsers: h5bp.com/u
	 */
.hidden {
  display: none !important;
  visibility: hidden; }

/*
	 * Hide only visually, but have it available for screenreaders: h5bp.com/v
	 */
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0; }

/*
	 * Extends the .visuallyhidden class to allow the element to be focusable
	 * when navigated to via the keyboard: h5bp.com/p
	 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  margin: 0;
  width: auto;
  height: auto; }

/*
	 * Hide visually and from screenreaders, but maintain layout
	 */
.invisible {
  visibility: hidden; }

/*
	 * Clearfix: contain floats
	 *
	 * For modern browsers
	 * 1. The space content is one way to avoid an Opera bug when the
	 *    `contenteditable` attribute is included anywhere else in the document.
	 *    Otherwise it causes space to appear at the top and bottom of elements
	 *    that receive the `clearfix` class.
	 * 2. The use of `table` rather than `block` is only necessary if using
	 *    `:before` to contain the top-margins of child elements.
	 */
.clearfix:before, .lightbox:before,
.clearfix:after,
.lightbox:after {
  display: table;
  /* 2 */
  content: " ";
  /* 1 */ }

.clearfix:after, .lightbox:after {
  clear: both; }

/*
	 * For IE 6/7 only
	 * Include this rule to trigger hasLayout and contain floats.
	 */
.clearfix, .lightbox {
  *zoom: 1; }

/*
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 767px), only screen and (min-resolution: 144dpi) and (max-width: 767px)  {

		.menu-icon a,
		.search-icon a,
		.submit a,
		.logo a,
		.close a,
		#btnSearch { background-image: url('../images/slice-mobilex2.png'); background-repeat: no-repeat; text-indent: -9999px; }
	}
	*/
/* ==========================================================================
   11.0 Isotope - Mobile
   ========================================================================== */
.isotope-item {
  z-index: 2; }

.isotope-hidden.isotope-item {
  z-index: 1;
  pointer-events: none; }

/**** Isotope CSS3 transitions ****/
.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s; }

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width; }

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity; }

/**** disabling Isotope CSS3 transitions ****/
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s; }

/* End: Recommended Isotope styles */
/* disable CSS transitions for containers with infinite scrolling*/
.isotope.infinite-scrolling {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none; }

/* ==========================================================================
   Desktop min-width: 480px
   ========================================================================== */
@media only screen and (min-width: 480px) {
  /* ==========================================================================
   1.0 Socials home - mobile
   ========================================================================== */
  .content-section__header h1 {
    display: inline-block;
    font-size: 42px;
    margin: 0 0 0 0; }
  .content-section__header a {
    float: right;
    margin: 22px 21px 0 0; } }

/* ==========================================================================
   Desktop min-width: 768px
   ========================================================================== */
@media only screen and (min-width: 768px) {
  .container {
    width: 730px;
    margin: 0 auto; }
  .container--border {
    margin-top: -60px !important; }
  .content-section {
    padding: 60px 15px; }
  .content-half {
    width: 50%; }
  .content-half span {
    margin-bottom: 60px; }
  .visitors-info h1 {
    margin: 0 0 30px 0; }
  .visitors-info p {
    float: left;
    margin: 0 0 0 0; }
  .visitors-info .btn {
    float: right;
    width: 243px;
    margin-left: 20px;
    margin-right: 0;
    margin-bottom: 0; }
  /* ==========================================================================
   14.0 Newsfeed - Desktop (min 768px)
   ========================================================================== */
  #newsFeed {
    height: 75px; }
  .newsWrapper {
    width: 520px;
    height: 75px;
    float: left;
    overflow: hidden;
    background-color: #0085B5; }
  .newsWrapper .icon {
    width: 75px;
    height: 75px;
    float: left;
    background-color: #006A91;
    position: relative;
    background-position: -52px -162px; }
  .newsWrapper .icon:after {
    left: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .newsWrapper .icon:after {
    border-color: transparent;
    border-left-color: #006A91;
    border-width: 8px;
    top: 50%;
    margin-top: -8px; }
  .newsWrapper .newsText {
    width: 445px;
    height: 75px;
    float: left;
    overflow: hidden; }
  .newsWrapper .newsText ul {
    width: 445px !important; }
  .newsWrapper .newsText ul li {
    padding: 8px 25px 0 25px;
    height: 67px;
    width: 395px !important;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: white;
    line-height: 22px; }
  .newsWrapper .newsText a {
    color: white; }
  .newsWrapper2 ul {
    width: 520px !important; }
  .newsWrapper2 ul li {
    background-position: right center;
    background-color: #0085B5;
    width: 100% !important;
    font-family: "Open Sans", sans-serif;
    height: 75px;
    font-size: 16px;
    color: white;
    line-height: 22px; }
  .newsWrapper2 ul li a {
    color: white; }
  .newsWrapper2 ul li img {
    float: left; }
  .newsWrapper2 ul li.block_facebook {
    background-color: #54739F; }
  .newsWrapper2 ul li.block_facebook .icon {
    background-position: -112px -162px;
    background-color: #335B99; }
  .newsWrapper2 ul li .icon {
    width: 75px;
    height: 75px;
    float: left;
    background-color: #335B99;
    position: relative;
    background-position: -52px -162px; }
  .newsWrapper2 ul li .icon:after {
    left: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .newsWrapper2 ul li .icon:after {
    border-color: transparent;
    border-left-color: #335B99;
    border-width: 8px;
    top: 50%;
    margin-top: -8px; }
  .newsWrapper2 .newsText {
    width: 395px;
    height: 67px;
    float: left;
    overflow: hidden;
    padding: 8px 25px 0 25px; }
  .newsWrapper2 .newsTextImage {
    width: 200px;
    height: 63px;
    float: left;
    overflow: hidden;
    padding: 8px 30px 0px 15px; }
  .newsWrapper2 .newsImage {
    width: 200px;
    height: 75px;
    float: left;
    overflow: hidden;
    text-align: center; }
  .newsWrapper .table {
    display: table;
    height: 100%; }
  .newsWrapper .table-cell {
    display: table-cell;
    vertical-align: middle; }
  /* ==========================================================================
   15.0 Content pagina - Desktop (min 768px)
   ========================================================================== */
  #content {
    position: relative;
    margin-top: 150px;
    width: 100%;
    -webkit-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5); }
  #content .container .wrapper {
    padding: 0; }
  .programma .wrapper {
    padding: 15px 0 !important; }
  /* ==========================================================================
   16.0 Filter - Desktop (min 768px)
   ========================================================================== */
  .programma a#filter {
    float: left;
    margin-left: 30px; }
  #meerinfo {
    margin-right: 0px; }
  #infoOverlay .close {
    width: 78px;
    height: 78px; }
  #infoOverlay .close a {
    width: 78px;
    height: 78px;
    background-position: -560px -280px; }
  #infoOverlay .wrapper {
    padding: 25px 0 !important;
    width: 640px; }
  #filterOverlay .close {
    width: 78px;
    height: 78px; }
  #filterOverlay .close a {
    width: 78px;
    height: 78px;
    background-position: -560px -280px; }
  #filterOverlay hr {
    display: none; }
  #filterOverlay .knop {
    float: right;
    clear: both; }
  #filterOverlay .wrapper {
    padding: 25px 0 !important; }
  #switch {
    height: 40px;
    float: right;
    margin-top: 9px; }
  #switch a {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    float: left;
    display: block;
    background-color: #E7E7E7;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-indent: -9999px; }
  #switch a:hover {
    background-color: #B9B9B9; }
  #switch a.list {
    background-position: -640px -80px; }
  #switch a.block {
    background-position: -640px -40px; }
  #switch a.active {
    background-color: #D93600; }
  #switch a.list.active {
    background-position: -680px -80px; }
  #switch a.block.active {
    background-position: -680px -40px; }
  #text {
    position: relative;
    margin-top: 150px;
    width: 100%;
    -webkit-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 300px rgba(0, 0, 0, 0.5); }
  .mainBlock {
    padding: 30px 0; }
  #text .title {
    background-color: transparent !important; }
  .title span {
    display: none; }
  .bckWhite .title {
    color: #666600; }
  .bckBlue {
    background-color: #D93600;
    color: white; }
    .bckBlue textarea,
    .bckBlue select,
    .bckBlue input {
      color: #2C312A !important; }
  .bckBlue .title {
    color: white; }
  .bckBlue .knop {
    background-image: url("../images/arrow-right-mobile-blue.png");
    background-color: white;
    color: #D93600 !important; }
  .bckGrey {
    background-color: #EDEDED; }
  .bckGrey .title {
    color: #666600; }
  .bckRed {
    background-color: #666600;
    color: white; }
    .bckRed textarea,
    .bckRed select,
    .bckRed input {
      color: #2C312A !important; }
  .bckRed .title {
    color: white; }
  .bckRed a {
    color: white; }
  .bckRed a:hover {
    color: white; }
  #submenu {
    position: absolute;
    top: -60px;
    width: 100%;
    font-family: "Noto Serif", serif;
    font-size: 17px;
    height: 60px;
    z-index: 100; }
  #submenu .container div.submenuContainer {
    display: table;
    width: 720px;
    padding: 10px 5px;
    background-color: #666600; }
  #submenu ul {
    margin: 10px 0;
    padding: 0;
    height: 40px;
    list-style: none;
    display: table-row;
    table-layout: fixed;
    clear: both;
    padding: 10px 0;
    width: 720px; }
  #submenu ul li {
    padding: 0 5px;
    margin: 0 5px;
    display: table-cell;
    width: auto; }
  #submenu ul li a {
    display: block;
    background-color: #333300;
    color: white;
    text-decoration: none;
    padding: 10px 0;
    text-align: center;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    line-height: 20px; }
  #submenu ul li a:hover {
    background-color: white;
    color: #2C312A; }
  #submenu ul li a:active {
    background-color: white;
    color: #D93600; }
  #submenu.sticky {
    position: fixed;
    top: 0; }
  .contentBlock {
    width: 46%;
    margin-right: 4%;
    float: left; }
  .contentBlock3 {
    width: 30%;
    margin-right: 3%;
    float: left; }
  .contentBlock3 img {
    width: 100%; }
  .group_1 {
    width: 30%;
    float: left; }
  .group_2 {
    width: 65%;
    float: right; }
  .logo {
    margin-top: 15px;
    z-index: 10;
    position: relative; }
  .logo a {
    width: 100px;
    height: 105px;
    display: block;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain; }
  .var1 a {
    background-image: url(../images/logo--whd-geel-rood.svg); }
  /* geel - rood */
  .var2 a {
    background-image: url(../images/logo--whd-blauw-wit.svg); }
  /* blauw - wit */
  .var3 a {
    background-image: url(../images/logo--whd-wit-blauw.svg); }
  /* wit - blauw */
  .var4 a {
    background-image: url(../images/logo--whd-wit-rood.svg); }
  /* wit - rood */
  .var5 a {
    background-image: url(../images/logo--whd-rood-zwart.svg); }
  /* rood - zwart */
  .var6 a {
    background-image: url(../images/logo--whd-geel-zwart.svg); }
  /* geel - zwart */
  /* ==========================================================================
   18.0 Programma - Desktop (min 768px)
   ========================================================================== */
  #programmaBackground {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden; }
  #photo {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1; }
  #map {
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0; }
  #programmaContent {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 600px;
    background-color: transparent;
    z-index: 0;
    pointer-events: none; }
  .programmaWrapper {
    width: 470px;
    right: 78px;
    margin-right: 0;
    position: absolute;
    pointer-events: auto;
    background-color: white; }
  .programmaWrapper .programmaContainer,
  .ticketWrapper .programmaContainer {
    width: 100%;
    margin-right: 0;
    padding: 0;
    position: relative; }
  .programmaTitle {
    height: 78px;
    display: table;
    width: 100%; }
  .programmaTitle h1 {
    display: table-cell;
    vertical-align: middle;
    padding: 0 15px;
    margin: 0 !important; }
  .programmaTitle div.close {
    float: right;
    width: 78px; }
  .programmaTitle div.close a.close {
    width: 78px;
    height: 78px;
    background-color: #E7E7E7;
    display: block;
    background-position: -320px -280px; }
  .programmaTitle div.close a.close:hover {
    background-color: #B9B9B9; }
  .programmaContent {
    position: absolute;
    top: 75px;
    height: 522px;
    overflow: auto; }
  .programmaPadding {
    padding: 15px; }
  .ticketWrapper {
    width: 730px;
    margin-right: 0;
    position: absolute;
    pointer-events: auto;
    background-color: white; }
  .sidebar {
    width: 78px;
    float: right;
    pointer-events: auto; }
  .sidebar a.close {
    width: 78px;
    height: 78px;
    background-color: #E7E7E7;
    display: block;
    background-position: -320px -280px; }
  .sidebar a.close:hover {
    background-color: #B9B9B9; }
  .sidebar a.open {
    width: 78px;
    height: 78px;
    background-color: #666600;
    display: block;
    background-position: -320px -200px; }
  .sidebar a.open:hover {
    background-color: black; }
  .shareButtons a {
    width: 78px;
    height: 78px; }
  .shareButtons a.share, .shareButtons a.share--program {
    background-position: 9px -161px; }
  .shareButtons a.twitter {
    background-position: -51px -161px; }
  .shareButtons a.facebook {
    background-position: -111px -161px; }
  .shareButtons a.pinterest {
    background-position: -171px -161px; }
  .toggleView {
    position: absolute;
    left: 0;
    bottom: 0;
    font-family: "Noto Serif", serif;
    font-size: 20px;
    pointer-events: auto; }
  .toggleView a {
    background-color: white;
    color: #2C312A;
    padding: 20px 20px 15px 20px;
    float: left;
    text-decoration: none; }
  .toggleView a span {
    background-position: -434px -236px;
    width: 13px;
    height: 9px;
    margin-left: 20px;
    margin-top: 7px;
    display: block;
    float: right; }
  .toggleView a.map {
    display: none; }
  /* ==========================================================================
   19.0 Planner - Desktop (min 768px)
   ========================================================================== */
  .single-whd_planner #content {
    margin-top: -90px; }
  #plannerHeader {
    padding: 0;
    background-color: transparent;
    color: white;
    top: -90px;
    height: 90px;
    position: absolute;
    width: 100%;
    z-index: 100; }
  #plannerHeader .container {
    padding: 0px !important; }
  #plannerHeader .title {
    padding: 15px 15px 0 15px;
    width: 541px;
    float: left;
    height: 75px;
    overflow: hidden;
    display: block; }
  #plannerHeader .title h1 {
    color: white;
    margin: 0;
    padding: 0;
    font-size: 27px; }
  #plannerHeader .opnieuw {
    padding: 0;
    width: 159px;
    float: right;
    height: 90px; }
  #plannerHeader .opnieuw a {
    padding: 18px 15px 0 15px;
    color: white;
    display: block;
    text-align: center;
    height: 72px;
    background-color: black; }
  #plannerHeader .opnieuw a:hover {
    background-color: #A40D21; }
  #plannerHeader .opnieuw a span {
    display: block;
    width: 13px;
    height: 12px;
    float: none;
    background-position: -434px -314px;
    margin: 0 auto 10px auto; }
  .planner .container {
    padding: 15px 0; }
  .single-whd_planner #content.planner .container .wrapper {
    padding-left: 15px; }
  .plannerList {
    margin-bottom: 55px; }
  .plannerList li {
    background-color: transparent;
    width: 475px; }
  .plannerList li a {
    position: relative; }
  .plannerList li a .image {
    display: block;
    float: left;
    width: 195px; }
  .plannerList li a .image img {
    width: 175px;
    height: 130px; }
  .plannerList li a .listWrapper {
    float: left;
    width: 280px;
    padding-top: 5px; }
  .plannerList li a .listWrapper div {
    padding: 0; }
  .plannerList li a .button {
    position: absolute;
    bottom: 5px;
    left: 195px;
    text-indent: 0;
    background-image: url("../images/arrow-right-mobile.png");
    background-repeat: no-repeat;
    background-position: right center;
    color: white;
    width: 250px;
    height: auto;
    float: left;
    padding: 10px 15px; }
  /* ==========================================================================
   21.0 Background images - Desktop (min 768px)
   ========================================================================== */
  .submit a {
    background-position: -315px -110px; }
  .twitter a,
  .facebook a,
  .flickr a,
  .menu-icon a,
  .search-icon a,
  .ticket-icon a,
  .submit a,
  .close a,
  a.close,
  a.open,
  .button,
  .toggleView a span,
  #switch a,
  #plannerHeader .opnieuw a span,
  #btnSearch {
    background-image: url("../images/slice.png");
    background-repeat: no-repeat;
    text-indent: -9999px; }
  .txt h1.date {
    width: 555px; }
  .txt p.small {
    width: 515px; } }

/* ==========================================================================
   Desktop min-width: 960px
   ========================================================================== */
@media only screen and (min-width: 960px) {
  .container {
    width: 960px; }
  .newsWrapper {
    width: 650px; }
  .newsWrapper .newsText {
    width: 575px; }
  .newsWrapper .newsText ul {
    width: 575px !important; }
  .newsWrapper .newsText ul li {
    padding: 0 25px;
    width: 525px !important;
    height: 75px; }
  .newsWrapper2 ul {
    width: 650px !important; }
  .newsWrapper2 .newsText {
    width: 525px; }
  .newsWrapper2 .newsTextImage {
    width: 250px; }
  .newsWrapper2 .newsImage {
    width: 280px; }
  .visitors-info .btn {
    width: 286px; }
  #submenu {
    top: -80px;
    font-size: 18px; }
  #submenu .container div.submenuContainer {
    padding: 20px 15px;
    width: 960px;
    box-sizing: border-box; }
  #submenu ul {
    height: 40px; }
  .mainBlock .title {
    padding: 15px 20px; }
  .mainBlock .wrapper {
    padding: 15px 20px; }
  /*
		#submenu .container div.submenuContainer { display: table; width: 720px; padding: 10px 5px; background-color: $theme-color-01; }
		#submenu ul { margin: 10px 0; padding: 0; height: 40px; list-style: none; display: table-row;table-layout: fixed; clear: both; padding: 10px 0; width: 720px; }
		#submenu ul li { padding: 0 5px; margin: 0 5px; display: table-cell; width: auto; }
		#submenu ul li a { display: block; background-color: #3484CD; color: $snow-white; text-decoration: none; padding: 10px 0; text-align: center; -webkit-border-radius: 3px; border-radius: 3px; }
		#submenu ul li a:hover { background-color: $snow-white; color: #3484CD; }
	*/
  #plannerHeader .title {
    width: 671px; }
  #plannerHeader .title h1 {
    margin-top: 10px;
    font-size: 32px; }
  #plannerHeader .title p {
    margin-top: 8px; }
  .programmaWrapper {
    width: 502px; }
  .ticketWrapper {
    width: 860px; }
  .txt h1.date {
    width: 685px; }
  .txt p.small {
    width: 645px; }
  /* ==========================================================================
   22.0 Newsletter - Desktop (min 960px)
   ========================================================================== */ }

/* ==========================================================================
   Desktop min-width: 1350px
   ========================================================================== */
