 /* ==========================================================================
   8.0 Kaart - Mobile
   ========================================================================== */

#kaart {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;

	img {
		max-width: none;
	}
}

#kaartFilterButtonContainer {
	position: relative;
	width: 100%;
	pointer-event: none;
}

#kaartFilterButton { padding: 20px; }

#kaartFilterButton a {
	float: left;
	clear: both;
	padding: 10px 60px 10px 20px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #0065BF;
	color: $snow-white;
	text-decoration: none;
	font-size: 18px;
	font-family: $good-bold;
}

#kaartFilterButton a:hover { background-color: #005199; }

#kaartFilterContainer {
	position: relative;
	width: 100%;
	pointer-event: none;
}

#kaartFilter {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	display: none;
	width: 100%;
	height: auto;
	background-color: #0065BF;
	pointer-events: auto;
}

#kaartFilter .close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	width: 60px;
	height: 60px;
}

#kaartFilter .close a {
	display: block;
	width: 60px;
	height: 60px;
	background-color: #005199;
	background-position: -180px -118px;
}

#kaartFilter .close a:hover { background-color: #005199; }

#kaartFilter .wrapper { padding: 15px; }

#kaartFilter .wrapper h1 {
	color: #233b48;
	font-size: 20px;
}

#kaartFilter .wrapper hr {
	margin: 6px 0 20px 0;
	height: 1px;
	border: none;
	background-color: #4d94d2;
}

#kaartFilter input { display: none; }

#kaartFilter label {
	display: block;
	margin: 4px 0;
	padding: 15px 0px 15px 20px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #3384CC;
	color: #0D5592;
	font-size: $base-font-size;
	font-family: $good-bold;
	cursor: pointer;
}

#kaartFilter label.active {
	background-color: $snow-white;
	color: #0065BF;
}

#kaartFilter label span {
	float: right;
	margin-top: -9px;
}

#kaartFilter .noBackground label {
	padding: 5px 0;
	background-color: transparent;
	color: #3284CD;
	font-size: 25px;
}

#kaartFilter .noBackground label.active {
	background-color: transparent;
	color: $snow-white;
}

#kaartOpenContainer {
	display: none;
}

.post-type-archive-whd_arrangement,
.post-type-archive-whd_programma {
	.kaart--small {
		display: none;
	}
}

 /* ==========================================================================
   20.0 Kaart - Desktop (min 768px)
   ========================================================================== */
@include respond-to(medium-and-up) {

	.post-type-archive-whd_programma {

		.kaart--small {
			display: block;
		}

		#kaart {
			height: 580px;
		}

		#kaartOpenContainer {
			display: block;
			position: absolute;
			width: 100%;

			.toggleView {
				right: 0;
				left: auto;
			}

			.big {
				display: block;
			}

			.small {
				display: none;
			}
		}

	}

	#kaartFilterButtonContainer {
		pointer-events: none;
		position: fixed;
		bottom: 0;
		left: 0;
	}

	#kaartFilterButton {
		width: 120px;
		pointer-events: auto;
		background-color: #E7E7E7;
	}

	#kaartFilterContainer {
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		height: 0;
		overflow: hidden;
	}

	#kaartFilterContainer .container {  }

	#kaartFilter {
		display: block;
		height: 490px;
		width: 270px;
		pointer-events: auto;
	}

	#kaartFilter .close {
		width: 78px;
		height: 78px;
	}

	#kaartFilter .close a {
		width: 78px;
		height: 78px;
		background-position: -560px -280px;
	}

	#kaartFilter .wrapper h1 { margin: 13px 0 50px; }

}
