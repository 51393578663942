// --------------------------------------------------------------------------
// Respond to
// --------------------------------------------------------------------------


// Defaults
// --------------------------------------------------------------------------

$breakpoints: small-and-up, small-and-below, small-only, compact-and-up, compact-and-below, compact-only, medium-and-up, medium-and-below, medium-only, large-and-up, large-and-below, large-only, wide-and-up, wide-and-below, wide-only, huge-and-up, huge-and-below, huge-only !default;
$oldie: false !default;

// Respond to (min-width by default)
// --------------------------------------------------------------------------

@mixin respond-to($condition, $breaks: $breakpoints) {

	// Loop through the given breakpoints (by default all breakpoints)...
	@each $break in $breaks {

		// But only output breakspoint(s) that match the conditions...
		@if $condition == $break {

			@if $oldie {

				// Ignore breakpoints transparently
				// Output a fixed media query set for IE
				@include respond-to-condition($condition) { @content; }

				// @content

			} @else {

				// Invoke the respond-to-condition mixin...
				@include respond-to-condition($condition) { @content; }

			}

		}
	}

}

// Respond to condition
// --------------------------------------------------------------------------

@mixin respond-to-condition($condition) {

	@if $condition == small-and-up      { @media only screen and (min-width: ($breakpoint-small + em)) { @content; }}
	@if $condition == compact-and-up    { @media only screen and (min-width: ($breakpoint-compact + em)) { @content; }}
	@if $condition == medium-and-up     { @media only screen and (min-width: ($breakpoint-medium + em)) { @content; }}
	@if $condition == large-and-up      { @media only screen and (min-width: ($breakpoint-large + em)) { @content; }}
	@if $condition == wide-and-up       { @media only screen and (min-width: ($breakpoint-wide + em)) { @content; }}
	@if $condition == huge-and-up       { @media only screen and (min-width: ($breakpoint-huge + em)) { @content; }}

	@if $condition == small-and-below   { @media only screen and (max-width: ($breakpoint-compact - .01 + em)) { @content; }}
	@if $condition == compact-and-below { @media only screen and (max-width: ($breakpoint-medium - .01 + em)) { @content; }}
	@if $condition == medium-and-below  { @media only screen and (max-width: ($breakpoint-normal - .01 + em)) { @content; }}
	@if $condition == large-and-below   { @media only screen and (max-width: ($breakpoint-wide - .01 + em)) { @content; }}
	@if $condition == wide-and-below    { @media only screen and (max-width: ($breakpoint-huge - .01 + em)) { @content; }}
	@if $condition == huge-and-below    { @content; }

	@if $condition == small-only        { @media only screen and (min-width: ($breakpoint-small + em)) and (max-width: ($breakpoint-compact - .01 + em)) { @content; }}
	@if $condition == compact-only      { @media only screen and (min-width: ($breakpoint-compact + em)) and (max-width: ($breakpoint-medium - .01 + em)) { @content; }}
	@if $condition == medium-only       { @media only screen and (min-width: ($breakpoint-medium + em)) and (max-width: ($breakpoint-normal - .01 + em)) { @content; }}
	@if $condition == large-only        { @media only screen and (min-width: ($breakpoint-large + em)) and (max-width: ($breakpoint-wide - .01 + em)) { @content; }}
	@if $condition == wide-only         { @media only screen and (min-width: ($breakpoint-wide + em)) and (max-width: ($breakpoint-huge - .01 + em)) { @content; }}
	@if $condition == huge-only         { @media only screen and (min-width: ($breakpoint-huge + em)) { @content; }}

}
